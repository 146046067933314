import Navbar from "../../Components/Navbar/Navbar";
import React, { useState } from "react";
import {Row, Col} from "react-bootstrap"
import { Navigate, useParams, useNavigate } from "react-router-dom";
import {getAlumniUpdate, updateAlumni} from "../../APICalls/SingleAlumni/SingleAlumniApi";
import { refreshPage, getMonths } from "../../Helpers/Utils/Common";
import { ToastContainer, toast } from "react-toastify";
import InputError from "../../Components/InputError/InputError";
import { validateAddSingleAlumni } from "../../Helpers/Validation/Alumni";
import { GetDepartments  } from "../../APICalls/Department/DepartmentApi";  
import { getDegreeProgram } from "../../APICalls/DegreeProgram.js/DegreeProgramApi";
import "react-toastify/dist/ReactToastify.css";
import "./EditAlumni.css";

function EditAlumni({ setIsChecked, isChecked}) {
  const navigate = useNavigate();
  const departments = GetDepartments();
  const Months = getMonths()
  const [inactive, setInactive] = useState(false);
  const [redirect, setRedirect] = useState("");
  const alumni_id = useParams();
  const [isClicked, setIsClicked] = useState(false);
  const [alumniDetails, setAlumniDetails] = useState([]);
  const [checked, setChecked] = useState(false);
  const [showDepartment, setShowDepartment] = useState(false);
  const [degreePrograms, setDegreePrograms] = useState([]);

  const colleges = [
    {value: "1", label: "College of Science"}
  ]

  const [isError, setIsError] = useState({
    preferred_title: false,
    married_surname: false,
    last_name: false,
    first_name: false,
    middle_name: false,
    suffix: false,
    birthdate: false,
    nationality: false,
    college_id: false,
    degree: false,
    degree_program: false,
    student_no: false,
    latin_award: false,
    month_graduated: false,
    year_graduated: false,
    academic_year: false,
    year_conferred: false,
    month_conferred: false,
    scholarship: false,
    contact_no: false,
    personal_email: false,
    pronoun: false,
    permanent_address: false,
    permanent_country: false,
    permanent_province: false,
    permanent_city: false,
    permanent_postal_code: false,
    present_address: false,
    present_country: false,
    present_province: false,
    present_city: false,
    present_postal_code: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAlumniDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (e) => {
    const { value } = e.target;
    getDegreeProgramByDept(value)
    setShowDepartment(true)
  };

   const checkboxHandler = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      alumniDetails.present_address = alumniDetails.permanent_address;
      alumniDetails.present_country = alumniDetails.permanent_country;
      alumniDetails.present_province = alumniDetails.permanent_province;
      alumniDetails.present_city = alumniDetails.permanent_city;
      alumniDetails.present_postal_code = alumniDetails.permanent_postal_code;
    } else {
      alumniDetails.present_address = "";
      alumniDetails.present_country = "";
      alumniDetails.present_province = "";
      alumniDetails.present_city = "";
      alumniDetails.present_postal_code = "";
    }
  };

   React.useEffect(() => {
    if (
      alumniDetails.present_address !== alumniDetails.permanent_address ||
      alumniDetails.present_country !== alumniDetails.permanent_country ||
      alumniDetails.present_province !== alumniDetails.permanent_province ||
      alumniDetails.present_city !== alumniDetails.permanent_city ||
      alumniDetails.present_postal_code !== alumniDetails.permanent_postal_code
    ) {
      setChecked(false);
    } 
  }, [alumniDetails]);

  // Get alumni details by ID
  async function getAlumni() {
    const response = await getAlumniUpdate(alumni_id.id);
    if (response.data) {
      setAlumniDetails(response.data.data);
    }
  }

  async function getDegreeProgramByDept(dept_id) {
    const response = await getDegreeProgram('', dept_id);
    if (response.data) {
      setDegreePrograms(response.data)
    }
  }

  async function update() {
    if (validateAddSingleAlumni(alumniDetails, setIsError) && !isClicked) {
      const response = await updateAlumni(alumni_id, alumniDetails);
      if (response.data) {
        setIsClicked(true);
        toast.success(response.data.data.response.toUpperCase());
        setTimeout(() => {navigate(-1);
          setTimeout(() => refreshPage(), 500)}, 1500);
      } else {
        setIsClicked(false);
        toast.error(response.error.data.messages.error.toUpperCase());
      }
    }
  }

  React.useEffect(() => {
    getAlumni();
  }, []);

  if (redirect === "back") {
    return <Navigate to="/alumni" />;
  }

  return (
    <div className="page">
      <ToastContainer />
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />

      <div className="login-container">
        <div className="form-cont pt-4">
          <div className="container pt-0">
            <Row className="">
              <h1 className="title left">EDIT ALUMNI ENTRY</h1>
            </Row>
            <Row>
              <Col xs={12} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">PREF. TITLE</p>
                  <input
                    type="text"
                    name="preferred_title"
                    className="form-control"
                    id="pref_title"
                    value={alumniDetails.preferred_title}
                    aria-describedby="pref_title"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>
              <Col xs={12} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">MARRIED SURNAME</p>
                  <input
                    type="text"
                    name="married_surname"
                    className="form-control"
                    id="married_surname"
                    value={alumniDetails.married_surname}
                    aria-describedby="married_surname"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>
              <Col xs={12} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    LAST NAME<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="last_name"
                    className="form-control"
                    id="last_name"
                    value={alumniDetails.last_name}
                    aria-describedby="last_name"
                    onChange={(e) => handleChange(e)}
                  />
                    <InputError className='left' isValid={isError.last_name} message={"This field is required"}/>
                </div>
              </Col>
              <Col xs={12} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    FIRST NAME<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="first_name"
                    className="form-control"
                    id="first_name"
                    value={alumniDetails.first_name}
                    aria-describedby="first_name"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError className='left' isValid={isError.first_name} message={"This field is required"}/>
                </div>
              </Col>
              <Col xs={12} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">M.I.</p>
                  <input
                    type="text"
                    name="middle_name"
                    className="form-control"
                    value={alumniDetails.middle_name}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>
              <Col xs={12} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">SUFFIX</p>
                  <input
                    type="text"
                    name="suffix"
                    className="form-control"
                    value={alumniDetails.suffix}
                    aria-describedby="suffix"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>
              <Col xs={12} sm={4} lg={3} className="mt-4">
                  <div className="form-group">
                    <p className="input-subtitle">
                      DATE OF BIRTH<span className="required-icon">*</span>
                    </p>
                    <input
                      type="date"
                      name="birthdate"
                      value={alumniDetails.birthdate}
                      className="modal-input date-input form-control input-subtitle input-shadow"
                      onChange={(e) => handleChange(e)}
                    />
                    <InputError className='left' isValid={isError.birthdate} message={"This field is required"}/>
                  </div>
                </Col>
                <Col xs={12} sm={4} lg={3} className="mt-4">
                  <div className="form-group">
                    <p className="input-subtitle">
                      NATIONALITY<span className="required-icon">*</span>
                    </p>
                    <input
                      type="text"
                      name="nationality"
                      className="form-control"
                      id="nationality"
                      value={alumniDetails.nationality}
                      aria-describedby="nationality"
                      onChange={(e) => handleChange(e)}
                    />
                    <InputError className='left' isValid={isError.nationality} message={"This field is required"}/>
                  </div>
                </Col>
                <Col xs={12} sm={4} lg={3} className="mt-4">
                  <div className="form-group">
                    <p className="input-subtitle">PRONOUNS</p>
                    <input
                      type="text"
                      name="pronoun"
                      className="form-control"
                      value={alumniDetails.pronoun}
                      aria-describedby="pronoun"
                      placeholder="THEY/THEM"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
              </Col>
              <Col xs={12} sm={4} lg={3} className="mt-4">
                  <div className="form-group">
                    <p className="input-subtitle">
                      COLLEGE<span className="required-icon">*</span>
                    </p>
                    <select
                      type="text"
                      name="college_id"
                      className="form-control"
                      value={alumniDetails.college_id}
                      aria-describedby="college"
                      onChange={(e) => handleChange(e)}
                    >
                      <option value=''>Select</option>
                      {
                        colleges.map((data) => (
                          <option value={data.value}>{data.label}</option>
                        ))
                      }
                    </select>
                    <InputError className='left' isValid={isError.college_id} message={"This field is required"}/>
                  </div>
                </Col>
                <Col xs={12} sm={4} lg={3} className="mt-4">
                  <div className="form-group">
                    <p className="input-subtitle">
                      DEPARTMENT<span className="required-icon">*</span>
                    </p>
                    <select
                      type="text"
                      name="department_id"
                      className="form-control"
                      value={alumniDetails.department_id}
                      onChange={(e) => handleChange(e)}
                      onClick={handleSelectChange}
                    >
                      <option value="" hidden selected disabled>Select</option>
                      {
                        departments.map((data) => (
                          <option value={data.value}>{data.label}</option>
                        ))
                      }
                    </select>
                    <InputError className='left' isValid={isError.department_id} message={"This field is required"}/>
                  </div>
                </Col>
                {
                  showDepartment && (
                  <Col xs={12} sm={4} lg={3} className="mt-4">
                    <div className="form-group">
                      <p className="input-subtitle">
                        DEGREE PROGRAM<span className="required-icon">*</span>
                      </p>
                      <select
                        type="text"
                        name="degree_program_id"
                        className="form-control"
                        value={alumniDetails.degree_program_id}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Select</option>
                        {
                          degreePrograms.map((data) => (
                            <option value={data.id}>{data.degree_program}</option>
                          ))
                        }
                      </select>
                      <InputError className='left' isValid={isError.degree_program_id} message={"This field is required"}/>
                    </div>
                  </Col>
                  )
                }
                <Col xs={12} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    STUDENT NO.<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="student_no"
                    className="form-control"
                    value={alumniDetails.student_no}
                    aria-describedby="student_no"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError className='left' isValid={isError.student_no} message={"This field is required"}/>
                </div>
                </Col>
            
              <Col xs={12} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">LATIN AWARDS/DISTINCTION</p>
                  <input
                    type="text"
                    name="latin_award"
                    className="form-control"
                    value={alumniDetails.latin_award}
                    aria-describedby="latin_award"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>
              <Col xs={12} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    SEMESTER GRADUATED<span className="required-icon">*</span>
                  </p>
                  <div className="row">
                    <div className="col-6">
                      <select
                        type="text"
                        name="month_graduated"
                        className="form-control"
                        id="month_graduated"
                        value={alumniDetails.month_graduated}
                        aria-describedby="month_graduated"
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="" hidden selected disabled>Month</option>
                        {
                          Months.map((data) => (
                            <option value={data.value}>{data.label}</option>
                          ))
                        }
                      </select>
                      <InputError className='left' isValid={isError.month_graduated} message={"This field is required"}/>
                    </div>
                    <div className="col-5">
                      <input
                        type="number"
                        placeholder="Year"
                        name="year_graduated"
                        className="form-control"
                        id="year_graduated"
                        value={alumniDetails.year_graduated}
                        aria-describedby="year_graduated"
                        onChange={(e) => handleChange(e)}
                      />
                      <InputError className='left' isValid={isError.year_graduated} message={"This field is required"}/>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    ACADEMIC YEAR<span className="required-icon">*</span>
                  </p>
                  <input
                    type="number"
                    name="academic_year"
                    className="form-control"
                    id="academic_year"
                    value={alumniDetails.academic_year}
                    aria-describedby="academic_year"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError className='left' isValid={isError.academic_year} message={"This field is required"}/>
                </div>
              </Col>
              <Col xs={12} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">YEAR CONFERRED</p>
                  <div className="row">
                    <div className="col-6">
                      <select
                        type="text"
                        name="month_conferred"
                        className="form-control"
                        id="month_conferred"
                        value={alumniDetails.month_conferred}
                        aria-describedby="month_conferred"
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="" hidden selected disabled>Month</option>
                        {
                          Months.map((data) => (
                            <option value={data.value}>{data.label}</option>
                          ))
                        }
                      </select>
                    </div>
                    <div className="col-5">
                      <input
                        type="number"
                        placeholder="Year"
                        name="year_conferred"
                        className="form-control"
                        id="year_conferred"
                        value={alumniDetails.year_conferred}
                        aria-describedby="year_conferred"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    SCHOLARSHIP
                  </p>
                  <input
                    type="text"
                    name="scholarship"
                    className="form-control"
                    id="scholarship"
                    value={alumniDetails.scholarship}
                    aria-describedby="scholarship"
                    placeholder="DOST SCHOLARSHIP"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>
              <Col xs={12} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    CONTACT NUMBER<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="contact_no"
                    className="form-control"
                    id="contact_no"
                    value={alumniDetails.contact_no}
                    aria-describedby="contact_no"
                    placeholder="0999999999"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError className='left' isValid={isError.contact_no} message={"This field is required"}/>
                </div>
              </Col>
              <Col xs={12} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    ACTIVE PERSONAL EMAIL
                    <span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="personal_email"
                    className="form-control"
                    id="personal_email"
                    value={alumniDetails.personal_email}
                    aria-describedby="personal_email"
                    placeholder="JUANDELACRUZ@GMAIL.COM"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError className='left' isValid={isError.personal_email} message={"This field is required"}/>
                </div>
              </Col>
            </Row>
            <Row>
              <div className="col mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    PERMANENT ADDRESS<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="permanent_address"
                    className="form-control"
                    id="permanent_address"
                    value={alumniDetails.permanent_address}
                    aria-describedby="permanent_address"
                    placeholder="UNIT NUMBER/BUILDING/STREET NUMBER/VILLAGE/BARANGAY/LANDMARK"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError className='left' isValid={isError.permanent_address} message={"This field is required"}/>
                </div>
              </div>
            </Row>
            <Row>
              <Col xs={6} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    COUNTRY<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="permanent_country"
                    className="form-control"
                    id="permanent_country"
                    value={alumniDetails.permanent_country}
                    aria-describedby="permanent_country"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError className='left' isValid={isError.permanent_country} message={"This field is required"}/>
                </div>
              </Col>
              <Col xs={6} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    STATE/PROVINCE<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="permanent_province"
                    className="form-control"
                    value={alumniDetails.permanent_province}
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError className='left' isValid={isError.permanent_province} message={"This field is required"}/>
                </div>
              </Col>
              <Col xs={6} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    CITY<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="permanent_city"
                    className="form-control"
                    id="permanent_city"
                    value={alumniDetails.permanent_city}
                    aria-describedby="permanent_city"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError className='left' isValid={isError.permanent_city} message={"This field is required"}/>
                </div>
              </Col>
              <Col xs={6} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    POSTAL CODE<span className="required-icon">*</span>
                  </p>
                  <input
                    type="number"
                    name="permanent_postal_code"
                    className="form-control"
                    id="permanent_postal_code"
                    value={alumniDetails.permanent_postal_code}
                    aria-describedby="permanent_postal_code"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError className='left' isValid={isError.permanent_postal_code} message={"This field is required"}/>
                </div>
              </Col>
            </Row>
            <Row>
              <div className="col mt-4">
                <div className="form-group">
                  <div className="row row-form">
                    <div className="col-3">
                      <p className="input-subtitle w-100">
                        PRESENT ADDRESS<span className="required-icon">*</span>
                      </p>
                    </div>
                    <div className="col-5  form-group">
                      <input
                        type="checkbox"
                        className="custom-control-inpu mr-10 "
                        name="same_address"
                        checked={checked}
                        onChange={checkboxHandler}
                      />
                      <label
                        className="custom-control-label input-title ms-2"
                        htmlFor="purchase-limit"
                      >
                        SAME AS PERMANENT ADDRESS
                      </label>
                    </div>
                  </div>
                  <input
                    type="text"
                    name="present_address"
                    className="form-control"
                    value={alumniDetails.present_address}
                    id="present_address"
                    aria-describedby="present_address"
                    placeholder="UNIT NUMBER/BUILDING/STREET NUMBER/VILLAGE/BARANGAY/LANDMARK"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError className='left' isValid={isError.present_address} message={"This field is required"}/>
                </div>
              </div>
            </Row>
            <Row>
              <Col xs={12} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    COUNTRY<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="present_country"
                    className="form-control"
                    value={alumniDetails.present_country}
                    id="present_country"
                    aria-describedby="present_country"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError className='left' isValid={isError.present_country} message={"This field is required"}/>
                </div>
              </Col>
              <Col xs={12} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    STATE/PROVINCE<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="present_province"
                    className="form-control"
                    value={alumniDetails.present_province}
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError className='left' isValid={isError.present_province} message={"This field is required"}/>
                </div>
              </Col>
              <Col xs={12} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    CITY<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="present_city"
                    className="form-control"
                    value={alumniDetails.present_city}
                    id="present_city"
                    aria-describedby="present_city"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError className='left' isValid={isError.present_city} message={"This field is required"}/>
                </div>
              </Col>
              <Col xs={12} sm={4} lg={3} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    POSTAL CODE<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="present_postal_code"
                    className="form-control"
                    value={alumniDetails.present_postal_code}
                    id="present_postal_code"
                    aria-describedby="present_postal_code"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError className='left' isValid={isError.present_postal_code} message={"This field is required"}/>
                </div>
              </Col>
            </Row>
            
            <Row className="justify-content-end mt-5 me-5">
              <Col xs={2} className="right">
                <button className="back-btn btn-rad right px-5" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => setRedirect("back")}>
                  BACK
                </button>
              </Col>
              <Col xs={1} className="right">
                <button className="btn-green btn-rad px-5"  data-bs-toggle="modal" onClick={update}>
                  UPDATE
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditAlumni;
