import { handleValidationChange } from "../CommonValidation";

export const validateAddDepartment = (data, setIsError) => {
    var isValid = true

    if (data.college_id === "" || data.college === "") {
      handleValidationChange("college_id", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("college_id", false, setIsError);
    }

    if (data.department_name === "" || data.department === "") {
      handleValidationChange("department_name", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("department_name", false, setIsError);
    }

    return isValid
}