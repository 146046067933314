import Navbar from "../../Components/Navbar/Navbar";
import React, { useState } from "react";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import Profile from "../../Assets/Images/Dashboard/user_profile.png";
import {
  getAdminUser,
  getNewLogins,
  getNewRegistrants,
} from "../../APICalls/ManageUserApi";
import { getAnnouncementDisplay } from "../../APICalls/Announcement/Announcement";
import { getProjectsDisplay } from "../../APICalls/ProjectsApi/ProjectsApi";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function DashboardItc({ setIsChecked, isChecked }) {
  const [inactive, setInactive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [redirect, setRedirect] = useState("");
  const [action, setAction] = useState({});
  const [adminData, setAdminData] = useState({});
  const id = useParams();
  const [annId, setAnnId] = useState();
  const [announcementData, setAnnouncementData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [newLoginData, setNewLoginData] = useState([]);
  const [newLogin, setNewLogin] = useState([]);
  const [newRegistrants, setNewRegistrants] = useState([]);
  const navigateTo = useNavigate()

  async function PioProfile() {
    const response = await getAdminUser(id);
    if (response.data) {
      setAdminData(response.data[0]);
    }
  }

  async function fetchAnnouncement() {
    const response = await getAnnouncementDisplay(annId);
    if (response.data) {
      var arr = response.data.filter((data, key) => key <= 2);
      setAnnouncementData(arr);
    }
  }

  async function fetchProjects() {
    const response = await getProjectsDisplay();
    if (response.data) {
      var arr = response.data.filter((data, key) => key <= 2);
      setProjectData(arr);
    }
  }

  async function fetchNewLogins() {
    const response = await getNewLogins();
    if (response.data.todays_logins) {
      setNewLoginData(response.data.todays_logins[0]);
    }
  }

  async function fetchNewLoginsTable() {
    const response = await getNewLogins();
    if (response.data.logins) {
      var arr = response.data.logins.filter((data, key) => key <= 2);
      setNewLogin(arr);
    }
  }

  async function fetchNewRegistrants() {
    const response = await getNewRegistrants();
    if (response.data[0]) {
      setNewRegistrants(response.data[0]);
    }
  }

  React.useEffect(() => {
    PioProfile();
  }, []);

  React.useEffect(() => {
    fetchProjects();
    fetchAnnouncement();
    fetchNewLogins();
    fetchNewLoginsTable();
    fetchNewRegistrants();
  }, []);

  React.useEffect(() => {
    let loadingTime = setTimeout(() => {
      setIsReady(true);
    }, 5000);
  }, []);

  React.useEffect(() => {
    if (action !== "" && action.id != null && action.action === "delete-user") {
      setShowModal(true);
    }
  }, [action]);

  React.useEffect(() => {
    if (redirect === "see_all_project") {
      navigateTo('/projects')
    } else if (redirect === "see_all_announcement") {
      navigateTo('/announcement')
    }
  }, [redirect])

  return (
    <div className="page">
      <Row>
        <Col className="col-1">
          <Navbar
            onCollapse={(e) => {
              setInactive(e);
            }}
            active={"DASHBOARD"}
            setIsChecked={setIsChecked}
            isChecked={isChecked}
          />
        </Col>
        <div className="login-container">
          <div className="form-cont mt-4">
            <div className="itc-db-container pt-0">
              <Row className="section-container header-cont ms-3 mt-3">
                <Col className="col-2 center profile-cont">
                  <img className="user-profile " src={Profile}></img>
                </Col>
                <Col className="pt-2 left">
                  <Row>
                    <h1 className="admin-name">
                      {adminData.first_name + " " + adminData.last_name}
                    </h1>
                  </Row>
                  <Row>
                    <h2 className="role-label">{adminData.role}</h2>
                  </Row>
                  <Row>
                    <p className="campus-name">UP CEBU</p>
                  </Row>
                </Col>
              </Row>

              <Row className="section-container pt-4 ms-2">
                <Row className="input-shadow cont-color-grey ms-2">
                  <Row className="pt-2">
                    <div className="col-6 left">
                      <h3 className="left section-header ms-3 mt-2 mb-3">
                        PROJECTS
                      </h3>
                    </div>
                    <div className="col-6 right">
                      <button
                        className="see-all-btn me-0 right"
                        onClick={() => setRedirect("see_all_project")}
                      > SEE ALL </button>
                    </div>
                  </Row>

                  <Row className="section-body">
                    {projectData.map((data, key) => {
                      return (
                        <div>
                          <Row className="mt-1">
                            <Col className="col-2 left">
                              <label className="subtitle">{data.name}</label>
                            </Col>
                            <Col className="col-2 left">
                              <label type="date" className="subtitle"></label>
                              {data.date}
                            </Col>
                            <Col className="col-4 left">
                              <label className="subtitle">
                                {data.description}
                              </label>
                            </Col>
                            <Col xs={2} className="section-btns left">
                              <button className="left button-maroon me-1">
                                {data.tag_1}
                              </button>
                              {
                                data.tag_2 ? (
                                  <button className="left mt-1 me-1 button-yellow ">
                                    {data.tag_2}
                                  </button>
                                ) : (
                                  <div></div>
                                )
                              }
                            </Col>
                            <Col xs={2} className="section-btns left">
                              {
                                data.tag_3 ? (
                                  <button className="left me-1 button-green">
                                    {data.tag_3}
                                  </button>
                                ) : (
                                  <div></div>
                                )
                              }
                              {
                                data.tag_4 ? (
                                  <button className="left mt-1 me-1 button-maroon">
                                    {data.tag_4}
                                  </button>
                                ) : (
                                  <div></div>
                                )
                              }
                              {
                                data.tag_5 ? (
                                  <button className="left me-1 mt-1 button-green">
                                    {data.tag_5}
                                  </button>
                                ) : (
                                  <div></div>
                                )
                              }
                            </Col>
                          </Row>
                          <hr></hr>
                        </div>
                      );
                    })}
                  </Row>
                </Row>
              </Row>

              <Row className="section-container ms-3">
                <Col className="subsection-container mt-4 right input-shadow col-5.5 me-3">
                  <Row className="pt-2">
                    <h3 className="left section-header ms-3 mt-2 mb-3">
                      NEW LOGINS
                    </h3>
                  </Row>

                  <Row className="section-body">
                    {newLogin.map((data, ley) => {
                      return (
                        <div>
                          <Row className="pt-1 left pb-1">
                            <Col className="col-5">
                              <label className="subtitle">{data.name}</label>
                            </Col>
                            <Col className="col-4">
                              <label type="date" className="subtitle ">
                                {data.logged_on}
                              </label>
                            </Col>
                            <Col className="col-3">
                              <label className="subtitle">{data.portal}</label>
                            </Col>
                          </Row>
                          <hr></hr>
                        </div>
                      );
                    })}
                  </Row>
                </Col>
                <Col className="subsection-container input-shadow right mt-4 col-6">
                  <Row className="pt-2 mb-3 mt-2">
                    <div className="col-6 left">
                      <h3 className="left section-header">ANNOUNCEMENTS</h3>
                    </div>
                    <div className="col-6 right">
                      <button
                        className="see-all-btn me-0 right"
                        onClick={() => setRedirect("see_all_announcement")}
                      >
                        SEE ALL
                      </button>
                    </div>
                  </Row>

                  <Row className="section-body left">
                    {announcementData.map((data, key) => {
                      const date_posted = data.date_posted;
                      const date_only = new Date(date_posted).toLocaleString(
                        "en-US",
                        {
                          month: "numeric",
                          day: "2-digit",
                          year: "numeric",
                        }
                      );
                      return (
                        <div>
                          <Row className=" pt-1 pb-1 left">
                            <Row>
                              <Col className="col-4 mb-2">
                                <button className="left button-maroon">
                                  {date_only}
                                </button>
                              </Col>
                            </Row>
                            <Row>
                              <label className="subtitle">{data.content}</label>
                            </Row>
                          </Row>
                          <hr></hr>
                        </div>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
}

export default DashboardItc;
