import { getToken, getUser, getDataId, removeSession, refreshPage } from "../../Helpers/Utils/Common";
import { postAPICall, postAPICallCustom } from "../axiosMethodCalls";


/***************************
 * PROJECT
 ***************************/

const token = getToken();
const user = getUser();
const id = getDataId();

//POST
export const createProjects= async (data) => {
   try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "projects/add", {
        token: token,
        requester: user,
        department_id: data.department_id,
        name: data.name,
        project_head: data.project_head,
        date: data.date,
        tag_1: data.tag_1,
        tag_2: data.tag_2,
        tag_3: data.tag_3,
        tag_4: data.tag_4,
        tag_5: data.tag_5,
        budget: data.budget,
        in_kind: data.in_kind,
        contact_person: data.contact_person,
        email: data.email,
        description: data.description,
    });
      return ({data:response});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getProjects = async (id, date) => {
  try {
      const dateFrom = date.from ? date.from : ""
      const dateTo = date.to ? date.to : ""
      const response = await postAPICall(process.env.REACT_APP_LINK + "projects/get", {
          requester: user,
          token: token,
          department_id: id,
          date_from: dateFrom,
          date_to: dateTo
      });
      return ({data:response.data.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getProjectsDisplay = async (id) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "projects/get", {
          requester: user,
          token: token,
          project_id: id,
      });
      return ({data:response.data.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const updateProjects = async (id, data) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "projects/update", {
          requester: user,
          token: token,
          project_id: id,
          department_id: data.department_id,
          name: data.name,
          project_head: data.project_head,
          date: data.date,
          tag_1: data.tag_1,
          tag_2: data.tag_2,
          tag_3: data.tag_3,
          tag_4: data.tag_4,
          tag_5: data.tag_5,
          budget: data.budget,
          in_kind: data.in_kind,
          contact_person: data.contact_person,
          email: data.email,
          description: data.description,
    });
      return ({data:response});   
    } catch (error) {
      return ({error: error.response});
  }
}

export const deleteProjects = async (id) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "projects/delete", {
          requester: user,
          token: token,
          project_id: id,
      });
      return ({data:response});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getAlumniPledge= async (id, filter) => {
  try {
      const date_from = filter.date_from ? filter.date_from : ''
      const date_to = filter.date_to ? filter.date_to : ''
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_pledges/get_by_project", {
          requester: user,
          token: token,
          project_id: id,
          date_from: date_from,
          date_to: date_to
      });
      return ({data:response.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}