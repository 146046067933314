import { handleValidationChange } from "../CommonValidation";

export const validateAddCourse = (data, setIsError) => {
    var isValid = true

    if (data.department_id === "") {
      handleValidationChange("department_id", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("department_id", false, setIsError);
    }

    if (data.course_name === "") {
      handleValidationChange("course_name", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("course_name", false, setIsError);
    }

    return isValid
}