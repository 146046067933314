import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import './App.css';
import { getUser, getRole } from './Helpers/Utils/Common';
import { removeUserSession } from './Helpers/Utils/Common';
import Login from './Pages/Login/Login';
import SingleEntry from './Pages/SingleEntry/SingleEntry';
import EditAlumni from './Pages/EditAlumni/EditAlumni';
import BatchEntry from './Pages/BatchEntry/BatchEntry';
import Alumni from './Pages/Alumni/Alumni';
import Announcement from './Pages/Announcement/Announcement';
import StudentProfile from './Pages/StudentsProfile/StudentProfile';
import StudentProfileWOInterview from './Pages/StudentsProfile/StudentProfileWOInterview'
import Projects from './Pages/Projects/Projects';
import Scholarship from './Pages/Scholarship/Scholarship';
import ManageUser from './Pages/ManageUser/ManageUser';
import DashboardDean from './Pages/Dashboards/DashboardDean';
import DashboardItc from './Pages/Dashboards/DashboardItc';
import DashboardPio from './Pages/Dashboards/DashboardPio';
import DashboardOsa from './Pages/Dashboards/DashboardOsa';
import ExitInterview from './Pages/ExitInterview/ExitInterviewOverall';
import ExitInterviewQualitative from "./Pages/ExitInterview/ExitInterviewQualitative";
import ExitInterviewAlumni from './Pages/ExitInterview/ExitInterview';
import ProjectPledge from './Pages/Projects/ProjectPledge';
import EmployabilityRate from './Pages/Reports/EmployabilityRate'
import FurtherStudies from './Pages/Reports/FurtherStudies'
import EmployabilityStatus from './Pages/Reports/EmployabilityStatus';
import GraduatesReport from './Pages/Reports/GraduatesReport';
import IdleTimer from './Components/IdleTImer';
import DepartmentManager from "./Pages/ManageDeparment/department_manager";
import CourseManager from "./Pages/ManageCourse/course_manager";

function App() {
  document.title = "UP CEBU ALUMNI TRACKER ADMIN";
  const [isTimeout, setIsTimeout] = useState(false);
  const user = getUser();
  const roleId = getRole();

  async function Timer() {
    const timer = new IdleTimer({
      timeout: 100000, //expire after 10 seconds
      onTimeout: () => {
        setIsTimeout(true);
        removeUserSession()
      },
      onExpired: () => {
      }
    });

    return () => {
      timer.cleanUp();
    };
  
  }

  React.useEffect(() => {
    Timer();
  }, []);


  return (
   
    <div className="App">
      <div> {isTimeout ? 
      <Router>
        <Routes>
          <Navigate to='/'/>
          {/* <Route path="/" element={<Login />}/> */}
        </Routes>
      </Router>
       : 
      <Router>
        <Routes>
          <Route path="/" element={<Login />}/>
        </Routes>
        <Routes>
          <Route path="/single-entry" element={ (user && (roleId==='1'||roleId==='4'||roleId==='5'||roleId==='6'||roleId==='7')) ? <SingleEntry/> : <Navigate to='/'/>}/>
        </Routes>
        <Routes>
          <Route path="/edit-alumni/:id" element={(user && (roleId==='1'||roleId==='4'||roleId==='5'||roleId==='6'||roleId==='7')) ? <EditAlumni/> : <Navigate to='/'/>}/>
        </Routes>
        <Routes>
          <Route path="/batch-entry" element={ (user && (roleId==='1'||roleId==='4'||roleId==='5'||roleId==='6'||roleId==='7')) ? <BatchEntry/> : <Navigate to='/'/>}/>
        </Routes>
        <Routes>
          <Route path="/alumni" element={user ? <Alumni/> : <Navigate to='/'/>}/>
        </Routes>
        <Routes>
          <Route path="/announcement" element={(user && roleId!=='3') ? <Announcement /> : <Navigate to='/'/>}/>
        </Routes>
        <Routes>
          <Route path="/student-profile/:id" element={user ? <StudentProfile /> : <Navigate to='/'/>}/>
        </Routes>
        <Routes>
          <Route path="/student-profile-without-interview" element={user ? <StudentProfileWOInterview /> : <Navigate to='/'/>}/>
        </Routes>
        <Routes>
          <Route path="/projects" element={(user && roleId!=='3')  ? <Projects /> : <Navigate to='/'/>}/>
        </Routes>
        <Routes>
          <Route path="/projects-pledge/:id" element={(user && roleId!=='3')  ? <ProjectPledge /> : <Navigate to='/'/>}/>
        </Routes>
        <Routes>
          <Route path="/scholarship" element={(user && roleId!=='2')  ? <Scholarship /> : <Navigate to='/'/>}/>
        </Routes>
        <Routes>
          <Route path="/manage-user" element={(user && (roleId==='1'||roleId==='4'||roleId==='5'||roleId==='6'||roleId==='7'))  ? <ManageUser /> : <Navigate to='/'/>}/>
        </Routes>
        <Routes>
          <Route 
            path="/dashboard" 
            element={ (user && (roleId==='1'||roleId==='4'||roleId==='5'||roleId==='7'||roleId==='8'||roleId==='10')) ? <DashboardDean /> : <Navigate to='/'/>}
          />
        </Routes>
        <Routes>
          <Route 
            path="/dashboard-itc" 
            element={ (user && roleId==='6') ? <DashboardItc /> : <Navigate to='/'/>}
          />
        </Routes>
        <Routes>
          <Route 
            path="/dashboard-pio" 
            element={ (user && roleId==='2') ? <DashboardPio/> : <Navigate to='/'/>}
          />
        </Routes>
        <Routes>
          <Route 
            path="/dashboard-osa" 
            element={ (user && roleId==='3') ? <DashboardOsa /> : <Navigate to='/'/>}
          />
        </Routes>
        <Routes>
          <Route path="/exit-interview" element={ user ? <ExitInterview /> : <Navigate to='/'/> }/>
        </Routes>
        <Routes>
          <Route path="/exit-interview-qualitative" element={ (user && roleId!=='2') ? <ExitInterviewQualitative /> : <Navigate to='/'/> }/>
        </Routes>
        <Routes>
          <Route path="/exit-interview-alumni/:id" element={(user && roleId!=='2') ? <ExitInterviewAlumni/> : <Navigate to='/'/> }/>
        </Routes>
        <Routes>
          <Route path="/employability_rate/:months" element={(user && (roleId!=='2'&&roleId!=='3')) ? <EmployabilityRate/> : <Navigate to='/'/> }/>
        </Routes>
        <Routes>
          <Route path="/further_studies" element={(user && (roleId!=='2'&&roleId!=='3')) ? <FurtherStudies/> : <Navigate to='/'/>}></Route>
        </Routes>
        <Routes>
          <Route path="/employability_status" element={(user && (roleId!=='2'&&roleId!=='3')) ? <EmployabilityStatus/> : <Navigate to='/'/>}></Route>
        </Routes>
        <Routes>
          <Route path="/department" element={(user && (roleId!=='2'&&roleId!=='3')) ? <DepartmentManager/> : <Navigate to='/'/>}></Route>
        </Routes>
        <Routes>
          <Route path="/course" element={(user && (roleId!=='2'&&roleId!=='3')) ? <CourseManager/> : <Navigate to='/'/> }></Route>
        </Routes>
      </Router>
}
      </div>
    </div>
  );
}

export default App;
