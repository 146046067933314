import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./InputError.css"

const  InputError = ({isValid , message}) => {
    if(!isValid) {
        return <></>
    }
    else {
      return (
        <div className="validity-error callout bottom">
          <span className="error-text">
            {message}
          </span>
        </div>
      );
    }
}

export default InputError


