import { handleValidationChange } from "../CommonValidation";

export const validateAddAnnouncement = (data, setIsError) => {
    var isValid = true

    if (data.subject === "") {
      handleValidationChange("subject", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("subject", false, setIsError);
    }

    if (data.date_posted === "") {
      handleValidationChange("date_posted", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("date_posted", false, setIsError);
    }

    if (data.content === "") {
      handleValidationChange("content", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("content", false, setIsError);
    }

    if (data.department === "") {
      handleValidationChange("department", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("department", false, setIsError);
    }

    return isValid
}