import Navbar from "../../Components/Navbar/Navbar";
import React, { useState } from "react";
import Table from "../../Components/Table/Table";
import ModalPopUp from "../../Components/Modal/Modal";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { refreshPage } from "../../Helpers/Utils/Common";
import { Row, Col } from "react-bootstrap";
import { getRole } from "../../Helpers/Utils/Common";
import { getDepartment } from "../../Helpers/Utils/Common";
import { validateAddProject } from "../../Helpers/Validation/Project";
import {
  createProjects,
  deleteProjects,
  getProjects,
  getProjectsDisplay,
  updateProjects,
} from "../../APICalls/ProjectsApi/ProjectsApi";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "./Projects.css";

function Projects({ setIsChecked, isChecked }) {
  const navigateto = useNavigate();
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [action, setAction] = useState({});
  const [projectId, setProjectId] = useState();
  const [isClicked, setIsClicked] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [data, setData] = useState({});
  const userRole = getRole();
  const [projectHistoryData, setProjectHistoryData] = useState([]);

  const [tag, setTag] = useState([{ tag: "" }]);

  const handleButtonChange = (id) => {
    var arr = [...tag];
    arr[id].state = !tag[id].state;
    setTag(arr);

    var isFive =
      tag.filter((data) => data.state === true).length >= 5 ? true : false;
    var newArr = [...tag];
    if (isFive) {
      setTag(newArr);
    }
  };

  const [projectDetails, setProjectDetails] = useState({
    project_id: projectId,
    department_id: "",
    name: "",
    tag_1: "",
    tag_2: "",
    tag_3: "",
    tag_4: "",
    tag_5: "",
    project_head: "",
    date: "",
    subject: "",
    in_kind: "",
    contact_person: "",
    email: "",
    description: "",
  });

  const [isError, setIsError] = useState({
    department_id: false,
    name: false,
    tag_1: false,
    project_head: false,
    contact_person: false,
    email: false,
  });

  const [filterDate, setFilterDate] = useState({
    from: "",
    to: "",
  });

  async function fetchProject(filterDate) {
    setProjectHistoryData([]);
    const department_id = getDepartment();
    const response = await getProjects(department_id, filterDate);
    if (response.data) {
      setProjectHistoryData(response.data);
      setIsReady(true);
    }
  }

  async function editProject(id) {
    const response = await getProjectsDisplay(id);
    if (response.data) {
      setUpdateData(response.data);
      setShowModalEdit(true);
    }
  }

  async function deleteProject(id) {
    const response = await getProjectsDisplay(id);
    if (response.data) {
      setData(response.data);
      setShowModalDelete(true);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdateChange = (e) => {
    setUpdateData({ ...updateData, [e.target.name]: e.target.value });
    setData({ ...updateData, [e.target.name]: e.target.value });
  };

  async function add() {
    if (validateAddProject(projectDetails, setIsError) && !isClicked) {
        setIsClicked(true)
        const response = await createProjects(projectDetails); 
        if (response.data && response.data.status === 200) {
          toast.success(response.data.data.response.toUpperCase());
          setTimeout(function () {
            refreshPage()
          }, 500);
        } else {
          setIsClicked(false);
          toast.error(response.error.data.messages.error.toUpperCase());
        }
    }
  }

  async function update() {
    if (validateAddProject(updateData, setIsError) && !isClicked) {
      setIsClicked(true);
      const response = await updateProjects(projectId, updateData, tag);
      if (response.data && response.data.status === 200) {
        toast.success(response.data.data.response.toUpperCase());
        setTimeout(function () {
          refreshPage();
        }, 500);
      } else {
        setIsClicked(false);
        toast.error(response.error.data.messages.error.toUpperCase());
      }
    } 
  }

  async function del() {
    const response = await deleteProjects(projectId);
    if (response.data?.status === 200) {
      toast.success(response.data.data.response.toUpperCase());
    } else {
      toast.error(response.error.data.response.toUpperCase());
    }
    setShowModalDelete(false);
    setTimeout(function () {
      refreshPage();
    }, 1500);
  }

  const handleSelectChange = (e) => {
    const { id, name, value } = e.target;
    setProjectId(id);
    if (value === "update") {
      editProject(id);
    } else if (value === "delete") {
      deleteProject(id);
    } else if (value === "pledge") {
      navigateto("/projects-pledge/" + e.target.id);
    }
  };

  React.useEffect(() => {
    fetchProject(filterDate);
  }, [filterDate]);

  React.useEffect(() => {
    if (action !== "" && action.id != null && action.action === "delete") {
      setShowModalDelete(true);
    } else if (
      action !== "" &&
      action.id != null &&
      action.action === "update"
    ) {
      setShowModalEdit(true);
    }
  }, [action]);

    return (
        <div className="page">
          <ToastContainer/>
            <Navbar
            onCollapse={(e) => {
                setInactive(e);
            }}
            active={"PROJECTS"}
            setIsChecked={setIsChecked}
            isChecked={isChecked}
            />
            <div className=" login-container">
              <div className={`container ${inactive ? "inactive" : "active"}`}>    
                <div className="form-cont pt-4">
                    <div className="container ms-1 pt-0">
                      <Row>
                        <Col className = "col-2">
                          <h1 className="project-title left">PROJECTS</h1>
                        </Col>
                        <Col className='col-10'>
                            {
                              (userRole==='1'||userRole==='4'||userRole==="5"||userRole === "6"||userRole==="7") && (
                                <Row className='d-flex justify-content-end'>
                                  <Col xs={2} className='me-2'>
                                    <Row><span className="input-subtitle left">From:</span></Row>
                                    <Row><input type="date" name="date_from" className="form-control input-shadow input-green-bg input-subtitle left from_date" id="date_from" aria-describedby="date_from" onChange={(e) => setFilterDate({...filterDate, from: e.target.value})}/></Row>
                                  </Col>
                                  <Col xs={2} className='me-2'>
                                    <Row><span className="input-subtitle left">To:</span></Row>
                                    <Row><input type="date" name="date_to" className="form-control input-shadow input-green-bg input-subtitle left from_date" id="date_to" aria-describedby="date_to" onChange={(e) => setFilterDate({...filterDate, to: e.target.value})}/></Row>
                                  </Col>
                                  <Col xs={2} className='me-2'>
                                    <Row><span className="input-subtitle left white-text">add btn</span></Row>
                                    <Row><button className="maroon-btn" onClick={()=>setShowModalAdd(true)}>ADD PROJECTS</button></Row>
                                  </Col>
                                </Row>
                              )
                            }
                            {
                              (userRole === "2" || userRole === "8"||userRole === "10") && (
                                <Row className='d-flex justify-content-end'>
                                  <Col xs={2} className='me-3 w-49'>
                                    <Row><span className="input-subtitle left">From:</span></Row>
                                    <Row><input type="date" name="date_from" className="form-control input-shadow input-green-bg input-subtitle left from_date" id="date_from" aria-describedby="date_from"/></Row>
                                  </Col>
                                  <Col xs={2} className='me-3 w-49'>
                                    <Row><span className="input-subtitle left">To:</span></Row>
                                    <Row><input type="date" name="date_to" className="form-control input-shadow input-green-bg input-subtitle left from_date" id="date_to" aria-describedby="date_to"/></Row>
                                  </Col>
                                </Row>
                              )
                            }
                        </Col>
                      </Row>

                      <Row className="row-form mb-4 mt-4 project-wrapper">
                        <Table
                              type={"project"}
                              tableData={projectHistoryData}
                              clickable={true}
                              headingColumns={(userRole!=="3"&&userRole!=="8"&& userRole!=="10")?[
                                  "PROJECT DATE",
                                  "PROJECT NAME",
                                  "PROJECT HEAD",
                                  "TARGET",
                                  "BUDGET",
                                  "DESCRIPTION",
                                  "ACTION",
                              ]:[
                                  "PROJECT DATE",
                                  "PROJECT NAME",
                                  "PROJECT HEAD",
                                  "TARGET",
                                  "BUDGET",
                                  "DESCRIPTION",
                              ]}
                              setAction={setAction}
                              useLoader={true}
                              isReady={isReady}
                              onSelectChange={handleSelectChange}
                          />
                      </Row>
                    </div>
                </div>
            </div>
       
            <ModalPopUp
                type="delete-project"
                show={showModalDelete}
                handleClose={() => setShowModalDelete(false)}
                handleDelete={del}
                data={data}
                setData={handleUpdateChange}
            />
            <ModalPopUp
                type="add-project"
                show={showModalAdd}
                handleClose={() => setShowModalAdd(false)}
                data={projectDetails}
                handleAdd={add}
                setData={handleChange}
                isError={isError}
            />
            <ModalPopUp
                type="edit-project"
                show={showModalEdit}
                handleClose={() => setShowModalEdit(false)}
                data={updateData}
                handleUpdateTag={handleButtonChange}
                setData={handleUpdateChange}
                handleUpdate={update}
                isError={isError}
            />
            
      </div>
    </div>
  );
}
export default Projects;
