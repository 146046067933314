import { postAPICall } from "./axiosMethodCalls";
import { getBranch, getUser, getKey, getToken, refreshPage } from "../Helpers/Utils/Common";

/***************************
 * LOGIN
 ***************************/

//POST
export const loginUser = async (credential, login_type) => {
  try {
    const response = await postAPICall(process.env.REACT_APP_LINK + "login", {
      credential: credential,
      login_type: login_type,
    });
    return { data: response};
  } catch (error) {
    return { data: error.response.data };
  }
};


/***************************
 * LOGOUT
 ***************************/
//POST
export const logoutUser = async () => {
    try {
        const response = await postAPICall(process.env.REACT_APP_LINK + 'logout');
    } catch (error) {
        return ({error: error});
    }
}


