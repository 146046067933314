
import { ToastContainer, toast } from "react-toastify";
import { CSVLink } from "react-csv";
import React, { useState, useRef } from "react";
import {Row, Col} from 'react-bootstrap'
import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/Table/Table";
import ModalPopUp from "../../Components/Modal/Modal";
import { refreshPage, getRole } from "../../Helpers/Utils/Common";
import {
  createScholarship,
  deleteScholarship,
  getScholarship,
  getScholarshipUpdate,
  updateScholarship,
} from "../../APICalls/ScholarshipApi/ScholarshipApi";
import { validateAddScholarship } from "../../Helpers/Validation/Scholarship";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "./Scholarship.css";


function Scholarship({ setIsChecked, isChecked }) {
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [id, setId] = useState();
  const userRole = getRole();
  const componentRef = useRef()
  const [isClicked, setIsClicked] = useState(false);
  const [scholarshipHistoryData, setScholarshipHistoryData] = useState([]);
  const [scholarshipData, setScholarshipData] = useState({});
  const [scholarship, setScholarship] = useState({});
  const [scholarshipDetails, setScholarshipDetails] = useState({
    name: "",
    contact_person: "",
    target_date: "",
    budget: "",
    email: "",
    description: "",
  });

  const [isError, setIsError] = useState({
    name: false,
    contact_person: false,
    email: false,
  });

  const csvHeaders = [
    {label: "DATE", key: "added_on"},
    {label: "SCHOLARSHIPS NAME", key: "name"},
    {label: "CONTACT PERSON", key: "contact_person"},
    {label: "TARGET DATE", key: "target_date"},
    {label: "BUDGET", key: "budget"},
    {label: "DESCRIPTION", key: "description"}
  ];

  const [filterDate, setFilterDate] = useState({
    from: "",
    to: "",
  });

  async function fetchScholarship(filterDate) {
    setScholarshipHistoryData([]);
    const response = await getScholarship(filterDate);
    if (response.data) {
      var scholarship_details = response.data;
      setScholarshipHistoryData(scholarship_details);
      setIsReady(true);
    }
  }

  async function Scholarship(id) {
    const response = await getScholarshipUpdate(id);
    if (response.data) {
      setScholarshipData(response.data);
      setShowModalEdit(true);
    }
  }

  async function ScholarshipDelete(id) {
    const response = await getScholarshipUpdate(id);
    if (response.data) {
      setScholarship(response.data);
      setShowModalDelete(true);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setScholarshipDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdateChange = (e) => {
    setScholarshipData({ ...scholarshipData, [e.target.name]: e.target.value });
    setScholarship({ ...scholarshipData, [e.target.name]: e.target.value });
  };

  async function add() {
    if (validateAddScholarship(scholarshipDetails, setIsError)&& !isClicked) {
      setIsClicked(true)
      const response = await createScholarship (scholarshipDetails);
      if (response.data && response.data.status === 200) {
        toast.success(response.data.data.response.toUpperCase());
        setTimeout(function () {
          refreshPage();
        }, 500);
      } else {
        setIsClicked(false)
        toast.error(response.error.data.messages.error.toUpperCase());
      }} 
  }

  async function update() {
    if (validateAddScholarship(scholarshipData, setIsError)&& !isClicked) { 
      const response = await updateScholarship(id, scholarshipData);
      if (response.data && response.data.status === 200) {
        setIsClicked(true);
        toast.success(response.data.data.response.toUpperCase());
        setTimeout(function () {
          refreshPage();
        }, 500);
      } else {
        setIsClicked(false);
        toast.error(response.error.data.messages.error.toUpperCase());
      }
    }
    
  }

  async function del() {
    const response = await deleteScholarship(id);
    if (response.data?.status === 200) {
      toast.success(response.data.data.response.toUpperCase());
    } else {
      toast.error(response.error.data.response.toUpperCase());
    }
    setShowModal(false);
    setTimeout(function () {
      refreshPage();
    }, 500);
  }

  React.useEffect(() => {
    fetchScholarship(filterDate);
  }, [filterDate]);

  const handleSelectChange = (e) => {
    const { id, value } = e.target;
    setId(id);
    if (value === "update") {
      Scholarship(id);
    }
    if (value === "delete") {
      ScholarshipDelete(id);
    }
  };

  return (
    <div className="page">
      <ToastContainer />
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />

      <div className="login-container">
        <div className="form-cont pt-4">
          <div className="container pt-0">
              <Row className="">
                <Col className="" xs={2}>
                  <h1 className="title left">SCHOLARSHIPS</h1>
                </Col>
                <Col>
                <div className="d-flex justify-content-end">
                <Col xs={2} className="me-1">
                  <span className="input-subtitle">From:</span>
                  <br />
                  <input
                    type="date"
                    name="date_from"
                    className="form-control input-subtitle input-shadow input-green-bg"
                    id="date_from"
                    aria-describedby="date_from"
                    onChange={(e) =>
                      setFilterDate({ ...filterDate, from: e.target.value })
                    }
                  />
                </Col>
                <Col xs={2} className="me-1">
                  <span className="input-subtitle left">To:</span>
                  <br />
                  <input
                    type="date"
                    name="date_to"
                    className="form-control input-subtitle input-shadow input-green-bg"
                    id="date_to"
                    aria-describedby="date_to"
                    onChange={(e) =>
                      setFilterDate({ ...filterDate, to: e.target.value })
                    }
                  />
                </Col>
                <Col xs={2} className="me-1 pt-4">
                    <CSVLink
                        data={scholarshipHistoryData}
                        headers={csvHeaders}
                        filename={"Scholarships"}
                      >
                        <button className="width-fill orange-btn">
                          DOWNLOAD CSV
                        </button>
                      </CSVLink>
                  </Col>
                {
                  (userRole !== "2" || userRole !== "8" || userRole !== "10") && (
                    <Col xs={2.5} className="pt-4">
                      <button
                        className="maroon-btn"
                        onClick={() => setShowModal(true)}
                      >
                        ADD SCHOLARSHIP
                      </button>
                  </Col>
                  )
                }
                </div></Col>
              </Row>

              <div className="row row-form mt-4 mb-4 scholarship-wrapper">
                <Table
                  type={"scholarship"}
                  tableData={scholarshipHistoryData}
                  clickable={true}
                  headingColumns={(userRole!=="2"&&userRole!=="8"&& userRole!=="10")?[
                    "DATE",
                    "SCHOLARSHIP NAME",
                    "CONTACT PERSON",
                    "TARGET DATE",
                    "BUDGET",
                    "DESCRIPTION",
                    "ACTION",
                  ]:[
                    "DATE",
                    "SCHOLARSHIP NAME",
                    "CONTACT PERSON",
                    "TARGET DATE",
                    "BUDGET",
                    "DESCRIPTION",
                  ]}
                  useLoader={true}
                  isReady={isReady}
                  onSelectChange={handleSelectChange}
                />
              </div>

            <div className="row row-form">
              <div className="col right"></div>
            </div>
          </div>
        </div>
      </div>
      <ModalPopUp
        type="add-scholarship"
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleAdd={add}
        data={scholarshipDetails}
        setData={handleChange}
        isError={isError}
      />
      <ModalPopUp
        type="delete-scholarship"
        show={showModalDelete}
        handleClose={() => setShowModalDelete(false)}
        handleDelete={del}
        data={scholarship}
        setData={handleUpdateChange}
      />
      <ModalPopUp
        type="edit-scholarship"
        show={showModalEdit}
        handleClose={() => setShowModalEdit(false)}
        data={scholarshipData}
        setData={handleUpdateChange}
        handleUpdate={update}
        isError={isError}
      />
    </div>
  );
}

export default Scholarship;
