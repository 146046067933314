import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import Navbar from "../../Components/Navbar/Navbar";
import ModalPopUp from "../../Components/Modal/Modal";
import { addDepartment, getDepartment, updateDepartment, deleteDepartment } from "../../APICalls/Department/DepartmentApi";
import { getUser, getToken } from "../../Helpers/Utils/Common";
import { postAPICall } from "../../APICalls/axiosMethodCalls";
import { refreshPage, removeSession } from "../../Helpers/Utils/Common";
import {validateAddDepartment} from '../../Helpers/Validation/Department'
import Table from "../../Components/Table/Table";

function DepartmentManager() {
  const [inactive, setInactive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [allDepartments, setAllDepartments] = useState([]);
  const [updateData, setUpdateData] = useState({})
  const [deptId, setDeptId] = useState()

  const [departmentData, setDepartmentData] = useState({
    department_name: '',
    college_id: '',
  });

  const [isError, setIsError] = useState({
    department_name: false,
    college_id: false,
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setDepartmentData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (e) => {
    const { id, name, value } = e.target;
    setDeptId(id);
    if (value === "update") {
      editDepartment(id);
      setShowModalEdit(true);
    }
  };

  const handleUpdateChange = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
  };

  async function editDepartment(id) {
    const response = await getDepartment(id);
    if (response.data) {
      const res = response.data[0]
      res.department_name = res.department;
      setUpdateData(res);
      setShowModalEdit(true);
    }
  }

   async function deleteDepartmentById(id) {
    const response = await getDepartment(id);
    if (response.data) {
      const res = response.data[0]
      res.department_name = res.department;
      setUpdateData(res);
      setShowModalDelete(true);
    }
  }

  async function add() {
    if (validateAddDepartment(departmentData, setIsError) === true && isClicked === false) {
      setIsClicked(true)
       const response = await addDepartment(departmentData);
      if (response.data && response.data.status === 200) {
        toast.success(response.data.data.response.toUpperCase());
        setTimeout(function () {
          refreshPage();
        }, 1500);
    } else {
      setErrorMessages(response.error.data.messages)
      toast.error(response.error.data.messages.error.toUpperCase());
    }} 
  }

  async function update() {
    if (validateAddDepartment(updateData, setIsError) && !isClicked) {
      setIsClicked(true)
      const response = await updateDepartment(deptId, updateData);
      if (response.data) {
        toast.success(response.data.data.response.toUpperCase());
        setTimeout(function () {
          refreshPage();
        }, 1500);
      } else {
        setIsClicked(false)
        toast.error(response.error.data.messages.error.toUpperCase());
      }
    }
  }

  async function deleteDept() {
    const response = await deleteDepartment(deptId)
    if (response.data?.status === 200) {
      toast.success(response.data.data.response.toUpperCase());
    } else {
      toast.error(response.error.data.response.toUpperCase());
    }
    setShowModalDelete(false);
    setTimeout(function () {
      refreshPage();
    }, 1500);
  }

  React.useEffect(() => {
    const fetchData = async() => {
      try {
          const response = await postAPICall (process.env.REACT_APP_LINK + "departments/get", {
              requester: await getUser(),
              token: await getToken(),
              department_id: '',
              college_id: ''
          })
          var departments = response.data.data
          var selected = departments.map((data) => {
              return {value: data.id, label: data.department, college: data.college}
          })
          setAllDepartments(selected);
          setIsReady(true);
      } catch (error) {
          if (error.response.status === 401) {
              removeSession()
              refreshPage()
          } else {
            setAllDepartments([]);
            setIsReady(true);
          }
      }
    };
    fetchData();
  }, []);

  
  return (
    <div className="page">
      <ToastContainer />
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
      />
      <div className="login-container center">
        <div className={`container ${inactive ? "inactive" : "active"}`}>
          <div className="form-cont .center-form-cont pt-4">
            <div className="container ms-1 pt-0">
              <Row className="row-height">
                <Col className="col-10">
                  <h1 className="title left">MANAGE DEPARTMENT</h1>
                </Col>
                <Col className="col-2 right">
                  <div className="pt-2">
                    <button className="right maroon-btn" onClick={() => setShowModal(true)}> ADD DEPARTMENT</button>
                  </div>
                </Col>
              </Row>
              <div className="row row-form mb-4">
             
              <Table
                  type={"manage_departments"}
                  tableData={allDepartments}
                  clickable={true}
                  headingColumns={["DEPARTMENT", "COLLEGE", "ACTION"]}
                  onSelectChange={handleSelectChange}
                  useLoader={true}
                  isReady={isReady}
                />
              </div>
            </div>
          </div>
        </div>
        <ModalPopUp
          type="add-department"
          show={showModal}
          handleClose={() => setShowModal(false)}
          handleAdd={add}
          data={departmentData}
          setData={handleChange}
          isError={isError}
          errorMessages={errorMessages}
        />

        <ModalPopUp
          type="edit-department"
          show={showModalEdit}
          handleClose={() => setShowModalEdit(false)}
          data={updateData}
          setData={handleUpdateChange}
          handleUpdate={update}
          isError={isError}
          errorMessages={errorMessages}
        />
        {/* <ModalPopUp
          type="delete-department"
          show={showModalDelete}
          handleClose={() => setShowModalDelete(false)}
          handleDelete={deleteDept}
          data={updateData}
          setData={handleUpdateChange}
        /> */}
      </div>
    </div>
  );
}

export default DepartmentManager;
