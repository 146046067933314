import React from "react";
import { useState, useRef } from "react";
import { ToastContainer } from "react-bootstrap";
import {Row, Col} from 'react-bootstrap'
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";

import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/Table/Table";
import Print from "../../Components/Print/Print";

import { getAlumniTypeInstitution, getAlumniTypeSector, getOldestBatch, getAllAlumniEmployabilityStatus } from '../../APICalls/ManageUserApi'
import { getDepartment, getName } from "../../Helpers/Utils/Common";
import { GetAllBatch } from "../../Helpers/Utils/CommonAPIs";


function EmployabilityStatus() {
    const [inactive, setInactive] = useState(false)
    const [filterData, setFilterData] = useState({
        date_from: "",
        date_to: "",
        institution_types: "",
        sector_type: ""
    })

    const emptyFilter = {
      year_from: '',
      year_to: '',
      degree_program_id: [],
    }
    // const [years, setYears] = useState([])
    const years = GetAllBatch();
    const [isReady, setIsReady] = useState(false)
    const [alumniData, setAlumniData] = useState([])
    const dept_id = getDepartment()
    const [institutionTypes, setInstitutionTypes] = useState([])
    const [sectorTypes, setSectorTypes] = useState([])
    const componentRef = useRef()
    const [printData, setPrintData] = useState([])

    const tableHeaders = [
      'ALUMNI', 'ADDRESS', 'CONTACT NUMBER', 'EMAIL','COMPANY NAME','POSITION','SALARY'
    ]

    const csvHeaders = [
        {label: "ALUMNI", key: "fullname"},
        {label: "ADDRESS", key: "permanent_address"},
        {label: "CONTACT NUMBER", key: "contact_no"},
        {label: "EMAIL", key: "personal_email"},
        { label: "COMPANY NAME", key: "company_institution" },
        { label: "POSITION", key: "position" },
        { label: "SALARY_ESTIMATION", key: "monthly_salary" },
    ]

    async function getInstitutionTypes() {
      setInstitutionTypes([])
      const response = await getAlumniTypeInstitution(dept_id, emptyFilter)
      if (response.data) {
        setInstitutionTypes(response.data.data)
      }
    }

    async function getSectorTypes() {
      setSectorTypes([])
        const response = await getAlumniTypeSector(dept_id, emptyFilter)
        if (response.data) {
          setSectorTypes(response.data.data)
        }
    }

    async function getAlumniData() {
      setAlumniData([])
      setPrintData([])
      const response = await getAllAlumniEmployabilityStatus(dept_id, filterData)
      if (response.data) {
        var alumni = response.data.data
        let print_arr = []
        alumni.map((data) => {
          let temp = []
          data.fullname = `${data.first_name} ${data.last_name}`
          temp.push(data.fullname, data.permanent_address, data.contact_no, data.personal_email,data.company_institution,data.position,data.monthly_salary)
          // return data
          print_arr.push(temp)
        })
        setPrintData(print_arr)
        setAlumniData(alumni)
      }
    }

    React.useEffect(() => {
        getAlumniData()
    }, [filterData])

    React.useEffect(() => {
      getInstitutionTypes()
      getSectorTypes();
    },[])

  return (
    <div className="page">
      <ToastContainer />
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
      />
      <div className="form-cont pt-4">
        <div className="container pt-0">
              <Row className="">
                <h1 className="project-title left">EMPLOYABILITY STATUS</h1>
              </Row>
              <Row className='d-flex justify-content-end me-1'>
                <Col xs={1} className="left me-1">
                  <Row>FROM</Row>
                  <Row>
                    <select
                      type="text"
                      name="from-date"
                      className="maroon-btn"
                      onChange={(e) =>
                        setFilterData({
                          ...filterData,
                          date_from: e.target.value,
                        })
                      }
                    >
                      {years.map((data) => (
                        <option value={data.name}>{data.name}</option>
                      ))}
                    </select>
                  </Row>
                </Col>
                <Col xs={1} className="left me-1">
                  <Row>TO: </Row>
                  <Row>
                    <select
                      type="text"
                      name="from-date"
                      className="maroon-btn"
                      onChange={(e) =>
                        setFilterData({
                          ...filterData,
                          date_to: e.target.value,
                        })
                      }
                    >
                      {years.map((data) => (
                        <option value={data.name}>{data.name}</option>
                      ))}
                    </select>
                  </Row>
                </Col>
                <Col xs={2} className='left  me-1'>
                  <Row> INSTITUTION: </Row>
                  <Row>
                    <select
                      type="text"
                      name="institution_types"
                      className="maroon-btn"
                      onChange={(e) =>
                        setFilterData({
                          ...filterData,
                          institution_types: e.target.value,
                        })
                      }
                    >
                      <option value={''}>All</option>
                      {institutionTypes.map((data) => (
                        <option value={data.institution_type}>{data.institution_type}</option>
                      ))}
                    </select>
                  </Row>
                    
                </Col>
                <Col xs={2} className='left me-1'>
                  <Row>SECTOR:</Row>
                  <Row>
                    <select
                      type="text"
                      name="sector_type"
                      className="maroon-btn"
                      onChange={(e) =>
                        setFilterData({
                          ...filterData,
                          sector_type: e.target.value,
                        })
                      }
                    >
                      <option value={''}>All</option>
                      {sectorTypes.map((data) => (
                        <option value={data.sector}>{data.sector}</option>
                      ))}
                    </select>
                  </Row>
                </Col>
                <Col xs={1} className="left me-1 pt-4 p-0">
                  <ReactToPrint
                    trigger={() => <button className="width-fill orange-btn">Print</button>}
                    content={() => componentRef.current}
                    documentTitle={'Employability Status'}
                  />
                </Col>
                <Col xs={2} className="pt-4 p-0">
                  <CSVLink
                      data={alumniData}
                      headers={csvHeaders}
                      filename={"Employability Status"}
                    >
                      <button className="width-fill orange-btn">
                        DOWNLOAD CSV
                      </button>
                    </CSVLink>
                </Col>
              </Row>
              <div className="row row-form mb-4">
                <Table
                  type={"employability_status"}
                  tableData={alumniData}
                  headingColumns={tableHeaders}
                  useLoader={true}
                  isReady={isReady}
                />
              </div>
               <div className="onlyPrint">
                  <div className="pt-2" ref={componentRef}>
                    <Print
                      title={"EMPLOYABILITY STATUS"}
                      headingColumns={tableHeaders}
                      tableData={printData}
                    />
                  </div>
              </div>

        </div>
      </div>
    </div>
  );
}

export default EmployabilityStatus;
