import { getToken, getUser, getDataId, removeSession, refreshPage } from "../../Helpers/Utils/Common";
import { postAPICall, postAPICallCustom } from "../axiosMethodCalls";


/***************************
 * ALUMNI
 ***************************/

const token = getToken();
const user = getUser();
const id = getDataId();

//POST
export const createAlumni = async (data) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumnae/add", {
          requester: 1,
          token: token,
          preferred_title: data.preferred_title,
          married_surname: data.married_surname,
          last_name: data.last_name,
          first_name: data.first_name,
          middle_name: data.middle_name,
          middle_initial: data.middle_name,
          suffix: data.suffix,
          birthdate: data.birthdate,
          nationality: data.nationality,
          college_id: data.college_id,
          department_id: data.department_id,
          degree_program_id: data.degree_program_id,
          student_no: data.student_no,
          latin_award: data.latin_award,
          month_graduated: data.month_graduated,
          year_graduated: data.year_graduated,
          academic_year: data.academic_year,
          year_conferred: data.year_conferred,
          month_conferred: data.month_conferred,
          scholarship: data.scholarship,
          contact_no: data.contact_no,
          personal_email: data.personal_email,
          pronouns: data.pronouns,
          permanent_address: data.permanent_address,
          permanent_country: data.permanent_country,
          permanent_province: data.permanent_province,
          permanent_city: data.permanent_city,
          permanent_postal_code: data.permanent_postal_code,
          present_address: data.present_address,
          present_country: data.present_country,
          present_province: data.present_province,
          present_city: data.present_city,
          present_postal_code: data.present_postal_code,
        });
        return ({data:response});   
    } catch (error) {
        if (error.response.status === 401) {
          removeSession();
        }
        return ({error: error.response});
    }
  }

export const getAlumni = async (id, date) => {
  try {
      const dateFrom = date.from ? date.from : ""
      const dateTo = date.to ? date.to : ""

      const response = await postAPICall(process.env.REACT_APP_LINK + "alumnae/get", {
          requester: user,
          token: token,
          department_id: id,
          date_from: dateFrom,
          date_to: dateTo
      });
      return ({data:response.data.data});  
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getAlumniProfile = async (id) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumnae/get", {
          requester: user,
          token: token,
          alumni_id: id,
      });
      return ({data:response.data.data});  
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getAlumniUpdate = async (id) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumnae/get", {
          requester: user,
          token: token,
          alumni_id: id,
      });
      return ({data:response.data});  
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const updateAlumni = async (id, data) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumnae/update", {
          requester: user,
          token: token,
          alumni_id: data.id,
          preferred_title: data.preferred_title,
          married_surname: data.married_surname,
          last_name: data.last_name,
          first_name: data.first_name,
          middle_name: data.middle_name,
          suffix: data.suffix,
          birthdate: data.birthdate,
          nationality: data.nationality,
          college_id: data.college_id,
          department_id: data.department_id,
          degree_program_id: data.degree_program_id,
          student_no: data.student_no,
          latin_award: data.latin_award,
          month_graduated: data.month_graduated,
          year_graduated: data.year_graduated,
          academic_year: data.academic_year,
          year_conferred: data.year_conferred,
          scholarship: data.scholarship,
          contact_no: data.contact_no,
          personal_email: data.personal_email,
          pronouns: data.pronouns,
          permanent_address: data.permanent_address,
          permanent_country: data.permanent_country,
          permanent_province: data.permanent_province,
          permanent_city: data.permanent_city,
          permanent_postal_code: data.permanent_postal_code,
          present_address: data.present_address,
          present_country: data.present_country,
          present_province: data.present_province,
          present_city: data.present_city,
          present_postal_code: data.present_postal_code,
          
    });
      return ({data:response});   
    } catch (error) {
      return ({error: error.response});
  }
}

export const deleteAlumni = async (id) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumnae/delete", {
          requester: user,
          token: token,
          alumni_id: id,
          
      });
      return ({data:response});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getEmploymentInformation = async (id) => {
  try {
    
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_employments/get", {
          requester: user,
          token: token,
          alumni_id: id,
          alumni_employment_id: '',
      });
      return ({data:response.data.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getCurrentEmployment = async (id) => {
  try {
    
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_employments/get_current_employment", {
          requester: user,
          token: token,
          alumni_id: id,
          alumni_employment_id: '',
      });
      return ({data:response.data.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getAcademicInformation = async (id) => {

  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_academics/get", {
          requester: user,
          token: token,
          alumni_id: id,
          alumni_academic_id: '',
      });
      return ({data:response.data.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getMembershipInformation= async (id) => {

  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_organizations/get", {
          requester: user,
          token: token,
          alumni_id: id,
          alumni_org_id: '',
      });
      return ({data:response.data.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}





