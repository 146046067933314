import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { getToken, getUser, getDepartment, removeSession, refreshPage } from "./Common";
import { postAPICall } from "../../APICalls/axiosMethodCalls";
import { getOldestBatch } from "../../APICalls/ManageUserApi";


export const GetAllBatch = () => {
    const [years, setYears] = useState([])
    useEffect (() => {
        const fetchData = async () => {
            try {
                const response = await getOldestBatch();
                if (response.data) {
                var years_arr = [{ name: parseInt(response.data[0].oldest_batch) }];
                for (
                    var i = parseInt(response.data[0].oldest_batch);
                    i < new Date().getFullYear();
                    ++i
                ) {
                    years_arr.push({ name: i + 1 });
                }
                setYears(years_arr);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    removeSession()
                    refreshPage()
                }
            }
        }
        fetchData();
    }, [])

    return years;
}

export const GetOldestBatch = () => {
    const [oldestYear, setOldestYear] = useState()

    useEffect (() => {
        const fetchData = async () => {
            try {
                const response = await getOldestBatch();
                if (response.data) {
                    setOldestYear(response.data[0].oldest_batch);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    removeSession()
                    refreshPage()
                }
            }
        }

        fetchData();
    }, [])

    return oldestYear;
}


