export const toastStyle = () => {
    return {
      fontFamily: "Montserrat-Regular",
      borderRadius: "10px",
      background: "#333",
      color: "#fff",
    };
  };
  

export const dropdownStyle = {
  control: styles => ({ ...styles, 
    backgroundColor: 'maroon', 
    color: 'white', 
    whiteSpace: "nowrap"}),
  input: styles => ({...styles, 
    display: "inline-block"}),
  menu: styles => ({...styles, 
    background: 'maroon', 
    color: 'white'}),
  indicatorContainer: styles => ({...styles, 
    color: 'yellow'}),
  valueContainer: styles => ({...styles, 
    color: 'maroon', 
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  }),
  multiValueLabel: styles => ({...styles, 
    color: 'maroon',
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  }),
  option: (styles, state) => {
    return {
      ...styles,
      backgroundColor: state.isFocused ? '#007aff' : 'maroon',
      color: '#FFF',
      fontSize: "1vw",
    };
  }

}

  
  
  