/***************************
 * Common Utilities
 *
***************************/

import { logoutUser } from "../../APICalls/authApi";

export const formatDate = (date) => {
  var formatDate = new Date(date);
  var stringDate = formatDate.toDateString().split(" ");

  return stringDate[1] + " " + stringDate[2] + ", " + stringDate[3]
}

export const numberWithCommas = (number) => {
  if (!number) {
    return undefined;
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function refreshPage() {
  window.location.reload();
}

export const formatDateSlash = (date) => {
  //if date is null or empty string return nothin
  if (!date || date === "") {
    return "";
  }

  var stringDate = date.split("-");

  return stringDate[1] + "/" + stringDate[2] + "/" + stringDate[0];
};

/***************************
 * Local Storage Utilities
***************************/

//return user data from local storage
export const getUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  return null;
};

//return user name from local storage
export const getName = () => {
  const userStr = localStorage.getItem("name");
  if (userStr) return JSON.parse(userStr);
  return null;
};

//return user type from local storage
export const getType = () => {
  const userStr = localStorage.getItem("role");
  if (userStr) return JSON.parse(userStr);
  return null;
};

//return user role from local storage
export const getRole = () => {
  const userStr = localStorage.getItem("role_id");
  if (userStr) return JSON.parse(userStr);
  return null;
};

export const getDepartment = () => {
  const userStr = localStorage.getItem("department_id");
  if (userStr) return JSON.parse(userStr);
  // return null;
};

//return has reset from local storage
export const getHasReset = () => {
  const userStr = localStorage.getItem("has-reset");
  if (userStr) return JSON.parse(userStr);
  return null;
};

//return role id from local storage
export const getRoleId = () => {
  return localStorage.getItem("role_id") || null;
};

export const getDataId = () => {
  const userStr = localStorage.getItem("id");
  if (userStr) return JSON.parse(userStr);
  return null;
};
//return token from local storage
export const getToken = () => {
  const userStr = localStorage.getItem("token");
  if (userStr) return JSON.parse(userStr);
  return null;
};

//return token expiry from local storage
export const getTokenExpiry = () => {
  return localStorage.getItem("token_expiry") || null;
};

export const getformatDate = (date) => {
  //if date is null or empty string return nothin
  if (!date || date == "") {
    return "";
  }
}
//return user key from local storage
export const getUserKey = () => {
  return localStorage.getItem("user-key") || null;
};

//return current branch from local storage
export const getBranch = () => {
  return localStorage.getItem("branch") || null;
};

//return api key from local storage
export const getKey = () => {
  return localStorage.getItem("api_key") || null;
}

//return current branch from local storage
export const getBranchType = () => {
  return localStorage.getItem("branch_type").replace(/['"]+/g, "") || null;
};

//remove token from local storage
export const removeUserSession = () => {
    logoutUser();
    localStorage.removeItem('user');
    localStorage.removeItem('name');
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('role_id');
    localStorage.removeItem('role');
    localStorage.removeItem('department_id');
    localStorage.removeItem('department');

    localStorage.removeItem('sign_up');
    localStorage.removeItem('ajs_user_id');
    localStorage.removeItem('ajs_user_traits');
    localStorage.removeItem('student_no');
    localStorage.removeItem('_expiredTime');
    localStorage.removeItem('ajs_anonymous_id');
    refreshPage();
}

//remove token from local storage
export const removeSession = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("type");
  localStorage.removeItem("role");
  localStorage.removeItem("user-key");
  localStorage.removeItem("name");
};

//set the token and user from local storage
export const setUserSession = (token,api_key, user) => {
  localStorage.setItem("token", token);
  localStorage.setItem("api_key", api_key);
  localStorage.setItem("user", JSON.stringify(user));
};

export const getDepartmentDropdown = () => {
  const userRole = getRole()
  const alumniDepartment = localStorage.getItem("department_id")
  
  const CSDepartments = [
    // {value: "", label: "All"}
    { value: "1", label: "Bachelor of Science in Computer Science" },
    { value: "2", label: "Master of Science in Computer Science" },
  ];
  const bioDepartments = [
    { value: "3", label: "Bachelor of Science in Biology" },
    { value: "4", label: "Master of Science in Environmental Studies" },
  ];
  const mathDepartments = [
    { value: "5", label: "Bachelor of Science in Mathematics" },
    { value: "6", label: "Bachelor of Science in Statistics" },
  ];
  const allDepartments = [
    ...CSDepartments,
    ...bioDepartments,
    ...mathDepartments,
  ];

  if (userRole == "1" || userRole == "7" || userRole == "8" || userRole=="10") {
      return allDepartments;
  } else if (
    (userRole == "4" && alumniDepartment == "1") ||
    (userRole == "5" && alumniDepartment == "1")
  ) {
    return CSDepartments;
  } else if (
    (userRole == "4" && alumniDepartment == "2") ||
    (userRole == "5" && alumniDepartment == "2")
  ) {
    return bioDepartments;
  } else if (
    (userRole == "4" && alumniDepartment == "3") ||
    (userRole == "5" && alumniDepartment == "3")
  ) {
    return mathDepartments;
  }
}

export const getMonths = () => {
  const Months = [
    {value: '1', label: 'January'},
    {value: '2', label: 'February'},
    {value: '3', label: 'March'},
    {value: '4', label: 'April'},
    {value: '5', label: 'May'},
    {value: '6', label: 'June'},
    {value: '7', label: 'July'},
    {value: '8', label: 'August'},
    {value: '9', label: 'September'},
    {value: '10', label: 'October'},
    {value: '11', label: 'November'},
    {value: '12', label: 'December'},
  ]

  return Months
}
