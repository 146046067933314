import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/Table/Table";
import React, { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  getAlumniPledge,
  getProjectsDisplay,
} from "../../APICalls/ProjectsApi/ProjectsApi";
import {Row, Col} from 'react-bootstrap'

function ProjectPledge({ setIsChecked, isChecked }) {
  const [inactive, setInactive] = useState(false);
  const [selected, setSelected] = useState({});
  const [redirect, setRedirect] = useState("");
  const id = useParams();
  const [pledgeData, setPledgeData] = useState({});
  const [pledgeDetails, setPledgeDetails] = useState([]);
  const [projectDetail, setProjectDetail] = useState({});
  const [filterDate, setFilterDate] = useState({
    date_from: '',
    date_to: ''
  })

  //GET API for Pledges
  async function fetchProjectPledge() {
    const response = await getAlumniPledge(id.id, filterDate);
    if (response.data) {
      setPledgeDetails(response.data.data.pledges);
      setPledgeData(response.data.data);
    }
  }
  
  // GET API for Project
  async function getProject() {
    const response = await getProjectsDisplay(id.id);
    if (response.data) {
      setProjectDetail(response.data);
    }
  }

  React.useEffect(() => {
    getProject();
  }, []);

  React.useEffect(() => {
    fetchProjectPledge();
  }, [filterDate])

  if (redirect === "back") {
    return <Navigate to="/projects" />;
  }

  return (
    <div className="page">
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />

      <div className="center login-container">
        <div className="form-cont me-2 pt-4">
          <div className="container center pt-0">
            
            <Row>
              <Col xs={6} className='modal-cont-header d-flex justify-content-left'>
                <Row className=''><div className="modal-header-label">{projectDetail.name}</div></Row>
              </Col>
              <Col  xs={6} className='modal-cont-header d-flex justify-content-end'>
                <Row className=''>
                    <Col xs={5} className='me-2'>
                      <Row><span className="input-subtitle left">From:</span></Row>
                      <Row><input type="date" name="date_from" className="form-control input-shadow input-green-bg input-subtitle left from_date" id="date_from" aria-describedby="date_from" onChange={(e) => setFilterDate({...filterDate, date_from: e.target.value})}/></Row>
                    </Col>
                    <Col xs={5} className='me-2'>
                      <Row><span className="input-subtitle left">To:</span></Row>
                      <Row><input type="date" name="date_to" className="form-control input-shadow input-green-bg input-subtitle left from_date" id="date_to" aria-describedby="date_to" onChange={(e) => setFilterDate({...filterDate, date_to: e.target.value})}/></Row>
                    </Col>
                  </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className='modal-cont-header d-flex justify-content-left'>
                <Row><h4 className="title">{projectDetail.project_head}</h4></Row>
              </Col>
            </Row>
            
            <hr />
            <div className="modal-content-body mt-3 ">
              <div className="row">
                <p className="col-3 title right">Total Amount: </p>
                <p className="col-3 yellow left">{pledgeData.pledge_sum}</p>
              </div>
              <div className="row">
                <div className="col-3 title right"> Total Pledges: </div>
                <p className="col-3 yellow left">{pledgeData.total_pledge}</p>
              </div>
              <hr />
              <div className="modal-cont-header mt-4 col-8 left">
                <h3 className="title">PLEDGES</h3>
              </div>
              <div className="row mb-4">
                <Table
                  type={"pledge"}
                  tableData={pledgeDetails}
                  clickable={true}
                  headingColumns={["PLEDGEE NAME", "DATE PLEDGE", "AMOUNT"]}
                  setID={setSelected}
                />
              </div>
            </div>
            <div className="row right pt-2 pb-3">
              <div className="col-5 right me-0">
                <button
                  className="green-btn btn-rad  right"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => setRedirect("back")}
                >
                  {" "}
                  BACK{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectPledge;
