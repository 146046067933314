import React from "react";
import { useState, useRef } from "react";
import { ToastContainer } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/Table/Table";
import { getDepartment, getName } from "../../Helpers/Utils/Common";
import { getOldestBatch, getAllAlumniFurtherStudies } from "../../APICalls/ManageUserApi";
import Print from "../../Components/Print/Print";


function FurtherStudies() {
  const [inactive, setInactive] = useState(false);
  const [years, setYears] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [readyExcel, setReadyExcel] = useState(false);
  const [alumniData, setAlumniData] = useState([]);
  const dept_id = getDepartment();
  const [filterData, setFilterData] = useState({
    date_from: "",
    date_to: "",
    degree_program_id: "",
  });

  const [printData, setPrintData] = useState([])
  const tableHeaders = [
    "ALUMNI",
    "ADDRESS",
    "CONTACT NUMBER",
    "EMAIL",
    "STUDIES"
  ]

  const csvHeaders = [
    {label: "ALUMNI", key: "fullname"},
    {label: "ADDRESS", key: "permanent_address"},
    {label: "CONTACT NUMBER", key: "contact_no"},
    {label: "EMAIL", key: "personal_email"},
    {label: "STUDIES", key: "further_studies"}
  ]

  const componentRef = useRef();

  async function getBatches() {
    setYears([]);
    const response = await getOldestBatch();
    var years = [{ name: parseInt(response.data[0].oldest_batch) }];
    for (
      var i = parseInt(response.data[0].oldest_batch);
      i < new Date().getFullYear();
      i++
    ) {
      years.push({ name: i + 1 });
    }
    setYears(years);
  }

  async function getAlumni() {
    setAlumniData([]);
    setPrintData([])
    const response = await getAllAlumniFurtherStudies(dept_id, filterData);
    if (response.data) {
      var alumni = response.data.data
      let print_arr = [];
      alumni.map((data) => {
        let temp = []
        data.fullname = `${data.first_name} ${data.last_name}`
        temp.push(data.fullname, data.permanent_address, data.contact_no, data.personal_email, data.further_studies)
        print_arr.push(temp)
      })
      setIsReady(true);
      setPrintData(print_arr)
      setAlumniData(alumni);
      setReadyExcel(true)
    }
  }

  React.useEffect(() => {
    getBatches();
  }, []);

  React.useEffect(() => {
    getAlumni();
  }, [filterData]);

  return (
    <div className="page">
      <ToastContainer />
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
      />
      <div className="form-cont pt-4">
        <div className="container pt-0">
            <Row>
              <Col xs={5} className="">
                <h1 className="project-title left">FURTHER STUDIES</h1>
              </Col>
              <Col className="left pt-3 me-2">
                <Row className='d-flex justify-content-end'>
                  <Col xs={2} className="me-1">
                    <Row>
                      <p className="form-label left">FROM:</p>
                    </Row>
                    <Row>
                      <select
                        type="text"
                        name="from-date"
                        className="right maroon-btn"
                        onChange={(e) =>
                          setFilterData({
                            ...filterData,
                            date_from: e.target.value,
                          })
                        }
                      >
                        {years.map((data) => (
                          <option value={data.name}>{data.name}</option>
                        ))}
                      </select>
                    </Row>
                  </Col>
                  <Col xs={2} className="me-1">
                    <Row>
                      <p className="form-label left">TO:</p>
                    </Row>
                    <Row>
                      <select
                        type="text"
                        name="from-date"
                        className="right maroon-btn"
                        onChange={(e) =>
                          setFilterData({
                            ...filterData,
                            date_to: e.target.value,
                          })
                        }
                      >
                        {years.map((data) => (
                          <option value={data.name}>{data.name}</option>
                        ))}
                      </select>
                    </Row>
                  </Col>
                  <Col xs={2} className="me-1 pt-4 p-0">
                    {/* <Row className="white-text">label</Row>
                    <Row> */}
                      <ReactToPrint
                        trigger={() => (
                          <button className="width-fill orange-btn">Print</button>
                        )}
                        content={() => componentRef.current}
                        documentTitle={'Further Studies'}
                      />
                    {/* </Row> */}
                  </Col>
                  <Col xs={3} className="me-1 pt-4 p-0">
                    <CSVLink
                        data={alumniData}
                        headers={csvHeaders}
                        filename={"Further Studies"}
                      >
                        <button className="width-fill orange-btn">
                          DOWNLOAD CSV
                        </button>
                      </CSVLink>
                  </Col>
                </Row>
              </Col>
            </Row>
            
            <Row className="row-form mb-4 center studies-wrapper">
              <Table
                type={"further_studies"}
                tableData={alumniData}
                headingColumns={tableHeaders}
                useLoader={true}
                isReady={isReady}
              />
            </Row>


          <div className="onlyPrint">
              <div className="pt-2" ref={componentRef}>
                <Print
                  title={"FURTHER STUDIES"}
                  headingColumns={tableHeaders}
                  tableData={printData}
                />
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FurtherStudies;
