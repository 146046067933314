import { getToken, getUser, getDataId, removeSession, refreshPage } from "../Helpers/Utils/Common";
import { postAPICall, postAPICallCustom } from "./axiosMethodCalls";

/***************************
 * USER
 ***************************/

const token = getToken();
const user = getUser();
const id = getDataId();

//POST
export const createManageUser= async (data) => {
    try {
        const response = await postAPICall(process.env.REACT_APP_LINK + "users/add", {
            requester: user,
            token: token,  
            role_id: data.role_id,
            department_id: data.department_id,
            email: data.email,
            last_name: data.last_name,
            first_name: data.first_name,
        });
        return ({data:response});   
    } catch (error) {
        if (error.response.status === 401) {
          removeSession();
          refreshPage();
        }
        return ({error: error.response});
    }
  }

export const getManageUser = async (id) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "users/get", {
          requester: user,
          token: token,
          user_id: id,
      });
      return ({data:response.data.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}


export const updateManageUser = async (id, data) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "users/update", {
          requester: user,
          token: token,
          user_id: id,
          role_id: data.role_id,
          department_id: data.department_id,
          email: data.email,
          last_name: data.last_name,
          first_name: data.first_name,     
    });
      return ({data:response});   
    } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const deleteManageUser = async (id) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "users/delete", {
          requester: user,
          token: token,
          user_id: id,
      });
      return ({data:response});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const deactivateUser = async (id, status) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "users/update_status", {
          requester: user,
          token: token,
          user_id: id,
          status: status,
      });
      return ({data:response});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

//FOR DASHBOARDS

export const getOverallAlumni = async (id, data) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumnae/get_overall_alumni_statistics", {
          requester: user,
          token: token,
          department_id: id,
          degree_program_id: data,
      });
      return ({data:response.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getAdminUser = async (id) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "users/get", {
          requester: user,
          token: token,
          user_id: user,
          department_id: id,
          // department: nameId,
      });
      return ({data:response.data.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getOldestBatch = async () => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumnae/get_oldest_batch", {
          requester: user,
          token: token,
          user_id: user,
          // department_id: id,
      });
      return ({data:response.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getAlumniNumberGrad = async (id, filterData) => {
  try {
    const dateFrom = filterData.date_from ? filterData.date_from : "";
    const dateTo = filterData.date_to ? filterData.date_to : "";
    const degree_program_id = filterData.degree_program_id
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumnae/get_total_graduates", {
          requester: user,
          token: token,
          year_from: dateFrom,
          year_to: dateTo,
          department_id: id,
          degree_program_id: degree_program_id,
      });
      return ({data:response.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getAlumniNumber = async (id) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumnae/get", {
          requester: user,
          token: token,
          user_id: user,
          department_id: id,
          degree_program_id: id,
      });
      return ({data:response.data.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getAlumniTypeInstitution = async (id, data) => {
  try { 
      const dept_id = id ? id : ''
      const yearFrom = data.year_from ? data.year_from : ""
      const yearTo = data.year_to ? data.year_to : ""
      const degree_program_id = data.degree_program_id;
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumnae/get_alumni_employment_status_by_institution", {
          requester: user,
          token: token,
          year_from: yearFrom,
          year_to: yearTo,
          degree_program_id: degree_program_id,
          department_id: dept_id,
      });
      return ({data:response.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getAlumniTypeSector = async (id, data) => {
  try {
      const yearFrom = data.year_from ? data.year_from : ""
      const yearTo = data.year_to ? data.year_to : ""
      const degree_program_id = data.degree_program_id ? data.degree_program_id: ""
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumnae/get_alumni_employment_status_by_sector", {
          requester: user,
          token: token,
          year_from: yearFrom,
          year_to: yearTo,
          department_id: id,
          degree_program_id: degree_program_id
      });
      return ({data:response.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getNewLogins = async () => {
    try {
        const response = await postAPICall(process.env.REACT_APP_LINK + "reports/get_login_list", {
            requester: user,
            token: token,
        });
        return ({data:response.data.data});   
    } catch (error) {
        if (error.response.status === 401) {
          removeSession();
          refreshPage();
        }
        return ({error: error.response});
    }
  }

export const getNewRegistrants = async () => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumnae/get_newly_created_alumni", {
          requester: user,
          token: token,
      });
      return ({data:response.data.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getEmployabilityRate = async (filterData) => {
  try {
    const months = filterData.months
    const dateFrom = filterData.date_from ? filterData.date_from : ""
    const dateTo = filterData.date_to ? filterData.date_to : ""
    const response = await postAPICall(process.env.REACT_APP_LINK + "reports/get_employability_rate", {
      requester: user,
      token: token,
      months: months,
      year_from: dateFrom,
      year_to: dateTo,
    })
    return ({data:response.data});
  } catch (error) {
    if (error.response.status === 401) {
      removeSession();
      refreshPage();
    }
    return ({error: error.message});
  }
}

export const getAllAlumniFurtherStudies = async (id, data) => {
  try {
    const dateFrom = data.date_from ? data.date.from : "";
    const dateTo = data.date_to ? data.date.from : "";
    const response = await postAPICall(process.env.REACT_APP_LINK + "alumnae/get_all_alumni_further_studies", {
      requester: user,
      token: token,
      date_from: dateFrom,
      date_to: dateTo,
      department_id: id,
      degree_program_id: data.degree_program_id
    })
    return ({data:response.data})
  } catch (error) {
    if (error.response.status === 401) {
      removeSession();
      refreshPage();
    }
  }
}

export const getAllAlumniEmployabilityStatus = async (id, data) => {
  try {
    const dateFrom = data.date_from ? data.date.from : "";
    const dateTo = data.date_to ? data.date.from : "";
    const response = await postAPICall(process.env.REACT_APP_LINK + "alumnae/get_alumni_employability_status", {
      requester: user,
      token: token,
      date_from: dateFrom,
      date_to: dateTo,
      department_id: id,
      institution: data.institution_type,
      sector: data.sector_type,
      // degree_program_id: data.degree_program_id
    })
    return ({data:response.data})
  } catch (error) {
    if (error.response.status === 401) {
      removeSession();
      refreshPage();
    }
  }
}

export const getAllDegreePrograms = async (id) => {
  try {
    const response = await postAPICall (process.env.REACT_APP_LINK + "degree_programs/get", {
      requester: user,
      token: token,
      department_id: id
    })
    return ({data: response.data})
  } catch (error) {
    if (error.response.status === 401) {
      removeSession()
      refreshPage()
    }
  }
}

