import React from "react";
import { useState, useRef } from "react";
import { ToastContainer } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";

import { getEmployabilityRate } from "../../APICalls/ManageUserApi";
import { getOldestBatch } from "../../APICalls/ManageUserApi";
import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/Table/Table";
import Print from "../../Components/Print/Print";

import "./EmployabilityRate.css";

function EmployabilityRate() {
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [alumniData, setAlumniData] = useState([]);
  const num_of_months = useParams();
  const [filterData, setFilterData] = useState({
    months: num_of_months.months,
    date_from: 2000,
    date_to: 2022,
  });
  const [years, setYears] = useState([]);
  const [printData, setPrintData] = useState([])
  const componentRef = useRef();

   const csvHeaders = [
    { label: "ALUMNI", key: "fullname"},
    { label: "DEPARTMENT", key: "department_name" },
    { label: "PROGRAM", key: "program_name" },
    { label: "INDUSTRY", key: "sector" },
    { label: "TITLE", key: "position" },
    { label: "SALARY_ESTIMATION", key: "monthly_salary" },
    { label: "INTERNATIONAL COMPANY", key: "international_company" },
  ];

  const tableHeaders = [
    "ALUMNI",
    "DEPARTMENT",
    "PROGRAM",
    "INDUSTRY",
    "TITLE",
    "SALARY-ESTIMATION",
    "INTERNATIONAL COMPANY",
  ]

  async function getEmployedAlumni() {
    setAlumniData([]);
    setPrintData([]);
    const response = await getEmployabilityRate(filterData);
    if (response.data) {
      var alumni = response.data[0].alumni;
      let print_arr = []
      alumni.map((data) => {
        let temp = []
        data.fullname = `${data.first_name} ${data.last_name}`
        data.international_company = data.is_international=='1' ? `${data.company_institution}` : ``
        temp.push(data.fullname, data.department_name, data.program_name, data.sector, data.position, data.monthly_salary,data.international_company)
        print_arr.push(temp)
      })
      setPrintData(print_arr)
      setAlumniData(alumni);
      setIsReady(true);
    }
  }

  async function getBatch() {
    const response = await getOldestBatch();
    if (response.data) {
      var years_arr = [{ name: parseInt(response.data[0].oldest_batch) }];
      for (
        var i = parseInt(response.data[0].oldest_batch);
        i < new Date().getFullYear();
        ++i
      ) {
        years_arr.push({ name: i + 1 });
      }
      setYears(years_arr);
    }
  }
 
  React.useEffect(() => {
    getEmployedAlumni();
  }, [filterData]);

  React.useEffect(() => {
    getBatch();
  }, []);

  return (
    <div className="page">
      <ToastContainer />
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
      />
      <div className="form-cont pt-4">
        <div className="container pt-0">
            <Row>
              <Col className="col-6">
                <h1 className="project-title left">EMPLOYABILITY RATE</h1>
              </Col>
              <Col className="left pt-1">
                <Row>
                  <Col className="noprint col-2 me-4">
                    <Row>
                      <p className="form-label left">FROM:</p>
                    </Row>
                    <Row>
                      <select
                        type="text"
                        name="from-date"
                        className="right maroon-btn"
                        onChange={(e) =>
                          setFilterData({
                            ...filterData,
                            date_from: e.target.value,
                          })
                        }
                      >
                        {years.map((data) => (
                          <option value={data.name}>{data.name}</option>
                        ))}
                      </select>
                    </Row>
                    {/* <p className="from-label right">
                    FROM:{" "}
                    <select
                      type="text"
                      name="from-date"
                      className="right maroon-btn ms-2"
                      onChange={(e) =>
                        setFilterData({
                          ...filterData,
                          date_from: e.target.value,
                        })
                      }
                    >
                      {years.map((data) => (
                        <option value={data.name}>{data.name}</option>
                      ))}
                    </select>
                  </p> */}
                  </Col>
                  <Col className="noprint col-2 me-4">
                    <Row>
                      <p className="form-label left">TO:</p>
                    </Row>
                    <Row>
                      <select
                        type="text"
                        name="from-date"
                        className="right maroon-btn"
                        onChange={(e) =>
                          setFilterData({
                            ...filterData,
                            date_to: e.target.value,
                          })
                        }
                      >
                        {years.map((data) => (
                          <option value={data.name}>{data.name}</option>
                        ))}
                      </select>
                    </Row>
                    {/* <p className="from-label right">
                    TO:{" "}
                    <select
                      type="text"
                      name="from-date"
                      className="right maroon-btn ms-2"
                      onChange={(e) =>
                        setFilterData({
                          ...filterData,
                          date_to: e.target.value,
                        })
                      }
                    >
                      {years.map((data) => (
                        <option value={data.name}>{data.name}</option>
                      ))}
                    </select>
                  </p> */}
                  </Col>
                  <Col className="noprint col-2 me-0">
                    <Row className="white-text">label</Row>
                    <Row>
                      <ReactToPrint
                        trigger={() => (
                          <button className="yellow-btn">Print</button>
                        )}
                        content={() => componentRef.current}
                        documentTitle={
                          "Employability Rate Within " +
                          num_of_months.months +
                          " months"
                        }
                      />
                    </Row>
                  </Col>
                  <Col className="noprint col-4 left">
                    <Row className="white-text">label</Row>
                    <Row>
                      <CSVLink
                        data={alumniData}
                        headers={csvHeaders}
                        filename={"Employability Rate"}
                      >
                        <button className=" width-fill height-fill orange-btn">
                          DOWNLOAD CSV
                        </button>
                      </CSVLink>
                    </Row>
                  </Col>
                  
                </Row>
              </Col>
            </Row>
      
            <div className="row row-form mb-4 mt-3 employability-wrapper">
              <Table
                type={"employability_rate"}
                tableData={alumniData}
                headingColumns={tableHeaders}
                useLoader={true}
                isReady={isReady}
              />
            </div>
            <div className="onlyPrint">
                <div className="pt-2" ref={componentRef}>
                  <Print
                    title={"EMPLOYABILITY RATE WITHIN 6 MONTHS"}
                    headingColumns={tableHeaders}
                    tableData={printData}
                  />
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default EmployabilityRate;
