import Navbar from "../../Components/Navbar/Navbar";
import React, { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Profile from "../../Assets/Images/Dashboard/user_profile.png";
import { Line, Doughnut } from "react-chartjs-2";
import ReactToPrint from "react-to-print";
import Select from 'react-select'
import { DownloadTableExcel } from "react-export-table-to-excel";
import { Row, Col } from "react-bootstrap";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  getAdminUser,
  getOverallAlumni,
  getAlumniNumber,
  getAlumniTypeInstitution,
  getAlumniTypeSector,
  getAlumniNumberGrad,
} from "../../APICalls/ManageUserApi";
import Logo3 from "../../Assets/Images/Login/logo_3.png";
import upCebuLogo from "../../Assets/Images/Login/up-cebu-logo.png";
import csLogo from "../../Assets/Images/Login/cs-logo.png";
import {dropdownStyle} from '../../Helpers/Constants/StyleConstants'
import { getDepartment, getType, getUser, getName} from "../../Helpers/Utils/Common";
import { GetDegreePrograms } from "../../APICalls/DegreeProgram.js/DegreeProgramApi";
import { GetAllBatch, GetOldestBatch } from "../../Helpers/Utils/CommonAPIs";
import NoDataFoundImg from '../../Assets/Images/noDataFound.png'
import "chart.js/auto";
import './DashboardDean.css'


function DashboardDean({ setIsChecked, isChecked }) {
  const [inactive, setInactive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState({});
  const [redirect, setRedirect] = useState("");
  const [adminData, setAdminData] = useState([]);
  const [emptyInstitution, setEmptyInstitution] = useState(true);
  const [emptySector, setEmptySector] = useState(true);
  const [institutionPercentages, setInstitutionPercentages] = useState([]);
  const [institutionLabels, setInstitutionLabels] = useState([]);
  const [institutionCounts, setInstitutionCounts] = useState([]);
  const [sectorPercentages, setSectorPercentages] = useState([]);
  const [sectorLabels, setSectorLabels] = useState([]);
  const [sectorCounts, setSectorCounts] = useState([]);

  const [overAll, setOverAll] = useState([]);
  const [empRateSixMonths, setEmpRateSixMonths] = useState([]);
  const [empRateOneYear, setEmpRateOneYear] = useState([]);
  const [empRateToDate, setEmpRateToDate] = useState([]);
  const [furtherStudies, setFurtherStudies] = useState([]);

  const navigateto = useNavigate();
  const userName = getName();
  const role = getType();
  const currentYear = new Date().getFullYear();
  const today = new Date()
  const dateToday = today.toLocaleString('default', { month: 'long' }) + ' ' + today.getDate() + ', '+ today.getFullYear()
  const departmentDropdown = GetDegreePrograms()
  const oldest_batch = GetOldestBatch();
  const years = GetAllBatch();

  const [filterData, setFilterData] = useState({
    date_from: '',
    date_to: '',
    degree_program_id: [],
  });

  const [statusFilterData, setStatusFilterData] = useState({
    year_from: "",
    year_to: "",
    degree_program_id: []
  })

  const [overAllSelectedDegrees, setOverAllSelectedDegrees] = useState([])
  const [csvRate, setCsvRate] = useState([]);
  const [csvInstitute, setCsvInstitute] = useState([]);
  const [csvSector, setCsvSector] = useState([]);
  const componentRef = useRef();
  const department_id = getDepartment();
  const [lineChartYear, setLineChartYear] = useState([]);
  const [lineChartPercentage, setLineChartPercentage] = useState([]);


  // Institution data for doughnut
  const getDataInstitution = {
    labels: institutionLabels,
    datasets: [
      {
        data: institutionCounts,
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  const instituteOption = {
    plugins: {
        tooltip: {
          callbacks: {
            label: (context) => {
              return `${context.label}: ${context.parsed} (${institutionPercentages[context.dataIndex]}%)`
            }
          }
        }
    },
  };

  // Sector Data for Doughnut
  const getDataSector = {
    labels: sectorLabels,
    datasets: [
      {
        data: sectorCounts,
        dataLabels: {
          enabled: true
        },
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  const sectorOption = {
    plugins: {
        tooltip: {
          callbacks: {
            label: (context) => {
              return `${context.label}: ${context.parsed} (${sectorPercentages[context.dataIndex]}%)`
            }
          }
        }
    },
  };

  // Fetch Line Chart Data
  async function getLineChartData() {
    const response = await getAlumniNumberGrad(department_id, filterData);
    if (response.data) {
      var result = response.data;
      var sorted_result = result.sort((a, b) =>
        a.year_graduated > b.year_graduated ? 1 : -1
      );
      const yearGraduatesMap = {};
      sorted_result.forEach((data) => {
        const year = data.year_graduated;
        const graduates = parseFloat(data.graduates);

        if (yearGraduatesMap[year]) {
          yearGraduatesMap[year] += graduates; // If year exists, add the graduates to the existing sum
        } else {
          yearGraduatesMap[year] = graduates; // If year does not exist, set the sum as the graduates value
        }
      });
      const year_graduated = Object.keys(yearGraduatesMap).map((year) => parseInt(year));
      const percentage_of_graduates = Object.values(yearGraduatesMap);
      setLineChartYear(year_graduated);
      setLineChartPercentage(percentage_of_graduates);
    } else {
      setLineChartYear([]);
      setLineChartPercentage([]);
    }
  }

  const LineChartData = {
    labels: lineChartYear,
    datasets: [
      {
        label: "Number of Graduates",
        data: lineChartPercentage,
        // backgroundColor: ["#66CDAA"],
        backgroundColor: ["#f4a460"],
        hoverBackgroundColor: ["#36A2EB"],
      },
    ],
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  );

  // Get admin details
  async function AdminProfile() {
    const response = await getAdminUser(getDepartment());
    if (response.data) {
      setAdminData(response.data[0]);
    }
  }

  // Fetch Sector data for the doughnut chart
  async function chartSector() {
    const response = await getAlumniTypeSector(department_id, statusFilterData);
    var csvSectorStatus = [];
    if (response.data) {
      setEmptySector(false);
      var percentages = [], labels = [], counts = [];
      response.data.data.map((val) => {
        percentages.push(parseFloat(val.percentage));
        labels.push(transformString(val.sector));
        counts.push(parseInt(val.sector_employed));
        csvSectorStatus.push({
          sector: `${val.sector}`,
          sector_count: `${val.sector_employed}`,
          sector_percentage: `${val.percentage}`,
        });
      });
      setSectorCounts(counts);
      setSectorPercentages(percentages);
      setSectorLabels(labels);
      setCsvSector(csvSectorStatus);
    } else {
      setEmptySector(true);
    }
  }

  // Fetch type of institution data for the doughnut chart
  async function chartInstitution() {
    var csvInstitutionStatus = [];
    const response = await getAlumniTypeInstitution(department_id, statusFilterData);
    if (response.data) {
      setEmptyInstitution(false);
      var percentages = [],
      labels = [], counts = [];
      response.data.data.map((val) => {
        percentages.push(parseFloat(val.percentage));
        labels.push(transformString(val.institution_type));
        counts.push(parseInt(val.institution_employed))
        csvInstitutionStatus.push({
          type: `${val.institution_type}`,
          institute_count: `${val.institution_employed}`,
          institute_percentage: `${val.percentage}`,
        });
      });
      setInstitutionCounts(counts);
      setInstitutionPercentages(percentages);
      setInstitutionLabels(labels);
      setCsvInstitute(csvInstitutionStatus);
    } else {
      setEmptyInstitution(true);
    }
  }

  // Fetch data for employability rate, status, & further studies percentages
  async function OverallEmployment() {
    const response = await getOverallAlumni(department_id, overAllSelectedDegrees);
    var csvEmployabilityRate = [];
    if (response.data) {
      var num_of_grads = response.data[4];
      setOverAll(num_of_grads);

      var six_months = response.data[0];
      setEmpRateSixMonths(six_months);
      csvEmployabilityRate.push({
        duration: "6 months",
        count: `${six_months.employed_alumni_within_half_year}`,
        percentage: `${six_months.percentage}`,
      });

      var one_year = response.data[1];
      setEmpRateOneYear(one_year);
      csvEmployabilityRate.push({
        duration: "1 year",
        count: `${one_year.employed_alumni_within_one_year}`,
        percentage: `${one_year.percentage}`,
      });

      var up_to_date = response.data[2];
      setEmpRateToDate(up_to_date);
      csvEmployabilityRate.push({
        duration: "Up-to-date",
        count: `${up_to_date.employed_alumni_currently_employed}`,
        percentage: `${up_to_date.percentage}`,
      });

      var further_studies = response.data[3];
      setFurtherStudies(further_studies);
      csvEmployabilityRate.push({
        duration: "Further Studies",
        count: `${further_studies.total_alumni_with_further_studies}`,
        percentage: `${further_studies.percentage}`,
      });
      setCsvRate(csvEmployabilityRate);
    }
  }

  const handleMultipleSelect = (event, type) => {
    var degrees = []
    event.map((val) => {
      degrees.push(val.value)
    })
    if (type === "overall") {
      setOverAllSelectedDegrees(degrees)
    } else if (type === "status") {
      setStatusFilterData({...statusFilterData,
        degree_program_id: degrees })
    } else if (type === "graduates") {
      setFilterData({...filterData,
        degree_program_id: degrees })
      }
  }

  function transformString(inputString) {
    if (inputString && inputString.includes('_')) {
      const words = inputString.split('_');
      const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
      return capitalizedWords.join(' ');
    } else if (inputString) {
      return inputString.charAt(0).toUpperCase() + inputString.slice(1);
    } else {
      return "";
    }
  }

  React.useEffect(() => {
    AdminProfile();
  }, []);

  React.useEffect(() => {
    chartInstitution();
    chartSector();
  }, [statusFilterData])

  React.useEffect(() => {
    OverallEmployment();
  }, [overAllSelectedDegrees])

  React.useEffect(() => {
    getLineChartData();
  }, [filterData]);

  React.useEffect(() => {
    if (action !== "" && action.id != null && action.action === "delete-user") {
      setShowModal(true);
    }
  }, [action]);

  React.useEffect(() => {
    if (redirect === "employability_rate_12m") {
      navigateto("/employability_rate/" + 12); // Change this to 12 once data is available
    } else if (redirect === "employability_rate_6m") {
      navigateto("/employability_rate/" + 6); // Change this to 6 once data is available
    } else if (redirect === "employability_rate_up_to_date") {
      navigateto("/employability_rate/" + 999);
    } else if (redirect == "further_studies") {
      navigateto("/further_studies");
    } else if (redirect === "employability_status") {
      navigateto("/employability_status");
    }
  }, [redirect]);

  return (
    <div className="page">
      <Row>
        <Col className="1">
          <Navbar
            onCollapse={(e) => {
              setInactive(e);
            }}
            active={"DASHBOARD"}
            setIsChecked={setIsChecked}
            isChecked={isChecked}
          />
        </Col>

        <div className="login-container">
          <div className={`container ${inactive ? "inactive" : "active"}`}>
            <div className="form-cont mt-4">
              <div className="itc-db-container pt-0">
                <Row className="section-container header-cont ms-3 mt-3">
                  <Col className="col-2 center profile-cont">
                    <img className="user-profile " src={Profile}></img>
                  </Col>
                  <Col className="col-6 pt-2 left">
                    <Row>
                      <h1 className="admin-name">
                        {/* {adminData.first_name + " " + adminData.last_name} */}
                        {userName?userName:''}
                      </h1>
                    </Row>
                    <Row>
                      <h2 className="role-label">{role?role:''}</h2>
                    </Row>
                    <Row>
                      <p className="campus-name">UP CEBU</p>
                    </Row>
                  </Col>
                </Row>

                <Row className="pt-3 me-3">
                  <Col className="col-7 right"></Col>
                  <Col className="col-3 right mt-0">
                    <ReactToPrint
                      trigger={() => (
                        <button className="right yellow-btn me-0">PRINT</button>
                      )}
                      content={() => componentRef.current}
                      documentTitle={"Report Summary"}
                    />
                  </Col>
                  <Col className="col-2 mt-0">
                    <DownloadTableExcel
                      filename="Report Summary"
                      sheet="users"
                      currentTableRef={componentRef.current}
                    >
                      <button className=" width-fill height-fill orange-btn">
                        DOWNLOAD CSV
                      </button>
                    </DownloadTableExcel>
                  </Col>
                </Row>

                <Row className="section-container pt-4 ms-3">
                  <Row className="input-shadow cont-color-grey pb-4 ms-0">
                    <Row className="pt-3">
                      <Col className="col-4 left">
                        <div >
                          <Select
                            styles={dropdownStyle}
                            options={departmentDropdown}
                            placeholder="All"
                            onChange={(event) => handleMultipleSelect(event, 'overall')}
                            isSearchable={false}
                            isMulti
                            closeMenuOnScroll={false}
                            closeMenuOnSelect={false}
                          />
                        </div>
                      </Col>

                      <Col className="col-7 ms-5">
                        <p className="input-subtitle ms-5">
                          TOTAL GRADUATES: 
                          <span className="input-subtitle ms-2 yellow">
                            {overAll.total_graduates}
                          </span>
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="col-3 mt-0 pointer-cursor">
                        <div
                          className="green-box ms-1 mt-2 input-shadow justify-content-center"
                          onClick={() => setRedirect("employability_rate_6m")}
                        >
                          <Row className="d-flex">
                            <h1 className="admin-name1" name="percentage">
                              {empRateSixMonths.percentage?empRateSixMonths.percentage:'0'}%
                            </h1>
                          </Row>
                          <Row className="justify-content-center">
                            <h3 className="subtitlex" name="employed_alumni">
                              {empRateSixMonths.employed_alumni}
                            </h3>
                          </Row>
                          <Row>
                            <label className="subtitlex1">EMPLOYABILITY</label>
                          </Row>
                          <Row>
                            <p className="subtitlex1">RATE (6 MONTHS)</p>
                          </Row>
                        </div>
                      </Col>
                      <Col className="col-3 mt-0 pointer-cursor">
                        <div
                          className="green-box ms-1 mt-2 input-shadow justify-content-center"
                          onClick={() => setRedirect("employability_rate_12m")}
                        >
                          <Row className="d-flex">
                            <h1 className="admin-name1" name="percentage">
                              {empRateOneYear.percentage?empRateOneYear.percentage:'0'}%
                            </h1>
                          </Row>
                          <Row className="justify-content-center">
                            <h3 className="subtitlex" name="employed_alumni">
                              {empRateOneYear.employed_alumni}
                            </h3>
                          </Row>
                          <Row>
                            <label className="subtitlex1">EMPLOYABILITY</label>
                          </Row>
                          <Row>
                            <p className="subtitlex1">RATE (1 YEAR)</p>
                          </Row>
                        </div>
                      </Col>
                      <Col className="col-3 mt-0 pointer-cursor">
                        <div
                          className="green-box ms-1 mt-2 input-shadow justify-content-center"
                          onClick={() =>
                            setRedirect("employability_rate_up_to_date")
                          }
                        >
                          <Row className="d-flex">
                            <h1 className="admin-name1" name="percentage">
                              {empRateToDate.percentage?empRateToDate.percentage:'0'}%
                            </h1>
                          </Row>
                          <Row className="justify-content-center">
                            <h3 className="subtitlex" name="employed_alumni">
                              {empRateToDate.employed_alumni}
                            </h3>
                          </Row>
                          <Row>
                            <label className="subtitlex1">EMPLOYABILITY</label>
                          </Row>
                          <Row>
                            <p className="subtitlex1">RATE (UP-TO-DATE)</p>
                          </Row>
                        </div>
                      </Col>
                      <Col className="col-3 mt-0 pointer-cursor">
                        <div
                          className="green-box ms-1 mt-2 input-shadow justify-content-center"
                          onClick={() => setRedirect("further_studies")}
                        >
                          <Row className="d-flex">
                            <h1 className="admin-name1" name="percentage">
                              {furtherStudies.percentage?furtherStudies.percentage:'0'}%
                            </h1>
                          </Row>
                          <Row className="justify-content-center">
                            <h3 className="subtitlex" name="employed_alumni">
                              {furtherStudies.total_alumni}
                            </h3>
                          </Row>
                          <Row>
                            <label className="subtitlex1">FURTHER</label>
                          </Row>
                          <Row>
                            <p className="subtitlex1">STUDIES</p>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Row>
                </Row>

                <Row className="section-container pt-4 ms-3">
                  <Row className="input-shadow cont-color-grey pb-4 ms-0">
                    <Row className="pt-2">
                      <Row>
                       <Col className="section-header ms-1 pt-2 mb-3 left"> EMPLOYMENT STATUS</Col>
                      </Row>
                      <Row>
                        <Col xs={4} className="left me-5">
                          <Select
                            styles={dropdownStyle}
                            options={departmentDropdown}
                            placeholder="All"
                            onChange={(event) => handleMultipleSelect(event, 'status')}
                            isSearchable={false}
                            isMulti
                            closeMenuOnScroll={false}
                            closeMenuOnSelect={false}
                          />
                        </Col>
                       
                        <Col>
                          <Row className='d-flex justify-content-end'>
                            <Col xs={3} className='me-2'>
                              <span>FROM:</span>
                                <span><select
                                  type="text"
                                  name="from-date"
                                  className="left maroon-btn ms-2"
                                  onChange={(e) =>
                                    setStatusFilterData({
                                      ...statusFilterData,
                                      year_from: e.target.value,
                                    })
                                  }
                                > 
                                  <option value={''} hidden>{currentYear}</option>
                                  {years.map((data) => (
                                    <option value={data.name}>{data.name}</option>
                                  ))}
                                </select></span>
                            </Col>
                            <Col xs={3} className=''>
                              <span>TO:</span>
                              <span><select
                                  type="text"
                                  name="from-date"
                                  className="left maroon-btn ms-2"
                                  onChange={(e) =>
                                    setStatusFilterData({
                                      ...statusFilterData,
                                      year_to: e.target.value,
                                    })
                                  }
                                >
                                  <option value={''} hidden>{currentYear}</option>
                                  {years.map((data) => (
                                    <option value={data.name}>{data.name}</option>
                                  ))}
                                </select></span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Row>
                    <Row
                      className="mt-3 ms-3 pointer-cursor"
                      onClick={() => setRedirect("employability_status")}
                    >
                      <Col className="col-6 center">
                        <Row>
                          <p className=" admin-name2">TYPE OF INSTITUTION</p>
                        </Row>
                        {!emptyInstitution ? (
                          <Row className="center">
                            <Col className="col-8 ms-5 center">
                              <Doughnut data={getDataInstitution} options={instituteOption}/>
                            </Col>
                          </Row>
                        ) : (
                          <img src={NoDataFoundImg} className='no-data-img'/>
                        )}
                      </Col>
                      <Col className="col-6 center">
                        <Row>
                          <p className="admin-name2">TYPE OF SECTOR</p>
                        </Row>
                        {!emptySector ? (
                          <Row className="center">
                            <Col className="col-8 ms-5 center">
                              <Doughnut data={getDataSector} options={sectorOption}/>
                            </Col>
                          </Row>
                        ) : (
                          <img src={NoDataFoundImg} className='no-data-img'/>
                        )}
                      </Col>
                    </Row>
                  </Row>

                  <Row className="section-container pt-4 ms-2">
                    <Row className="input-shadow cont-color-grey pb-4 pt-3">
                      <Col xs={4} className="left ">
                          <Select
                            styles={dropdownStyle}
                            options={departmentDropdown}
                            placeholder="All"
                            onChange={(event) => handleMultipleSelect(event, 'graduates')}
                            isSearchable={false}
                            isMulti
                            closeMenuOnScroll={false}
                            closeMenuOnSelect={false}
                          />
                      </Col>

                      <Col>
                          <Row className='d-flex justify-content-end'>
                            <Col xs={3} className='me-2'>
                              <span>FROM:</span>
                                <span><select
                                  type="text"
                                  name="year_from"
                                  className="ms-2 maroon-btn"
                                  onChange={(e) =>
                                    setFilterData({
                                      ...filterData,
                                      date_from: e.target.value,
                                    })
                                  }
                                >
                                  <option value={''} hidden>{currentYear}</option>
                                  {years.map((data) => (
                                    <option value={data.name}>{data.name}</option>
                                  ))}
                                </select></span>
                            </Col>
                            <Col xs={3} className=''>
                              <span>TO:</span>
                              <span><select
                                type="text"
                                name="year_to"
                                className="maroon-btn ms-2"
                                onChange={(e) =>
                                  setFilterData({
                                    ...filterData,
                                    date_to: e.target.value,
                                  })
                                }
                              >
                                <option value={''} hidden>{currentYear}</option>
                                {years.map((data) => (
                                  <option value={data.name}>{data.name}</option>
                                ))}
                              </select></span>  
                            </Col>
                          </Row>
                      </Col>

                      <Row className="mb-5 d-flex justify-content-center pt-3">
                        <h3 className="admin-name2 ">NUMBER OF GRADUATES</h3>
                          <Row className="mb-1 center">
                            <Line data={LineChartData} />
                          </Row>
                      </Row>
                    </Row>
                  </Row>
                </Row>

                {/* For print only */}
                <div className="onlyPrint">
                  <Row className="pt-2 report-table" ref={componentRef}>
                    <div className="report-logo-container center mb-4 ms-5 ">
                      <img src={Logo3} className="report-logo" />
                      <img src={upCebuLogo} className="report-logo" />
                      <img src={csLogo} className="report-logo" />
                    </div>
                    <table className="table table-bordered left ms-5 ">
                      <thead></thead>
                      <tbody>
                        <tr>
                          <th colSpan={3}>Employability Rate</th>
                        </tr>
                        <tr>
                          <th>Duration</th>
                          <th>No. of Alumni</th>
                          <th>Percentage</th>
                        </tr>
                        {csvRate.map((data) => {
                          return (
                            <tr>
                              <td>{data.duration} </td>
                              <td>{data.count}</td>
                              <td>{data.percentage} </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <br></br>
                        </tr>
                        <tr>
                          <th colSpan={3}>Employment Status</th>
                        </tr>
                        <tr>
                          <th>Institute Type</th>
                          <th>No. of Alumni</th>
                          <th>Percentage</th>
                        </tr>
                        {csvInstitute.map((data) => {
                          return (
                            <tr>
                              <td>{data.type} </td>
                              <td>{data.institute_count}</td>
                              <td>{data.institute_percentage}</td>
                            </tr>
                          );
                        })}
                        <tr>
                          <th>Sector</th>
                          <th>No. of Alumni</th>
                          <th>Percentage</th>
                        </tr>
                        {csvSector.map((data) => {
                          return (
                            <tr>
                              <td>{data.sector}</td>
                              <td>{data.sector_count}</td>
                              <td>{data.sector_percentage}</td>
                            </tr>
                          );
                        })}

                        <tr>
                          <br></br>
                        </tr>
                        <tr>
                          <th colSpan={3}>Number of Graduates</th>
                        </tr>
                        <tr>
                          <td colSpan={3}>{overAll.total_graduates}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="report-logo-container left mb-4 ms-5 mt-5">
                      <p>Generate on: <span>{dateToday.toLocaleString()}</span></p>
                      <p>Generated by: <span>{userName}</span></p>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
}

export default DashboardDean;
