import { getToken, getUser, getDataId, removeSession, refreshPage } from "../../Helpers/Utils/Common";
import { postAPICall, postAPICallCustom } from "../axiosMethodCalls";


/***************************
 * ALUMNI BATCH ENTRY
 ***************************/

const token = getToken();
const user = getUser();
const id = getDataId();

//POST
export const createBatch= async (data) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumnae/add_by_batch", {
          token: token,
        requester: user,
        alumni_list: data,
    });
    return ({data:response});   
} catch (error) {
    if (error.response.status === 401) {
      removeSession();
      refreshPage();
    }
    return ({error: error.response});
}
}

export const getBatchAlumni = async (id) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_academics/get", {
          requester: user,
          token: token,
          alumni_academic_id: id,
      });
      return ({data:response.data.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const updateBatchAlumni = async (id, data) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_academics/update", {
          requester: user,
          token: token,
          alumni_academic_id: id,
          institution: data.institution,
          degree: data.degree,
          specialization: data.specialization,
          scolarship_grant: data.scolarship_grant,
          is_current: data.is_current,
          date_started: data.date_started,
          
    });
      return ({data:response});   
    } catch (error) {
      return ({error: error.response});
  }
}

export const deleteBatchAlumni = async (id) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_academics/delete", {
          requester: user,
          token: token,
          alumni_academic_id: id,
      });
      return ({data:response});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}
