import React from "react";
import {Row, Col} from 'react-bootstrap'

import Logo3 from '../../Assets/Images/Login/logo_3.png'
import upCebuLogo from '../../Assets/Images/Login/up-cebu-logo.png'
import csLogo from '../../Assets/Images/Login/cs-logo.png'

import { getName } from "../../Helpers/Utils/Common";


function Print (props) {
    const userName = getName()
    const today = new Date()
    const dateToday = today.toLocaleString('default', { month: 'long' }) + ' ' + today.getDate() + ', '+ today.getFullYear()

    return (
        // <div>PRINT COMPONENT</div>
        <Row className="pt-2 report-table">
            <div className="report-logo-container center mb-4 ms-5 ">
                <img src={Logo3} className="report-logo" />
                <img src={upCebuLogo} className="report-logo" />
                <img src={csLogo} className="report-logo" />
            </div>
            <Row className="">
                <h5 className="project-title left">{props.title}</h5>
            </Row>
            <table className="table table-bordered table-responsive left ms-3">
                <thead>
                    <tr>
                        {
                            props.headingColumns.map((data) => {
                                return (
                                        <th scope="col" className="ms-1">{data}</th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {props.tableData.map((items, index) => {
                        return (
                        <tr>
                            {items.map((subItems, sIndex) => {
                            return <td> {subItems} </td>;
                            })}
                        </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="report-logo-container left mb-4 mt-5 ms-3">
                <Row>Generated on: {dateToday.toLocaleString()}</Row>
                <Row>Generated by: {userName}</Row>
            </div>  
        </Row>
    )
}

export default Print;