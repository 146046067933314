import { handleValidationChange } from "../CommonValidation";

export const validateAddSingleAlumni = (data, setIsError) => {

    var isValid = true;

    if (data.last_name === "") {
      handleValidationChange("last_name", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("last_name", false, setIsError);
    }
    
    if (data.first_name === "") {
      handleValidationChange("first_name", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("first_name", false, setIsError);
    }

    if (data.birthdate === "") {
      handleValidationChange("birthdate", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("birthdate", false, setIsError);
    }

    if (data.nationality === "") {
      handleValidationChange("nationality", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("nationality", false, setIsError);
    }

    if (data.college_id === "") {
      handleValidationChange("college_id", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("college_id", false, setIsError);
    }

    if (data.department_id === "") {
      handleValidationChange("department_id", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("department_id", false, setIsError);
    }

    if (data.degree_program_id === "") {
      handleValidationChange("degree_program_id", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("degree_program_id", false, setIsError);
    }

    if (data.student_no === "") {
      handleValidationChange("student_no", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("student_no", false, setIsError);
    }

    if (data.year_graduated === "") {
      handleValidationChange("year_graduated", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("year_graduated", false, setIsError);
    }

    if (data.month_graduated === "") {
      handleValidationChange("month_graduated", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("month_graduated", false, setIsError);
    }

    if (data.academic_year === "") {
      handleValidationChange("academic_year", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("academic_year", false, setIsError);
    }

    if (data.contact_no === "") {
      handleValidationChange("contact_no", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("contact_no", false, setIsError);
    }

    if (data.personal_email === "") {
      handleValidationChange("personal_email", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("personal_email", false, setIsError);
    }

    if (data.permanent_address === "") {
      handleValidationChange("permanent_address", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("permanent_address", false, setIsError);
    }

    if (data.permanent_country === "") {
      handleValidationChange("permanent_country", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("permanent_country", false, setIsError);
    }

    if (data.permanent_province === "") {
      handleValidationChange("permanent_province", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("permanent_province", false, setIsError);
    }

    if (data.permanent_city === "") {
      handleValidationChange("permanent_city", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("permanent_city", false, setIsError);
    }

    if (data.permanent_postal_code === "") {
      handleValidationChange("permanent_postal_code", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("permanent_postal_code", false, setIsError);
    }

    if (data.present_address === "") {
      handleValidationChange("present_address", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("present_address", false, setIsError);
    }

    if (data.present_country === "") {
      handleValidationChange("present_country", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("present_country", false, setIsError);
    }

    if (data.present_province === "") {
      handleValidationChange("present_province", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("present_province", false, setIsError);
    }

    if (data.present_city === "") {
      handleValidationChange("present_city", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("present_city", false, setIsError);
    }

    if (data.present_postal_code === "") {
      handleValidationChange("present_postal_code", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("present_postal_code", false, setIsError);
    }
    
    return isValid
}