import Navbar from "../../Components/Navbar/Navbar";
import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import Select from "react-select";
import { Row, Col, Container } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";

import {getName} from "../../Helpers/Utils/Common";
import { dropdownStyle } from "../../Helpers/Constants/StyleConstants";
import { getExitInterviewForAll, getEssayResponses } from "../../APICalls/ExitInterview/ExitInterviewApi";
import { getOldestBatch } from "../../APICalls/ManageUserApi";
import { GetDegreePrograms } from "../../APICalls/DegreeProgram.js/DegreeProgramApi";

import Logo3 from '../../Assets/Images/Login/logo_3.png'
import upCebuLogo from '../../Assets/Images/Login/up-cebu-logo.png'
import csLogo from '../../Assets/Images/Login/cs-logo.png'
import NoDataFoundImg from '../../Assets/Images/noDataFound.png'


import "./ExitInterview.css";


function ExitInterview({ setIsChecked, isChecked }) {
  const navigateto = useNavigate()
  const [inactive, setInactive] = useState(false);
  const [redirect, setRedirect] = useState("");
  const [responsesTally, setResponsesTally] = useState([{ id: "", question: "" }]);
  const [plansTally, setPlansTally] = useState([{ id: "", question: "" }]);
  const [goodnessLikert, setGoodnessLikert] = useState([{ id: "", question: "" }]);
  const [empty, setEmpty] = useState(false);
  const [years, setYears] = useState([]);
  const departmentDropdown = GetDegreePrograms();
  const [essayData, setEssayData] = useState([]);
  const [otherPlans, setOtherPlans] = useState([]);
  const [filterData, setFilterData] = useState({
    year: "",
    degree_program_id: []
  })

  const currentYear = new Date().getFullYear()
  const today = new Date()
  const dateToday = today.toLocaleString('default', { month: 'long' }) + ' ' + today.getDate() + ', '+ today.getFullYear()
  const componentRef = useRef()
  const navigateTo = useNavigate()
  const userName = getName()

  async function oldestBatch() {
    const response = await getOldestBatch();
    if (response.data) {
      var years_arr = [{ name: parseInt(response.data[0].oldest_batch) }];
      for (
        var i = parseInt(response.data[0].oldest_batch);
        i < new Date().getFullYear();
        ++i
      ) {
        years_arr.push({ name: i + 1 });
      }
      setYears(years_arr);
    }
  }

  async function fetchExitInterviewForAll() {
    setPlansTally([])
    setResponsesTally([]);
    responsesTally.length = 0;
    goodnessLikert.length = 0;
    const response = await getExitInterviewForAll(filterData);
    if (response.data) {
      setEmpty(false)
      setPlansTally(response.data.plans_tally);
      const responses = response.data.responses_tally;
      responses.map((item, index) => {
        if ('fair'in item && 'good' in item && 'poor' in item && 'very_good' in item && 'excellent' in item) {
          setGoodnessLikert(current => [...current, item]);
        } else {
          setResponsesTally(current => [...current, item]);
        }
      })
    } else {
      setEmpty(true)
    }

  }

  const handleFilterDegree = (event) => {
    var degrees = []
    event.map((val) => {
      degrees.push(val.value)
    })
    setFilterData({...filterData, degree_program_id: degrees})
  }

  function handleNavigation () {
    navigateTo('/exit-interview-qualitative');
  }

  // FETCH ESSAY RESPONSES
  async function fetchExitInterviewOthers() {
    essayData.length = 0;
    const response = await getEssayResponses(filterData);
    if (response.data) {
      setEssayData(response.data);
      response.data.map((plan) => {
        if (plan.other_plan) {
          setOtherPlans([...otherPlans, plan.other_plan]);
        }
      })
    } else {
    }
  }


  React.useEffect(() => {
    oldestBatch();
  }, []);

  React.useEffect(() => {
    fetchExitInterviewForAll();
    fetchExitInterviewOthers();
  }, [filterData])


  return (
    <div className="page">
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />

      <div className=" login-container">
        <div className={`container ${inactive ? "inactive" : "active"}`}>
          <div className="form-cont pt-4">
            <div className="container ms-2 pt-0">
              <Row>
                <Col xs={4} className="left">
                  <h3 className="left title">EXIT INTERVIEW</h3>
                </Col>
                <Col className="">
                  <Row className="d-flex justify-content-end">
                    <Col xs={3} className="left">
                      <Select
                        styles={dropdownStyle}
                        options={departmentDropdown}
                        placeholder="All"
                        onChange={handleFilterDegree}
                        isSearchable={false}
                        isMulti
                        closeMenuOnScroll={false}
                        closeMenuOnSelect={false}
                      />
                    </Col>
                    <Col xs={2} className="">
                      <select
                        type="text"
                        name="year"
                        className="left maroon-btn"
                        onChange={(e) => setFilterData({...filterData, year: e.target.value})}
                      >
                        <option value={currentYear}>{currentYear}</option>
                        {years.map((data) => (
                          <option value={data.name}>{data.name}</option>
                        ))}
                      </select>
                    </Col>
                    <Col xs={2} className="noprint left">
                        <ReactToPrint
                          trigger={() => (
                            <button className="width-fill orange-btn">Print</button>
                          )}
                          content={() => componentRef.current}
                          documentTitle={'Qualitative Exit Interview'}
                        />
                      </Col>
                    <Col xs={3} className="ms-0 left">
                      <DownloadTableExcel
                          filename="Exit Interview"
                          sheet="users"
                          currentTableRef={componentRef.current}
                        >
                          <button className="width-fill orange-btn">
                            DOWNLOAD CSV
                          </button>
                        </DownloadTableExcel>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr />
              <Row>
                <h3 className="header-2 pt-4 left">PLANS AFTER GRADUATION:</h3>
              </Row>

              <Row className="center">
                {!empty ? (
                  <Col className="row">
                    {plansTally.map((data, key) => {
                      if (data.plan) {
                        return (
                          <Col className="col-4 left">
                            <label className="btn-gren pad mt-4">
                              {data.plan}{" "}
                              <label className="white"> = {data.tally}</label>
                            </label>
                          </Col>
                        );
                      }
                    })}
                  </Col>
                ) : (
                  <img src={NoDataFoundImg} className='no-data-img smaller-img'/>
                )}
              </Row>

              <Row>
                <p className="row mt-5 ms-1 header-2">
                  LEVEL OF QUALITY IN RELATION TO DEGREE PROGRAM:
                </p>
              </Row>
              <Row>
                <Col className="col-6"></Col>
                <Col className="'col-6">
                  <Row>
                    <Col className="col-4 me-1 center">
                      <label className="maroon">Not Developed</label>
                    </Col>
                    <Col className="col-3 me-0 center">
                      <label className="maroon">Developed</label>
                    </Col>
                    <Col className="col-4 left">
                      <label className="maroon">Highly Developed</label>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {!empty ? (
                <Row>
                  {responsesTally.map((data, key) => {
                    return (
                      <Row>
                        <Col className="col-6 pt-3 left">
                          <label className="text-1">{data.question}</label>
                        </Col>

                        <Col className="col-6 center">
                          <Row className="pt-3">
                            <Col className="col-4 me-1 center">
                              <label className="yellow ms-5 me-5">
                                {data.not_developed}
                              </label>
                            </Col>
                            <Col className="col-3 me-1 center">
                              <label className="yellow ms-5 me-5">
                                {data.developed}
                              </label>
                            </Col>
                            <Col className="col-4 center">
                              <label className="yellow ms-3 me-5">
                                {data.highly_developed}
                              </label>
                            </Col>
                          </Row>
                        </Col>
                        <hr />
                      </Row>
                    );
                  })}
                </Row>
              ) : (
                <img src={NoDataFoundImg} className='no-data-img smaller-img'/>
              )}

              <Row className="mt-5">
                <Col xs={4}></Col>
                <Col xs={8}>
                  <Row>
                    <Col className="me-1 center">
                      <label className="maroon">Poor</label>
                    </Col>
                    <Col className="me-0 center">
                      <label className="maroon">Fair</label>
                    </Col>
                    <Col className="left">
                      <label className="maroon">Good</label>
                    </Col>
                    <Col className="me-1 center">
                      <label className="maroon">Very Good</label>
                    </Col>
                    <Col className="me-0 center">
                      <label className="maroon">Excellent</label>
                    </Col>
                  </Row>
                </Col>
              </Row>
              
              {!empty ? (
                <Row>
                  {goodnessLikert.map((data, key) => {
                    return (
                      <Row>
                        <Col xs={4} className="pt-3 left">
                          <label className="text-1">{data.question}</label>
                        </Col>

                        <Col xs={8} className="center">
                          <Row className="pt-3">
                            <Col className="center">
                              <label className="yellow">
                                {data.poor}
                              </label>
                            </Col>
                            <Col className="center">
                              <label className="yellow">
                                {data.fair}
                              </label>
                            </Col>
                            <Col className="center">
                              <label className="yellow">
                                {data.good}
                              </label>
                            </Col>
                            <Col className="center">
                              <label className="yellow">
                                {data.very_good}
                              </label>
                            </Col>
                            <Col className="center">
                              <label className="yellow">
                                {data.excellent}
                              </label>
                            </Col>
                          </Row>
                        </Col>
                        <hr />
                      </Row>
                    );
                  })}
                </Row>
              ) : (
                <img src={NoDataFoundImg} className='no-data-img smaller-img'/>
              )}

              <Container className="mx-2">
                <Row className="row header-2"><h4 className=" d-flex justify-content-left">ESSAY RESPONSES</h4></Row>
                <Row className="question-header mt-3 p-3">WHAT ARE YOUR BEST EXPERIENCES IN THE UNIVERSITY?</Row>
                <Row>
                  <Container className="response-container p-2">
                    {
                      (essayData && essayData.length) ? 
                        essayData.map((data) => {
                          if (data.experience) {
                            return (
                              <>
                              <Row className="text-start ms-3 me-5">{data.experience}</Row>
                              <hr/>
                              </>
                            )
                          }
                      })
                      : (<img src={NoDataFoundImg} className='no-data-img smaller-img'/>)
                    }
                  </Container>
                </Row>
              </Container>

              <Container className="mx-2 mt-0">
                <Row className="question-header p-3">WHAT CHALLENGES DID YOU ENCOUNTER IN THE UNIVERSITY? HOW DID YOU MANAGE IT?</Row>
                <Row>
                  <Container className="response-container p-2">
                    {
                      (essayData && essayData.length) ? 
                        essayData.map((data) => {
                          if (data.challenge) {
                            return (
                              <>
                              <Row className="text-start ms-3">{data.challenge}</Row>
                              <hr/>
                              </>
                            )
                          }
                      })
                        : (<img src={NoDataFoundImg} className='no-data-img smaller-img'/>)
                    }
                  </Container>
                </Row>
              </Container>

              <Container className="mx-2 mt-0 mb-0">
                <Row className="question-header p-3">WHAT ARE YOUR COMMENTS/SUGGESTIONS FOR IMPROVEMENT FOR UP CEBU?</Row>
                <Row>
                  <Container className="response-container p-2">
                    {
                      (essayData && essayData.length) ? 
                        essayData.map((data) => {
                          if (data.remarks) {
                            return (
                              <>
                              <Row className="text-start ms-3">{data.remarks}</Row>
                              <hr/>
                              </>
                            )
                          }
                      })
                        : (<img src={NoDataFoundImg} className='no-data-img smaller-img'/>)
                    }
                  </Container>
                </Row>
              </Container>

              <Row>
                <Col className="col-12 mt-5 right">
                  <button
                    className="btn-green btn-rad right w-13"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={handleNavigation}
                  > NEXT
                  </button>
                </Col>
              </Row>

              <div className="onlyPrint">
                  <Row className="pt-2 report-table" ref={componentRef}>
                    <div className="report-logo-container center mb-4 ms-5 ">
                      <img src={Logo3} className="report-logo" />
                      <img src={upCebuLogo} className="report-logo" />
                      <img src={csLogo} className="report-logo" />
                    </div>
                    <table className="table table-bordered left ms-5 ">
                      <thead></thead>
                      <tbody>
                        <tr>
                          <th colSpan={6} className='left'>Plans After Graduation</th>
                        </tr>
                        <tr>
                          <th>Plan</th>
                          <th colSpan={5}>Tally</th>
                        </tr>
                        {plansTally.map((data) => {
                          return (
                            data.plan && (
                              <tr>
                                <td>{data.plan} </td>
                                <td colSpan={5}>{data.tally} </td>
                              </tr>
                            )
                          );
                        })}
                        <tr>
                          <br></br>
                        </tr>
                        <tr>
                          <th colSpan={6} className='left'>Level of Quality in Relation to Degree Program</th>
                        </tr>

                        <tr>
                          <th></th>
                          <th colSpan={2}>Not Developed</th>
                          <th>Developed</th>
                          <th colSpan={2}>Highly Developed</th>
                        </tr>
                        {responsesTally.map((data) => {
                          return (
                            <tr>
                              <td>{data.question} </td>
                              <td colSpan={2}>{data.not_developed}</td>
                              <td>{data.developed}</td>
                              <td colSpan={2}>{data.highly_developed}</td>
                            </tr>
                          );
                        })}
                        <tr>
                          <br></br>
                        </tr>

                        <tr>
                          <th></th>
                          <th>Poor</th>
                          <th>Fair</th>
                          <th>Good</th>
                          <th>Very Good</th>
                          <th>Excellent</th>
                        </tr>
                        {goodnessLikert.map((data) => {
                          return (
                            <tr>
                              <td>{data.question} </td>
                              <td>{data.poor}</td>
                              <td>{data.fair}</td>
                              <td>{data.good}</td>
                              <td>{data.very_good}</td>
                              <td>{data.excellent}</td>
                            </tr>
                          );
                        })}
                        <tr>
                          <br></br>
                        </tr>
                        <tr>
                          <th colSpan={6} className='left'>ESSAY RESPONSES</th>
                        </tr>
                        <tr>
                          <th colSpan={6} className='left'>WHAT ARE YOUR BEST EXPERIENCES IN THE UNIVERSITY?</th>
                        </tr>
                        {
                          essayData.map((data, index) => {
                            return (
                              data.experience && (
                                <tr key={index}>
                                  <td colSpan={7}>{data.experience}</td>
                                </tr>
                              )
                            );
                          })
                        }
                        <tr>
                          <br></br>
                        </tr>
                        <tr>
                          <th colSpan={6} className='left'>WHAT CHALLENGES DID YOU ENCOUNTER IN THE UNIVERSITY? HOW DID YOU MANAGE IT?</th>
                        </tr>
                        {
                          essayData.map((data, index) => {
                            return (
                              data.challenge && (
                                <tr key={index}>
                                  <td colSpan={7}>{data.challenge}</td>
                                </tr>
                              )
                            );
                          })
                        }
                        <tr>
                          <br></br>
                        </tr>
                        <tr>
                          <th colSpan={6} className='left'>WHAT ARE YOUR COMMENTS/SUGGESTIONS FOR IMPROVEMENT FOR UP CEBU?</th>
                        </tr>
                        {
                          essayData.map((data, index) => {
                            return (
                              data.remarks && (
                                <tr key={index}>
                                  <td colSpan={7}>{data.remarks}</td>
                                </tr>
                              )
                            );
                          })
                        }


                      </tbody>
                    </table>
                    <div className="report-logo-container left mb-4 ms-5 mt-5">
                      <p>Generate on: <span>{dateToday.toLocaleString()}</span></p>
                      <p>Generated by: <span>{userName}</span></p>
                    </div>
                  </Row>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}

ExitInterview.propTypes = {
  setIsChecked: PropTypes.any,
  isChecked: PropTypes.bool,
  key: PropTypes.any,
};

export default ExitInterview;
