import Navbar from "../../Components/Navbar/Navbar";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getExitInterviewIndividual } from "../../APICalls/ExitInterview/ExitInterviewApi";
import "./ExitInterview.css";

function ExitInterview() {
  const id = useParams();
  const navigateTo = useNavigate()
  const [inactive, setInactive] = useState(false);
  const [questions, setQuestions] = useState([{ id: "", question: "" }]);
  const [question, setQuestion] = useState([{ id: "", question: "" }]);
  const [isChecked, setIsChecked] = useState(false);

  function transformString(inputString) {
    if (inputString && inputString.includes('_')) {
      const words = inputString.split('_');
      const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
      return capitalizedWords.join(' ');
    } else if (inputString) {
      return inputString.charAt(0).toUpperCase() + inputString.slice(1);
    } else {
      return "";
    }
  }

  async function fetchExitInterviewForAll() {
    setQuestions([{}]);
    setQuestion([{}]);
    const response = await getExitInterviewIndividual(id.id);
    if (response.data) {
      setQuestions(response.data.response_part_two);
      setQuestion(response.data.response_part_one);
    }
  }

  React.useEffect(() => {
    fetchExitInterviewForAll();
  }, []);

  return (
    <div className="page">
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />

      <div className="login-container">
        <div className="form-cont pt-4">
          <div className="container pt-0">
            <div className="row">
              <h1 className="col-4  left title">EXIT INTERVIEW</h1>
              <div className="row mt-4 left">
                <h3 className="header-2">PLANS AFTER GRADUATION:</h3>
              </div>
              <div className="row center mt-4 left">
                <div className="col-4">
                  <button className="btn-gren">{question.plan_1}</button>
                </div>
                <div className="col-4">
                  <button className="btn-gren">{question.plan_2}</button>
                </div>
                <div className="col-4">
                  <button className="btn-gren">{question.plan_3}</button>
                </div>
              </div>
              {/* FIRST ROW */}
              <div className="form-group mb-4">
                <div className="row mb-4">
                  <p className="row mt-5 ms-1 header-2">
                    LEVEL OF QUALITY IN RELATION TO DEGREE PROGRAM:
                  </p>
                </div>
                {questions.map((data, key) => {
                  return (
                    <div className="row ">
                      <div className="col-8">
                        <p className="row ms-1 header-2">{data.question}</p>
                      </div>
                      <div className="col-4">
                        <label className="maroon">{transformString(data.response)}</label>
                      </div>
                      <hr />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12">
                <button
                  className="back-btn btn-rad right w-13"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => {navigateTo("/alumni")}}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ExitInterview.propTypes = {
  setIsChecked: PropTypes.any,
  isChecked: PropTypes.bool,
  key: PropTypes.any,
};

export default ExitInterview;
