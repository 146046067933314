import { getToken, getUser, getDataId, removeSession, refreshPage } from "../../Helpers/Utils/Common";
import { postAPICall, postAPICallCustom } from "../axiosMethodCalls";


/***************************
 * NNOUNCEMENT
 ***************************/

const token = getToken();
const user = getUser();
const id = getDataId();

//POST
export const createAnnouncement= async (data) => {
    try {
        const response = await postAPICall(process.env.REACT_APP_LINK + "announcements/add", {
            token: token,
          requester: user,
          date_posted: data.date_posted,
          department: data.department,
          batch: data.batch,
          subject: data.subject,
          content: data.content,
      });
      return ({data:response});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getAnnouncement = async (id, date) => {
  try {
      const dateFrom = date.from ? date.from : ""
      const dateTo = date.to ? date.to : ""
      const response = await postAPICall(process.env.REACT_APP_LINK + "announcements/get", {
          requester: user,
          token: token,
          // department_id: id,
          date_from: dateFrom,
          date_to: dateTo
      });
      return ({data:response.data.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getAnnouncementDisplay = async (id) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "announcements/get", {
          requester: user,
          token: token,
          announcement_id: id,
      });
      return ({data:response.data.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const updateAnnouncement = async (id, data) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "announcements/update", {
          requester: user,
          token: token,
          announcement_id: id,
          is_emailed: data.is_emailed,
          date_posted: data.date_posted,
          department: data.department,
          batch: data.batch,
          subject: data.subject,
          content: data.content, 
    });
      return ({data:response});   
    } catch (error) {
      return ({error: error.response});
  }
}

export const deleteAnnouncement = async (id) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "announcements/delete", {
          requester: user,
          token: token,
          announcement_id: id,
      });
      return ({data:response});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}
