import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/Table/Table";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import ModalPopUp from "../../Components/Modal/Modal";
import { refreshPage } from "../../Helpers/Utils/Common";
import { getRole } from "../../Helpers/Utils/Common";
import { validateAddUser } from "../../Helpers/Validation/User";
import {createManageUser,
  deactivateUser,
  deleteManageUser,
  updateManageUser,
  getManageUser,
} from "../../APICalls/ManageUserApi";
import "./ManageUser.css";

function ManageUser({ setIsChecked, isChecked }) {
  const userRole = getRole();
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [userId, setUserId] = useState({});
  const [userHistoryData, setUserHistoryData] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [showActivate, setShowActivate] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [manageUserDetails, setManageUserDetails] = useState({
    role_id: "",
    email: "",
    last_name: "",
    first_name: "",
    showDepartment: false,
    role: ""
  })

  const [isError, setIsError] = useState({
    role_id: false,
    email: false,
    last_name: false,
    first_name: false,
  });

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    var newData = {...updateData}
    newData[name] = value;
    setUpdateData(newData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    var newData = {...manageUserDetails}
    newData[name] = value;
    setManageUserDetails(newData);
  };

  
  async function getTableData() {
    setUserHistoryData([]);
    const response = await getManageUser();
    if (response.data) {
      setUserHistoryData(response.data);
      setIsReady(true);
    }
  }

  async function getUserDetails(id) {
    const response = await getManageUser(id);
    if (response.data) {
      setUpdateData(response.data[0]);
    }
  }


  async function add() {
    if (validateAddUser(manageUserDetails, setIsError) && !isClicked) {
        setIsClicked(true)
        const response = await createManageUser(manageUserDetails); 
        if (response.data) {
          toast.success(response.data.data.response.toUpperCase());
          setTimeout(function () {
            refreshPage();
          }, 1500);
        } else {
        toast.error(response.error.data.messages.error.toUpperCase());
      }
    } 
  }

  async function update() {
    // console.log(updateData)
    if (updateData.role_id === '1') {
      updateData.department_id = '';
      updateData.department = '';
    }
    if (validateAddUser(updateData, setIsError) && !isClicked) {
      setIsClicked(true);
      const response = await updateManageUser(userId, updateData);
      if (response.data && response.data.status === 200) {
        toast.success(response.data.data.response.toUpperCase());
        setTimeout(() => refreshPage(), 1500);
      } else {
        setIsClicked(false);
        toast.error(response.error.data.messages.error.toUpperCase());
      }
    }
  }

 
  async function handleDeactivateUser(status) {
    const response = await deactivateUser(userId, status);
    if (response.data) {
      toast.success(response.data.data.response.toUpperCase());
        setTimeout(function () {
          refreshPage();
        }, 1000);
    } else {
      toast.error(response.error.data.response.toUpperCase());
    }
  }

 //DELETE API
  async function handleDelete () {
    const response = await deleteManageUser(userId);
    if (response.data) {
      toast.success(response.data.data.response.toUpperCase());
        setTimeout(function () {
          refreshPage();
        }, 1000);
    } else {
      toast.error(response.error.data.response.toUpperCase());
    }
  }

  const handleSelectChange = (e) => {
    const { id, name, value } = e.target;
    setUserId(id);
    getUserDetails(id);
    if (value === "update") {
      setShowModalEdit(true);
    } else if (value === "deactivate") {
      setShowDeactivate(true);
    } else if (value === "activate") {
      setShowActivate(true);
    } else {
      setShowDelete(true);
    }
  };

  React.useEffect(() => {
    getTableData();
  }, []);

  return (
    <div className="page">
      <ToastContainer />
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />
      <div className=" login-container center">
        <div className={`container ${inactive ? "inactive" : "active"}`}>
          <div className="form-cont .center-form-cont pt-4">
            <div className="container ms-1 pt-0">
              <div className="row">
                <div className="col-8 row-height">
                  <h1 className="title left">MANAGE USER</h1>
                </div>
                <div className="col-1"></div>
                {/* {(userRole == "1" || userRole == "5") && ( */}
                  <div className="col pt-2">
                    <button
                      className="right maroon-btn"
                      onClick={() => setShowAddModal(true)}
                    >
                      ADD USER
                    </button>
                  </div>
                {/* )} */}
              </div>
              {/* {(userRole!=="2"&&userRole!=="3"&&userRole!=="8") && ( */}
                <div className="row row-form mb-4">
                  <Table
                    type={"manage_user"}
                    tableData={userHistoryData}
                    clickable={true}
                    headingColumns={["NAME", "USER ROLE", "EMAIL", "STATUS", "ACTION"]}
                    onSelectChange={handleSelectChange}
                    useLoader={true}
                    isReady={isReady}
                  />
                </div>
              {/* )} */}
            </div>
          </div>
        </div>
        <ModalPopUp
          type="deactivate-user"
          show={showDeactivate}
          handleClose={() => setShowDeactivate(false)}
          handleDelete={() => handleDeactivateUser("inactive")}
          data={updateData}
        />
        <ModalPopUp
          type="delete-user"
          show={showDelete}
          handleClose={() => setShowDelete(false)}
          handleDelete={handleDelete}
          data={updateData}
        />
        <ModalPopUp
          type="activate-user"
          show={showActivate}
          handleClose={() => setShowActivate(false)}
          handleUpdate={() => handleDeactivateUser("active")}
          data={updateData}
        />
        <ModalPopUp
          type="add-user"
          show={showAddModal}
          handleClose={() => setShowAddModal(false)}
          data={manageUserDetails}
          isError={isError}
          handleAdd={add}
          setData={handleChange}
        />
        <ModalPopUp
          type="edit-user"
          show={showModalEdit}
          handleClose={() => setShowModalEdit(false)}
          handleUpdate={update}
          setData={handleUpdateChange}
          data={updateData}
          isError={isError}
        />
      </div>
    </div>
  );
}

export default ManageUser;
