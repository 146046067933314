import React, { useEffect } from "react";
import { useState } from "react";
import darrowright from "../../Assets/Images/TableFooter/doublearrowright.png"
import arrowright from "../../Assets/Images/TableFooter/arrowright.png"
import darrowleft from "../../Assets/Images/TableFooter/doublearrowleft.png"
import arrowleft from "../../Assets/Images/TableFooter/arrowleft.png"
import "./TableFooter.css";

const TableFooter = ({
  range,
  setPage,
  page,
  slice,
  footerClass,
  setRowsPerPage,
  rowsPerPage,
}) => {
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(rowsPerPage);

  function prev() {
    var newStartIndex = Math.max(startIndex - rowsPerPage, 0);
    setStartIndex(newStartIndex);
    setEndIndex(newStartIndex + rowsPerPage);
  }

  function next() {
    var newStartIndex = Math.min(startIndex + rowsPerPage, range.length - rowsPerPage);
    setStartIndex(newStartIndex);
    setEndIndex(newStartIndex + rowsPerPage);
  }

  function start() {
    setStartIndex(0);
    setEndIndex(rowsPerPage);
    setPage(range[0]);
  }

  function end() {
    setStartIndex(Math.max(range.length - rowsPerPage, 0));
    setEndIndex(range.length);
    setPage(range[range.length - 1]);
  }

  function setPageNav(el) {
    var selectedPage = parseFloat(el);
    var newStartIndex = (selectedPage - 1) * rowsPerPage;
    setStartIndex(newStartIndex);
    setEndIndex(newStartIndex + rowsPerPage);
    setPage(selectedPage);
  }

  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);

  useEffect(() => {
    // Reset startIndex and endIndex when rowsPerPage changes
    setStartIndex(0);
    setEndIndex(rowsPerPage);
  }, [rowsPerPage]);

  return (
    <div className="noprint">
      <div className={"tableFooter " + footerClass}>
      <div className="col page-count-cont">
        <span>Rows per page: </span>
        <select
          className="rows-input"
          onChange={(e) => setRowsPerPage(e.target.value)}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={150}>150</option>
          <option value={500}>500</option>
        </select>
        <span>
          | {page} of {range.length}
        </span>
      </div>
      <div className="col pages-cont d-flex justify-content-center">
        <button className="button navigateButton" onClick={() => start()}>
          <img
            src={darrowleft}
            className="arrow-icon"
          />
        </button>
        <button
          className={
            range.length <= 5
              ? "disable button navigateButton"
              : "button navigateButton"
          }
          onClick={() => prev()}
        >
          <img
            src={arrowleft}
            className="arrow-icon"
          />
        </button>
        
        {range.slice(startIndex, endIndex).map((el, index) => (
          <button
            key={index}
            className={`${"button"} ${
              page === el ? "activeButton" : "inactiveButton"
            }`}
            onClick={() => setPage(el)}
          >
            {el}
          </button>
        ))}
        <button
          className={
            range.length <= 5
              ? "disable button navigateButton"
              : "button navigateButton"
          }
          onClick={() => next()}
        >
          <img
            src={arrowright}
            className="arrow-icon"
          />
        </button>
        <button className="button navigateButton" onClick={() => end()}>
          <img
            src={darrowright}
            className="arrow-icon"
          />
          
        </button>
      </div>
      <div className="col d-flex justify-content-end">
        <select
          className="page-number-input"
          value={page}
          onChange={(e) => setPageNav(e.target.value)}  
        >
          <option value="" disabled>
            page
          </option>
          {range.map((el, index) => {
            return (
              <option key={index} value={el}>
                {index + 1}
              </option>
            );
          })}
        </select>
      </div>
    </div>
    </div>
  );
};


export default TableFooter;
