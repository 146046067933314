import { getToken, getUser, getDataId, removeSession, refreshPage } from "../../Helpers/Utils/Common";
import { postAPICall, postAPICallCustom } from "../axiosMethodCalls";


/***************************
 * EXIT INTERVIEW
 ***************************/

const token = getToken();
const user = getUser();
const id = getDataId();

//POST
export const getExitInterviewForAll = async (data) => {
  try {
      const year = data.year ? data.year : ""
      const degree_program_id = data.degree_program_id ? data.degree_program_id : ""
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_interviews/get_overall_responses", {
          requester: user,
          token: token,
          year: year,
          degree_program_id: degree_program_id,
      });
      return ({data:response.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getExitInterviewIndividual= async (id) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_interviews/get", {
          requester: user,
          token: token,
          alumni_id: id,
      });
      return ({data:response.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getExitInterviewOthers = async (id) => {
  try {
      // const response = await postAPICall(process.env.REACT_APP_LINK + "reports/get_others_exit_interview", {
      const response = await postAPICall(process.env.REACT_APP_LINK + "reports/get_others_exit_interview", {
          requester: user,
          token: token,
          // alumni_id: id,
      });
      return ({data:response.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getEssayResponses = async (filter) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_interviews/get_essay_responses", {
          requester: user,
          token: token,
      });
      return ({data:response.data});   
  } catch (error) {
    if (error.response.status === 401) {
      removeSession();
      refreshPage();
    }
    return ({error: error.response});
  }
}


