
import { useState, useEffect } from "react";
import { postAPICall } from "../axiosMethodCalls";
import { getToken, getUser, refreshPage, removeSession, getDepartment } from "../../Helpers/Utils/Common";

const token = getToken()
const user = getUser()
const department_id = getDepartment()

export const addDegreeProgram = async (data) => {
    try {
        const course_name = data.course_name
        const department_id = data.department_id
        const response = await postAPICall(process.env.REACT_APP_LINK + "degree_programs/add", {
            requester: user,
            token: token,
            department_id: department_id,
            name: course_name
        })
        return ({data:response})
    } catch (error) {
        // if (error.response.status === 401) {
        //     removeSession()
        //     refreshPage()
        // }
        return ({error: error.response});
    }
}

export const GetDegreePrograms = () => {
    const [data, setData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await postAPICall (process.env.REACT_APP_LINK + "degree_programs/get", {
                requester: await getUser(),
                token: await getToken(),
                department_id: await getDepartment()
                })
                var departments = response.data.data
                var selected = departments.map((data) => {
                    return {value: data.id, label: data.degree_program, department: data.department}
                })
                setData(selected)
            } catch (error) {
                if (error.response.status === 401) {
                    removeSession()
                    refreshPage()
                }
            }
        };
        fetchData();
    }, []);

    return data;
};

export const getDegreeProgram = async (id, dept_id) => {
    try {
        const response = await postAPICall(process.env.REACT_APP_LINK + "degree_programs/get", {
            requester: user,
            token: token,
            degree_program_id: id,
            department_id: dept_id,
        })
        return ({data:response.data.data})
    } catch (error) {
        // if (error.response.status == 401) {
        //     removeSession();
        //     refreshPage();
        // }
    }
}

export const updateDegreeProgram = async (id, data) => {
    try {
        const response = await postAPICall(process.env.REACT_APP_LINK + "degree_programs/update", {
            requester: user,
            token: token,
            degree_program_id: id,
            department_id: data.department_id,
            name: data.course_name
        })
        return ({data:response})
    } catch (error) {
        if (error.response.status == 401) {
            removeSession()
            refreshPage()
        }
    }
}

export const deleteDegreeProgram = async (id) => {
    try {
        const response = await postAPICall(process.env.REACT_APP_LINK + "degree_programs/delete", {
            requester: token,
            user: user,
            degree_program_id: id
        })
        return ({data:response})
    } catch (error) {
        if (error.response.status == 401) {
            removeSession()
            refreshPage()
        }
    }
}