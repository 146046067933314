import { getToken, getUser, refreshPage, removeSession } from "../../Helpers/Utils/Common";
import { postAPICall } from "../axiosMethodCalls";
import { useState, useEffect } from "react";

const token = getToken();
const user = getUser();

export const GetDepartments = () => {
    const [data, setData] = useState([])

    useEffect (() => {
        const fetchData = async() => {
            try {
                const response = await postAPICall (process.env.REACT_APP_LINK + "departments/get", {
                    requester: await getUser(),
                    token: await getToken(),
                    department_id: '',
                    college_id: ''
                })
                var departments = response.data.data
                var selected = departments.map((data) => {
                    return {value: data.id, label: data.department, college: data.college}
                })
                setData(selected)
            } catch (error) {
                if (error.response.status === 401) {
                    removeSession()
                    refreshPage()
                }
            }
        };
        fetchData()
    }, [])

    return data;
}

export const addDepartment = async (data) => {
    try {
        const response = await postAPICall(process.env.REACT_APP_LINK + "departments/add", {
        requester: user,
        token: token,
        college_id: data.college_id,
        name: data.department_name
    })
      return ({data:response});   
  } catch (error) {
      return ({error: error.response});
  }
}

export const getDepartment = async (id) => {
    try {
        const response = await postAPICall(process.env.REACT_APP_LINK + "departments/get", {
            requester: user,
            token: token,
            department_id: id
        })
        return ({data:response.data.data})
    } catch (error) {
        // if (error.response.status == 401) {
        //     removeSession();
        //     refreshPage();
        // }
    }
}

export const updateDepartment = async (department_id, data) => {
    try {
        const response = await postAPICall(process.env.REACT_APP_LINK + "departments/update", {
            requester: user,
            token: token,
            department_id: department_id,
            college_id: data.college_id,
            name: data.department_name
        })
        return ({data:response})
    } catch (error) {
    }
}

export const deleteDepartment = async (id) => {
    try {
        const response = await postAPICall(process.env.REACT_APP_LINK + "departments/delete", {
            requester: token,
            user: user,
            department_id: id
        })
        return ({data:response})
    } catch (error) {
        if (error.response.status == 401) {
            removeSession()
            refreshPage()
        }
    }
}