import React from "react";
import { useState, useRef } from "react";
import { ToastContainer } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";

import { getAlumniNumberGrad, getOldestBatch } from "../../APICalls/ManageUserApi";

import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/Table/Table";

import "./EmployabilityRate.css";

function GraduatesReport() {
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [alumniData, setAlumniData] = useState([{
    year_graduated: "",
    total_graduates: "",
  }]);
  const [filterData, setFilterData] = useState({
    date_from: 2000,
    date_to: 2022,
  });
  const [years, setYears] = useState([]);
  const componentRef = useRef();


   const csvHeaders = [
    { label: "YEAR", key: "year"},
    { label: "DEPARTMENT", key: "department_name" },
    { label: "GRADUATES", key: "graduates" },
    { label: "TOTAL GRADUATES", key: "total_graduates" },
  ];

  async function getAlumniGrads() {
    setAlumniData([]);
    const response = await getAlumniNumberGrad(filterData);
    if (response.data) {
      setAlumniData(response.data);
      setIsReady(true);
    }
  }

  async function getBatch() {
    const response = await getOldestBatch();
    if (response.data) {
      var years_arr = [{ name: parseInt(response.data[0].oldest_batch) }];
      for (
        var i = parseInt(response.data[0].oldest_batch);
        i < new Date().getFullYear();
        ++i
      ) {
        years_arr.push({ name: i + 1 });
      }
      setYears(years_arr);
    }
  }
 
  React.useEffect(() => {
    getAlumniGrads();
  }, [filterData]);

  React.useEffect(() => {
    getBatch();
  }, []);

  return (
    <div className="page">
      <ToastContainer />
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
      />
      <div className="form-cont pt-4">
        <div className="container pt-0">
          <div ref={componentRef}>
            <Row>
              <Col className="col-6">
                <h1 className="project-title left">NUMBER OF GRADUATES</h1>
              </Col>
              <Col className="left pt-1">
                <Row>
                  <Col className="noprint col-2 me-4">
                    <Row>
                      <p className="form-label left">FROM:</p>
                    </Row>
                    <Row>
                      <select
                        type="text"
                        name="from-date"
                        className="right maroon-btn"
                        onChange={(e) =>
                          setFilterData({
                            ...filterData,
                            date_from: e.target.value,
                          })
                        }
                      >
                        {years.map((data) => (
                          <option value={data.name}>{data.name}</option>
                        ))}
                      </select>
                    </Row>
                    {/* <p className="from-label right">
                    FROM:{" "}
                    <select
                      type="text"
                      name="from-date"
                      className="right maroon-btn ms-2"
                      onChange={(e) =>
                        setFilterData({
                          ...filterData,
                          date_from: e.target.value,
                        })
                      }
                    >
                      {years.map((data) => (
                        <option value={data.name}>{data.name}</option>
                      ))}
                    </select>
                  </p> */}
                  </Col>
                  <Col className="noprint col-2 me-4">
                    <Row>
                      <p className="form-label left">TO:</p>
                    </Row>
                    <Row>
                      <select
                        type="text"
                        name="to-date"
                        className="right maroon-btn"
                        onChange={(e) =>
                          setFilterData({
                            ...filterData,
                            date_to: e.target.value,
                          })
                        }
                      >
                        {years.map((data) => (
                          <option value={data.name}>{data.name}</option>
                        ))}
                      </select>
                    </Row>
                    {/* <p className="from-label right">
                    TO:{" "}
                    <select
                      type="text"
                      name="from-date"
                      className="right maroon-btn ms-2"
                      onChange={(e) =>
                        setFilterData({
                          ...filterData,
                          date_to: e.target.value,
                        })
                      }
                    >
                      {years.map((data) => (
                        <option value={data.name}>{data.name}</option>
                      ))}
                    </select>
                  </p> */}
                  </Col>
                  <Col className="noprint col-2 me-0">
                    <Row className="white-text">label</Row>
                    <Row>
                      <ReactToPrint
                      trigger={() => (
                        <button className="yellow-btn ">Print</button>
                      )}
                      content={() => componentRef.current}
                      documentTitle={
                        "Number of Graduates"
                      }
                    />
                    </Row>
                  </Col>
                  <Col className="noprint col-4 left">
                    <Row className="white-text">label</Row>
                    <Row>
                      <CSVLink
                        data={alumniData}
                        headers={csvHeaders}
                        filename={"Number of Graduates"}
                      >
                        <button className=" width-fill height-fill orange-btn">
                          DOWNLOAD CSV
                        </button>
                      </CSVLink>
                    </Row>
                  </Col>
                  
                </Row>
              </Col>
            </Row>
      
            <div className="row row-form mb-4 mt-4">
              <Table
                type={"total_graduates"}
                tableData={alumniData}
                headingColumns={[
                  "YEAR",
                  "DEPARTMENT",
                  "GRADUATES",
                  "TOTAL GRADUATES"
                ]}
                useLoader={true}
                isReady={isReady}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GraduatesReport;
