import Navbar from "../../Components/Navbar/Navbar";
import React, { useState, useRef } from "react";
import "./StudentProfile.css";
import { useParams, useNavigate } from "react-router-dom";
import {Row, Col, Table} from 'react-bootstrap'
import {
  getAcademicInformation,
  getAlumniProfile,
  getEmploymentInformation,
  getMembershipInformation,
  getCurrentEmployment
} from "../../APICalls/SingleAlumni/SingleAlumniApi";
import ReactToPrint from "react-to-print";

/* Assets */
import dashhead from "../../Assets/Images/Dashboard/dashboard_head.png";
import userpic from "../../Assets/Images/Dashboard/user_profile.png";
import gradcap from "../../Assets/Images/Dashboard/GraduationCap.png";
import residence from "../../Assets/Images/Dashboard/HomePage.png";
import bag from "../../Assets/Images/Dashboard/Briefcase.png";
import hand from "../../Assets/Images/Dashboard/Handshake.png";
import NoDataFoundImg from '../../Assets/Images/noDataFound.png'

function StudentProfile({ setIsChecked, isChecked }) {
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const id = useParams();
  const componentRef = useRef();
  const navigate = useNavigate();

  const [alumni, setAlumni] = useState({});
  const [employementDetails, setEmploymentDetails] = useState({});
  const [CurrentOccupation, setCurrentOccupation] = useState({})
  const [employementDetailsInTable, setEmploymentDetailsInTable] = useState([]);
  const [academicDetails, setAcademicDetails] = useState([]);
  const [membershipDetailsInTable, setMembershipDetailsInTable] = useState([]);


  // GET ALUMNI DETAILS
  async function getAlumni() {
    const response = await getAlumniProfile(id.id);
    if (response.data) {
      setAlumni(response.data);
    }
  }

  // GET EMPLOYMENT HISTORY
  async function getEmploymentDetailsTable() {
    const response = await getEmploymentInformation(id.id);
    if (response.data) {
      const res = response.data;
      setEmploymentDetailsInTable(res);
      // Get current employment details ; if multiple, first to satisfy condition is picked
      const current = res.filter(obj => {
        return obj.is_current === "1";
      });
      setCurrentOccupation(current)
    }
  }

  // GET ACADEMIC INFORMATION
  async function getAcademicDetails() {
    const response = await getAcademicInformation(id.id);
    if (response.data) {
      const res = response.data;
      const filtered = res.filter(obj => {
        return obj.alumni_id === id.id;
      });
      setAcademicDetails(filtered)
    }
  }

  // GET MEMBERSHIP IN ORGANIZATIONS
  async function getMembershipDetailsTable() {
    const response = await getMembershipInformation(id.id);
    if (response.data) {
      setMembershipDetailsInTable(response.data);
    }
  }

  React.useEffect(() => {
    getAlumni();
    getEmploymentDetailsTable();
    getAcademicDetails();
    getMembershipDetailsTable();
  }, []);

  React.useEffect(() => {
    let loadingTime = setTimeout(() => {
      setIsReady(true);
    }, 5000);
  }, []);

  return (
    <div className="page">
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />

      <div className="login-container">
        <div className="form-cont entire-page" ref={componentRef}>
          <Row className="profile-header-container">
            <Col xs={2} className=''>
              <img className="dashboard-user-icon" src={userpic} alt="user-pic" />
            </Col>
            <Col xs={8}>
              <h1 className="profile-header left p-50">{alumni.first_name?alumni.first_name:''} <span> {alumni.middle_name?alumni.middle_name:''} </span> {alumni.last_name?alumni.last_name:''}</h1>
            </Col>
          </Row>
          <Row className="mt-3 ms-2">
            <Col xs={3} className="profile-subheader ">
              <span>PERSONAL INFORMATION</span>
            </Col>
          </Row>
          <Row className="mt-3 ms-2">
            <Col xs={3} className="section-container dark-grey p-20 me-4">
              <p className="input-subtitle-grey">CONTACT NUMBER</p>
              <p className="input-subtitle">{alumni.contact_no ? alumni.contact_no : '---'}</p>

              <p className="input-subtitle-grey pt-3">EMAIL ADDRESS</p>
              <p className="input-subtitle">{alumni.personal_email ? alumni.personal_email : '---'}</p>

              <p className="input-subtitle-grey pt-3">SOCIAL MEDIA ACCOUNTS</p>
              <p className="input-subtitle">{alumni.facebook ? alumni.facebook : ''}</p>
              <p className="input-subtitle">{alumni.linkedin ? alumni.linkedin : ''}</p>
              <p className="input-subtitle">{alumni.twitter ? alumni.twitter : ''}</p>
              <p className="input-subtitle">{alumni.instagram ? alumni.instagram : ''}</p>
              <p className="input-subtitle">{alumni.github ? alumni.github : ''}</p>
              <p className="input-subtitle">{alumni.youtube ? alumni.youtube : ''}</p>
              <p className="input-subtitle">{alumni.personal_website ? alumni.personal_website : ''}</p>

              <p className="input-subtitle-grey pt-3">PRONOUNS</p>
              <p className="input-subtitle">{alumni.pronouns?alumni.pronouns:''}</p>
            </Col>

            <Col xs={8}>
              <Row className="align-items-center">
                  <img className="col-2 dash-info-icon" src={gradcap} alt="grad cap" />
                  <h3 className="col dash-sub-info p-20">EDUCATIONAL BACKGROUND </h3>
              </Row>
              <Row className="rad light-grey p-20">
                <Row>
                  <Col xs={4}>
                    <Row className="input-subtitle-grey pt-3">COLLEGE/SCHOOL</Row>
                    <Row className="input-subtitle">{alumni.college ? alumni.college : '---'}</Row>
                  </Col>
                  <Col xs={4}>
                    <Row className="input-subtitle-grey pt-3">DEGREE/CERTIFICATE</Row>
                    <Row className="input-subtitle">{alumni.degree_program ? alumni.degree_program : '---'}</Row>
                  </Col>
                  <Col xs={4}>
                    <Row className="input-subtitle-grey pt-3">STUDENT NUMBER</Row>
                    <Row className="input-subtitle">{alumni.student_no ? alumni.student_no : '---'}</Row>
                  </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                      <Row className="input-subtitle-grey pt-3">LATIN AWARD/ DISTINCTION</Row>
                      <Row className="input-subtitle">{alumni.college ? alumni.college : '---'}</Row>
                    </Col>
                    <Col xs={4}>
                      <Row className="input-subtitle-grey pt-3">SEMESTER GRADUATED</Row>
                      <Row className="input-subtitle">{alumni.degree_program ? alumni.degree_program : '---'}</Row>
                    </Col>
                    <Col xs={4}>
                      <Row className="input-subtitle-grey pt-3">ACADEMIC YEAR</Row>
                      <Row className="input-subtitle">{alumni.student_no ? alumni.student_no : '---'}</Row>
                    </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <Row className="input-subtitle-grey pt-3">YEAR CONFERRED</Row>
                    <Row className="input-subtitle">{alumni.student_no ? alumni.student_no : '---'}</Row>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>

          <Row className="ms-2">
            <Col xs={3} className='me-4'></Col>
            <Col xs={8}>
              <Row className="align-items-center">
                  <img className="col-2 dash-info-icon" src={residence} alt="grad cap" />
                  <h3 className="col dash-sub-info p-20">RESIDENTIAL INFORMATION</h3>
              </Row>
              <Row className="rad light-grey p-20">
                <Col>
                  <Row className="input-subtitle-grey pt-3">PRESENT ADDRESS</Row>
                  <Row className="input-subtitle">{alumni.present_address ? alumni.present_address : '---'}</Row>
                </Col>
                <Row>
                  <Col xs={3}>
                    <Row className="input-subtitle-grey pt-3">COUNTRY</Row>
                    <Row className="input-subtitle">{alumni.present_country ? alumni.present_country : '---'}</Row>
                  </Col>
                  <Col xs={3}>
                    <Row className="input-subtitle-grey pt-3">STATE/ PROVINCE</Row>
                    <Row className="input-subtitle">{alumni.present_province ? alumni.present_province : '---'}</Row>
                  </Col>
                  <Col xs={3}>
                    <Row className="input-subtitle-grey pt-3">CITY</Row>
                    <Row className="input-subtitle">{alumni.present_city ? alumni.present_city : '---'}</Row>
                  </Col>
                  <Col xs={3}>
                    <Row className="input-subtitle-grey pt-3">POSTAL CODE</Row>
                    <Row className="input-subtitle">{alumni.present_postal_code ? alumni.present_postal_code : '---'}</Row>
                  </Col>
                </Row>
                <Col>
                  <Row className="input-subtitle-grey pt-3">PERMANENT ADDRESS</Row>
                  <Row className="input-subtitle">{alumni.permanent_address ? alumni.permanent_address : '---'}</Row>
                </Col>
                <Row>
                  <Col xs={3}>
                    <Row className="input-subtitle-grey pt-3">COUNTRY</Row>
                    <Row className="input-subtitle">{alumni.permanent_country ? alumni.permanent_country : '---'}</Row>
                  </Col>
                  <Col xs={3}>
                    <Row className="input-subtitle-grey pt-3">STATE/ PROVINCE</Row>
                    <Row className="input-subtitle">{alumni.permanent_province ? alumni.permanent_province : '---'}</Row>
                  </Col>
                  <Col xs={3}>
                    <Row className="input-subtitle-grey pt-3">CITY</Row>
                    <Row className="input-subtitle">{alumni.permanent_city ? alumni.permanent_city : '---'}</Row>
                  </Col>
                  <Col xs={3}>
                    <Row className="input-subtitle-grey pt-3">POSTAL CODE</Row>
                    <Row className="input-subtitle">{alumni.permanent_postal_code ? alumni.permanent_postal_code : '---'}</Row>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>

          <hr/>

          <Row className="mt-3 ms-2">
            <Col xs={3} className="profile-subheader ">
              <span>EMPLOYMENT INFORMATION</span>
            </Col>
          </Row>
          
          <Row className="ms-5">
            <Col xs={11} className='ms-2'>
              <Row className="align-items-center">
                  <img className="col-2 dash-info-icon" src={bag} alt="grad cap" />
                  <h3 className="col dash-sub-info p-20">CURRENT OCCUPATION</h3>
              </Row>
              <Row>
                {(!CurrentOccupation && CurrentOccupation.length) ? (
                  <>
                    {CurrentOccupation.map((currentOccupation) => {
                    return (
                      <Row className="rad light-grey p-20 mb-3">
                      <Row>
                        <Col xs={4}>
                          <Row className="input-subtitle-grey pt-3">TYPE OF INSTITUTION</Row>
                          <Row className="input-subtitle">{currentOccupation.institution_type ? currentOccupation.institution_type : '---'}</Row>
                        </Col>
                        <Col xs={4}>
                          <Row className="input-subtitle-grey pt-3">SECTOR</Row>
                          <Row className="input-subtitle">{currentOccupation.sector ? currentOccupation.sector : '---'}</Row>
                        </Col>
                        <Col xs={4}>
                          <Row className="input-subtitle-grey pt-3">COMPANY INSTITUTION</Row>
                          <Row className="input-subtitle">{currentOccupation.company_institution ? currentOccupation.company_institution : '---'}</Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={4}>
                          <Row className="input-subtitle-grey pt-3">POSITION/ RANK</Row>
                          <Row className="input-subtitle">{currentOccupation.position ? currentOccupation.position : '---'}</Row>
                        </Col>
                        <Col xs={4}>
                          <Row className="input-subtitle-grey pt-3">SPECIALIZATION</Row>
                          <Row className="input-subtitle">{currentOccupation.specialization ? currentOccupation.specialization : '---'}</Row>
                        </Col>
                        <Col xs={4}>
                          <Row className="input-subtitle-grey pt-3">MONTHLY SALARY RATE</Row>
                          <Row className="input-subtitle">{currentOccupation.monthly_salary ? currentOccupation.monthly_salary : '---'}</Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={4}>
                          <Row className="input-subtitle-grey pt-3">TYPE OF EMPLOYMENT</Row>
                          <Row className="input-subtitle">{currentOccupation.type ? currentOccupation.type : '---'}</Row>
                        </Col>
                        <Col xs={4}>
                          <Row className="input-subtitle-grey pt-3">EXTENT OF EMPLOYMENT</Row>
                          <Row className="input-subtitle">{currentOccupation.extent ? currentOccupation.extent : '---'}</Row>
                        </Col>
                        <Col xs={4}>
                          <Row className="input-subtitle-grey pt-3">JOB DESCRIPTION</Row>
                          <Row className="input-subtitle">{currentOccupation.description ? currentOccupation.description : '---'}</Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={4}>
                          <Row className="input-subtitle-grey pt-3">PERIOD OF EMPLOYMENT</Row>
                          <Row className="input-subtitle">{(currentOccupation.date_started ? currentOccupation.date_started : '---') + (employementDetails.date_ended ? ' - ' + employementDetails.date_ended : '---')}</Row>
                        </Col>
                      </Row>
                    </Row> 
                    )
                  })}
                  </>
                ) : (
                  <Row className="d-flex justify-content-center">
                    <img src={NoDataFoundImg} className='no-data-img smaller-img'/>
                  </Row>
                )}
                
              </Row>
            </Col>
          </Row>

          <Row className="ms-5">
            <Col xs={11} className='ms-2'>
              <Row className="align-items-center">
                  <h3 className="col dash-sub-info p-20">HISTORY</h3>
              </Row>
              <Row className="rad light-grey p-20">
                <Table className="profile-table left">
                  <thead className="profile-table-header">
                    <th>OCCUPATION</th>
                    <th>COMPANY/INSTITUTION</th>
                    <th>POSITION/DEGREE</th>
                    <th>START DATE</th>
                    <th>END DATE</th>
                    <th>SALARY DATE</th>
                    <th>DESCRIPTION</th>
                  </thead>
                  <tbody>
                    {
                      (employementDetailsInTable && employementDetailsInTable.length) ? (
                        employementDetailsInTable.map((data) => {
                        return (
                          <tr>
                          <td>{data.specialization ? data.specialization : '--'}</td>
                          <td>{data.company_institution ? data.company_institution : '--'}</td>
                          <td>{data.position ? data.position : '--'}</td>
                          <td>{data.date_started ? data.date_started : '--'}</td>
                          <td>{data.date_ended ? data.date_ended : '--'}</td>
                          <td>{data.monthly_salary ? data.monthly_salary : '--'}</td>
                          <td>{data.description ? data.description : '--'}</td>
                        </tr>
                        )
                      })
                      ) : (
                        <td colSpan={15}>
                          <img src={NoDataFoundImg} className='no-data-img smaller-img centered-image p-0'/>
                        </td>
                      )
                    }
                  </tbody>
                </Table>
              </Row>
            </Col>
          </Row>

          <hr/>
{/* ACADEMIC INFORMATION */}
          <Row className="mt-3 ms-2">
            <Col xs={3} className="profile-subheader ">
              <span>ACADEMIC INFORMATION</span>
            </Col>
          </Row>
          <Row className="ms-5">
            <Col xs={11} className='ms-2 mt-3'>
              {/* <Row className="rad light-grey p-20"> */}
              <Row className="rad light-grey p-20">
                <Table className="profile-table left">
                  <thead className="profile-table-header">
                    <th>INSTITUTION</th>
                    <th>DEGREE</th>
                    <th>START DATE</th>
                    <th>END DATE</th>
                  </thead>
                  <tbody>
                    {
                      (academicDetails && academicDetails.length) ? (
                        academicDetails.map((data) => {
                        return (
                          <tr>
                          <td>{data.institution ? data.institution : '--'}</td>
                          <td>{data.degree ? data.degree : '--'}</td>
                          <td>{data.date_started ? data.date_started : '--'}</td>
                          <td>{data.date_ended ? data.date_ended : '--'}</td>
                        </tr>
                        )
                      })
                      ) : (
                        <td colSpan={15}>
                          <img src={NoDataFoundImg} className='no-data-img smaller-img centered-image p-0'/>
                        </td>
                      )
                    }
                  </tbody>
                </Table>
              </Row>
            </Col>
          </Row>

          <hr/>

          <Row className="mt-3 ms-2">
            <Col xs={3} className="profile-subheader ">
              <span>MEMBERSHIP IN ORGANIZATIONS</span>
            </Col>
          </Row>
          <Row className="ms-5">
            <Col xs={11} className='ms-2 mt-3'>
              <Row className="rad light-grey p-20">
                <Table className="left">
                  <thead className="profile-table-header">
                    <th>NAME OF ORGANIZATION</th>
                    <th>PERIOD OF MEMBERSHIP</th>
                    <th>HIGHEST POSITION</th>
                  </thead>
                  <tbody>
                    {
                      (membershipDetailsInTable && membershipDetailsInTable.length) ? (
                        membershipDetailsInTable.map((data) => {
                        return (
                          <tr>
                            <td>{data.name ? data.name : '--'}</td>
                            <td>{data.membership_period ? data.membership_period : '--'}</td>
                            <td>{data.position ? data.position : '--'}</td>
                          </tr>
                        )
                      })
                      ) : (
                        <td colSpan={15}>
                          <img src={NoDataFoundImg} className='no-data-img smaller-img centered-image p-0'/>
                        </td>
                      )
                    }
                  </tbody>
                </Table>
              </Row>
            </Col>
          </Row>

          <hr/>

{/* HIDE FAMILY MEMBERS INFO FOR NOW */}
          {/* <Row className="mt-3 ms-2">
            <Col xs={3} className="profile-subheader ">
              <span>UP ALUMNI FAMILY MEMBERS</span>
            </Col>
          </Row>
          <Row className="ms-5">
            <Col xs={11} className='ms-2 mt-3'>
              <Row className="rad light-grey p-20">
                <Table>
                  <thead className="profile-table-header">
                    <th>NAME</th>
                    <th>RELATION</th>
                    <th>DEGREE</th>
                    <th>UP CAMPUS</th>
                    <th>ADDRESS</th>
                    <th>EMAIL</th>
                    <th>TEL. NO/MOBILE NO.</th>
                  </thead>
                  <tbody>
                    {
                      !employementDetailsInTable ? (
                        employementDetailsInTable.map((data) => {
                        <tr>
                          <td>{data.occupation ? data.occupation : '--'}</td>
                          <td>{data.company_institution ? data.company_institution : '--'}</td>
                          <td>{data.position ? data.position : '--'}</td>
                          <td>{data.date_started ? data.date_started : '--'}</td>
                          <td>{data.date_ended ? data.date_ended : '--'}</td>
                          <td>{data.salary_date ? data.salary_date : '--'}</td>
                          <td>{data.description ? data.description : '--'}</td>
                        </tr>
                      })
                      ) : (
                        <tr>
                          <td colSpan={7} className='text-center'>No Data Found</td>
                        </tr>
                      )
                    }
                  </tbody>
                </Table>
              </Row>
            </Col>
          </Row> */}

          <hr/>

          <Row className="mt-3 ms-2 d-flex justify-content-end me-5">
            {/* <Col xs={2}>
              <ReactToPrint
                  trigger={() => (
                    <button className="btn-yellow-orange btn-rad right w-100">PRINT</button>
                  )}
                  content={() => componentRef.current}
                  documentTitle={"Report Summary"}
                />
            </Col> */}
            <Col xs={2}>
              <button className="btn-green btn-rad right w-100" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => navigate(-1)}>
                CLOSE
              </button>
            </Col>
          </Row>

        </div>
      </div>
    </div>
  );
}

export default StudentProfile;
