import { handleValidationChange } from "../CommonValidation";

export const validateAddScholarship = (data, setIsError) => {
    var isValid = true

    if (data.name === "") {
      handleValidationChange("name", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("name", false, setIsError);
    }

    if (data.contact_person === "") {
      handleValidationChange("contact_person", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("contact_person", false, setIsError);
    }

    if (data.email === "") {
      handleValidationChange("email", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("email", false, setIsError);
    }

    return isValid
}