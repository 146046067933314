import React, { useState } from "react";
import { Row, Col, InputGroup, Form} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { CSVLink } from "react-csv";
import Table from "../../Components/Table/Table";
import ModalPopUp from "../../Components/Modal/Modal";
import Navbar from "../../Components/Navbar/Navbar";
import {
  deleteAlumni,
  getAlumni,
  getAlumniProfile,
} from "../../APICalls/SingleAlumni/SingleAlumniApi";
import {
  refreshPage,
  getRole,
  getDepartment,
} from "../../Helpers/Utils/Common";
import SearchIcon from '@mui/icons-material/Search';
import "react-toastify/dist/ReactToastify.css";
import "./Alumni.css";
import { useRadioGroup } from "@mui/material";

function Alumni({ setIsChecked, isChecked }) {
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [id, setId] = useState();
  const userRole = getRole();
  const navigateto = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [alumniDetails, setAlumniDetails] = useState({});

  const csvHeaders = [
    { label: "NAME", key: "fullname" },
    { label: "STUDENT ID", key: "student_no" },
    { label: "ADDRESS", key: "permanent_address" },
    { label: "BIRTHDATE", key: "birthdate" },
    { label: "CONTACT_NO", key: "contact_no" },
    { label: "ADDED ON", key: "added_on" },
  ];

  const [filterDate, setFilterDate] = useState({
    from: "",
    to: "",
  });

  function searchFilter(e) {
      let filteredArr = [];
      let term = e.target.value.toLowerCase();
      if(tableData) {
          tableData.forEach(function(employee) {
              if((employee.fullname).toLowerCase().includes(term)) {
                  filteredArr.push(employee);
              }
          })
      }
      if(term == "") {
          setFilteredTableData(tableData);
      } else {
          setFilteredTableData(filteredArr);
      }
    }

  //GET API
  async function fetchAlumni({ filterDate }) {
    const department_id = getDepartment();
    setTableData([]);
    const response = await getAlumni(department_id, filterDate);
    if (response.data) {
      var alumni = response.data;
      alumni.map((data) => {
        data.fullname = `${data.first_name} ${data.last_name}`;
      });
      setTableData(alumni);
      setFilteredTableData(alumni);
      setIsReady(true);
    }
  }

  async function getAlumniDelete(id) {
    const response = await getAlumniProfile(id);
    if (response.data) {
      setAlumniDetails(response.data);
      setShowModalDelete(true);
    }
  }

  //Handles
  const handleUpdateChange = (e) => {
    setAlumniDetails({ ...alumniDetails, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e) => {
    const { id, data, name, value, row } = e.target;
    setId(id);
    if (value === "update") {
      navigateto("/edit-alumni/" + e.target.id);
    } else if (value === "delete") {
      getAlumniDelete(id);
    } else if (value === "profile") {
      navigateto("/exit-interview-alumni/" + e.target.id);
    }
  };

  const handleChooseAdd = (e) => {
    const { value } = e.target;
    if (value === "single") {
      navigateto("/single-entry");
    } else if (value === "batch") {
      navigateto("/batch-entry");
    }
  };

  //Delete API
  async function del() {
    const response = await deleteAlumni(id);
    if (response.data?.status === 200) {
      toast.success(response.data.data.response.toUpperCase());
    } else {
      toast.error(response.error.data.response.toUpperCase());
    }
    setShowModalDelete(false);
    setTimeout(function () {
      refreshPage();
    }, 1500);
  }

  React.useEffect(() => {
    fetchAlumni({ filterDate });
  }, [filterDate]);

  React.useEffect(() => {
    let loadingTime = setTimeout(() => {
      setIsReady(true);
    }, 1000);
  }, []);


  return (
    <div className="page">
      <ToastContainer />
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />
      <div className=" login-container">
        <div className={`container ${inactive ? "inactive" : "active"}`}>
          <div className="form-cont center me-2 pt-4">
            <div className="container pt-0">
              <Row>
                <Col className="" xs={2}>
                  <h1 className="title left">ALUMNI</h1>
                </Col>
                <Col>
                  <div className="d-flex justify-content-end">
                  <Col xs={2} className="me-1">
                    <span className="input-subtitle  d-flex justify-content-start">From:</span>
                    {/* <br /> */}
                    <input
                      type="date"
                      name="date_from"
                      className="form-control input-subtitle input-shadow input-green-bg"
                      id="date_from"
                      aria-describedby="date_from"
                      onChange={(e) =>
                        setFilterDate({ ...filterDate, from: e.target.value })
                      }
                    />
                  </Col>
                  <Col xs={2} className="me-1">
                    <span className="input-subtitle  d-flex justify-content-start">To:</span>
                    {/* <br /> */}
                    <input
                      type="date"
                      name="date_to"
                      className="form-control input-subtitle input-shadow input-green-bg"
                      id="date_to"
                      aria-describedby="date_to"
                      onChange={(e) =>
                        setFilterDate({ ...filterDate, to: e.target.value })
                      }
                    />
                  </Col>
                  {
                    userRole == "1" && (
                      <Col xs={2} className="me-1 pt-4">
                        <CSVLink
                            data={tableData}
                            headers={csvHeaders}
                            filename={"Alumni"}
                          >
                            <button className="width-fill orange-btn">
                              DOWNLOAD CSV
                            </button>
                          </CSVLink>
                      </Col>
                    )
                  }
                  {
                    (userRole !== "2" && userRole !== "3" && userRole !== "8"&& userRole!=="10") && (
                      <Col xs={2.5} className="pt-4">
                        <select
                        className="maroon-btn"
                        onChange={(e) => handleChooseAdd(e)}
                      >
                        <option>ADD</option>
                        <option value="single">Add Single Alumni</option>
                        <option value="batch">Add Batch Alumni</option>
                      </select>
                    </Col>
                    )
                  }
                  </div>
                  </Col>
              </Row>

              <Row className='mt-3'>
                  <InputGroup className="search-employee">
                      <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                      <Form.Control
                          className = "search-part me-3"
                          type="text"
                          autoComplete="off"
                          aria-label="term" 
                          aria-describedby="term" 
                          placeholder="Search Alumni"
                          name="term" 
                          id='term'
                          onChange={(e) => searchFilter(e)}
                      />
                  </InputGroup>
                </Row>

              <Row className='row-form mb-4 mt-2'>
                <div className="alumni-table-wrapper">
                  <Table
                    type={"alumni"}
                    tableData={filteredTableData}
                    clickable={true}
                    headingColumns={(userRole!=="2" && userRole!=="3" && userRole!=="8"&& userRole!=="10") ? [
                      "NAME",
                      "STUDENT ID",
                      "ADDRESS",
                      "BIRTHDATE",
                      "CONTACT NO.",
                      "ADDED ON",
                      "ACTION",
                    ] : [
                      "NAME",
                      "STUDENT ID",
                      "ADDRESS",
                      "BIRTHDATE",
                      "CONTACT NO.",
                      "ADDED ON",
                    ]}
                    useLoader={true}
                    isReady={isReady}
                    onSelectChange={handleSelectChange}
                  />
                </div>
              </Row>

            </div>
          </div>
        </div>
        <ModalPopUp
          type="delete-alumni"
          show={showModalDelete}
          handleClose={() => setShowModalDelete(false)}
          handleDelete={del}
          data={alumniDetails}
          setData={handleUpdateChange}
        />
      </div>
    </div>
  );
}

export default Alumni;
