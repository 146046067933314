import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {Row, Container} from 'react-bootstrap'

import AttachFile from "../../Assets/Images/AttachFile.png";
import Navbar from "../../Components/Navbar/Navbar";
import { createBatch } from "../../APICalls/BatchAlumni/BatchAlumni";
import { refreshPage } from "../../Helpers/Utils/Common";
import ModalPopUp from '../../Components/Modal/Modal'

import "./BatchEntry.css";


function BatchEntry({ setIsChecked, isChecked }) {
  const [inactive, setInactive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigateto = useNavigate();
  const [redirect, setRedirect] = useState("");
  const [selected, setSelected] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [uploadReady, setUploadReady] = useState(false);
  const [alumniDetails, setAlumniDetails] = useState({
    preferred_title: "",
    married_surname: "",
    last_name: "",
    first_name: "",
    middle_name: "",
    suffix: "",
    birthdate: "",
    nationality: "",
    college_id: "",
    degree: "",
    student_no: "",
    latin_award: "",
    semester_graduated: "",
    academic_year: "",
    year_conferred: "",
    scholarship: "",
    contact_no: "",
    personal_email: "",
    pronouns: "",
    permanent_address: "",
    permanent_country: "",
    permanent_province: "",
    permanent_city: "",
    permanent_postal_code: "",
    present_address: "",
    present_country: "",
    present_province: "",
    present_city: "",
    present_postal_code: "",
  });

  const [file, setFile] = useState();
  const [array, setArray] = useState([]);

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
    setSelected(true);
    setShowModal(true);
  };

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        if (header != undefined && values[index] != undefined) {
          header = header.replaceAll('"', "");
          header = header.trim();
          object[header] = values[index].trim();
        } else {
          object[header] = values[index];
        }
        return object;
      }, {});
      return obj;
    });

    array.pop();

    setArray(array);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };
      setUploadReady(true)
      fileReader.readAsText(file);
      toast.success("CONVERTED");
    }
  };

  async function add() {
    // console.log("hello")
    // console.log(array);
    setIsClicked(true);
    const response = await createBatch(array);
    if (response.data && response.data.status === 200) {
      toast.success(response.data.data.response.toUpperCase());
    } else {
      toast.error(response.error.data.messages.error.toUpperCase());
    }
    setTimeout(function () {
      navigateto('/alumni')
      refreshPage();
    }, 1500);
  }

  const inputRef = useRef(null);
  const handleClick = () => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
  };

  if (redirect === "back") {
    return <Navigate to="/alumni" />;
  }

  return (
    <div className="page center login-container">
      <ToastContainer />
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />
      <div className="form-cont">
        <Container className="pt-3 ">
          <Row><h1 className="title">BATCH ALUMNI ENTRY</h1></Row>
          <Container className="cont-attach">
            <div className="col ms-5 center">
              <input
                style={{ display: "none" }}
                type={"file"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleOnChange}
                ref={inputRef}
              />
            </div>
            <img src={AttachFile} className="attach-img"/>
            <Row className="pt-2"><span>ATTACH FILE <span className="yellows" onClick={handleClick}>HERE</span></span></Row>
          </Container>

          <Row className='mt-5 left'><h1 className="title">NOTE</h1></Row>
          <Row className='ms-5 left'>
            <p>1. Download the CSV template 
                <a
                  href="https://up-alumni-tracker-admin-api.myt-enterprise.com/sample_template.csv"
                  className="yellow ms-2"
                >'template.csv'</a>.
            </p>
            <p>2. For College column the acceptable value is "College of Science"</p>
            <p>3. For the Department column the acceptable values are:</p>
            <p className="ms-5 maroon">a. "Department of Computer Science"</p>
            <p className="ms-5 maroon">b. "Department of Biology"</p>
            <p className="ms-5 maroon">c. "Department of Mathematics"</p>

            <p>4. For the Degree column the acceptable values are:</p>
            <p className="ms-5 maroon">a. "BS Computer Science"</p>
            <p className="ms-5 maroon">b. "Masters in Computer Science"</p>
            <p className="ms-5 maroon">c. "BS Biology"</p>
            <p className="ms-5 maroon">d. "Masters in Environment Studies"</p>
            <p className="ms-5 maroon">e. "BS Mathematics"</p>
            <p className="ms-5 maroon">c. "BS Biology"</p>
          </Row>
          <hr/>
          {/* <Row> */}
            <button
              className="g-btn me-3 right"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => setRedirect("back")}
            > BACK
            </button>
          {/* </Row> */}
        </Container>
        <ModalPopUp
          type="upload"
          show={showModal}
          handleClose={() => setShowModal(false)}
          handleOnClick={handleOnSubmit}
          handleUpload={add}
          handleSelect={uploadReady}
        />
      </div>
    </div>
  );
}

BatchEntry.propTypes = {
  setIsChecked: PropTypes.any,
  isChecked: PropTypes.bool,
};

export default BatchEntry;
