import React from "react";
import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { addDegreeProgram, updateDegreeProgram, deleteDegreeProgram, getDegreeProgram } from "../../APICalls/DegreeProgram.js/DegreeProgramApi";
import { getDepartment } from "../../Helpers/Utils/Common";
import { refreshPage, removeSession } from "../../Helpers/Utils/Common";
import { getUser, getToken } from "../../Helpers/Utils/Common";
import { postAPICall } from "../../APICalls/axiosMethodCalls";
import { validateAddCourse } from "../../Helpers/Validation/Course";
import Navbar from "../../Components/Navbar/Navbar";
import ModalPopUp from "../../Components/Modal/Modal";
import Table from "../../Components/Table/Table";

function CourseManager() {
    const [inactive, setInactive] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [isClicked, setIsClicked] = useState(false);
    const [allCourses, setAllCourses] = useState([]);

    const [courseData, setCourseData] = useState({
        course_name: '',
        department_id: ''
    })

    const [isError, setIsError] = useState({
        course_name: false,
        department_id: false
    })

    const [courseDataToEdit, setCourseDataToEdit] = useState({})
    const [courseId, setCourseId] = useState()


    const handleChange = (e) => {
      const { name, value } = e.target;
      setCourseData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const handleSelectChange = (e) => {
      const { id, name, value } = e.target;
      setCourseId(id);
      if (value === "update") {
        editCourse(id);
      }
      if (value === "delete") {
        deleteCourseById(id);
      }
  };

  const handleUpdateChange = (e) => {
    setCourseDataToEdit({
      ...courseDataToEdit,
      [e.target.name]: e.target.value,
    });
  };

  async function editCourse(id) {
    const response = await getDegreeProgram(id, '');
    if (response.data) {
      const res = response.data[0]
      res.course_name = res.degree_program;
      setCourseDataToEdit(res);
      setShowModalEdit(true);
    }
  }

   async function deleteCourseById(id) {
    const response = await getDegreeProgram(id, '');
    if (response.data) {
      setCourseDataToEdit(response.data[0]);
      setShowModalDelete(true);
    }
  }

  async function add() {
    if (validateAddCourse(courseData, setIsError) === true && isClicked === false) {
        setIsClicked(true)
        const response = await addDegreeProgram(courseData); 
        if (response.data && response.data.status === 200) {
          toast.success(response.data.data.response.toUpperCase());
          setTimeout(function () {
            refreshPage();
          }, 1500);
        } else {
          setIsClicked(false)
          toast.error(response.error.data.messages.error.toUpperCase());
        }
      } 
  }

  async function update() {
    if (validateAddCourse(courseDataToEdit, setIsError) && !isClicked) {
      setIsClicked(true);
      const response = await updateDegreeProgram(courseId, courseDataToEdit);
      if (response.data) {
        toast.success(response.data.data.response.toUpperCase());
        setTimeout(function () {
          refreshPage();
        }, 500);
      } else {
        setIsClicked(false);
        toast.error(response.error.data.messages.error.toUpperCase());
      }
    }
  }

  async function deleteCourse() {
    const response = await deleteDegreeProgram(courseId)
    if (response.data?.status === 200) {
      toast.success(response.data.data.response.toUpperCase());
    } else {
      toast.error(response.error.data.response.toUpperCase());
    }
    setShowModalDelete(false);
    setTimeout(function () {
      refreshPage();
    }, 1500);
  }


  // Get all degree programs or courses
  React.useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await postAPICall (process.env.REACT_APP_LINK + "degree_programs/get", {
            requester: await getUser(),
            token: await getToken(),
            department_id: await getDepartment()
            })
            var departments = response.data.data
            var selected = departments.map((data) => {
                return {value: data.id, label: data.degree_program, department: data.department}
            })
            setAllCourses(selected);
            setIsReady(true);
        } catch (error) {
            if (error.response.status === 401) {
                removeSession()
                refreshPage()
            }
            setAllCourses([]);
            setIsReady(true);
        }
    };
    fetchData();
  }, []);

  return (
    <div className="page">
      <ToastContainer/>
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
      />
      <div className="login-container center">
        <div className={`container ${inactive ? "inactive" : "active"}`}>
          <div className="form-cont .center-form-cont pt-4">
            <div className="container ms-1 pt-0">
              <Row className="row-height">
                <Col className='col-10'><h1 className="title left">MANAGE COURSE</h1></Col>
                <Col className='col-2 right'>
                    <div className="pt-2">
                    <button
                        className="right maroon-btn"
                        onClick={() => setShowModal(true)}
                    >
                        ADD COURSE
                    </button>
                    </div>
                </Col>
              </Row>
              <div className="row row-form mb-4">
        
              <Table
                  type={"manage_courses"}
                  tableData={allCourses}
                  clickable={true}
                  headingColumns={["DEGREE PROGRAMS", "DEPARTMENT", "ACTIONS"]}
                  onSelectChange={handleSelectChange}
                  useLoader={true}
                  isReady={isReady}
                />
              </div>
            </div>
          </div>
        </div>
        <ModalPopUp
            type="add-course"
            show={showModal}
            handleClose={() => setShowModal(false)}
            handleAdd={add}
            data={courseData}
            setData={handleChange}
            isError={isError}
            errorMessages={errorMessages}
        />
        <ModalPopUp
          type="edit-course"
          show={showModalEdit}
          handleClose={() => setShowModalEdit(false)}
          data={courseDataToEdit}
          setData={handleUpdateChange}
          handleUpdate={update}
          isError={isError}
          errorMessages={errorMessages}
        />
        <ModalPopUp
          type="delete-course"
          show={showModalDelete}
          handleClose={() => setShowModalDelete(false)}
          handleDelete={deleteCourse}
          data={courseDataToEdit}
          setData={handleUpdateChange}
        />
      </div>
    </div>
  );
}

export default CourseManager;
