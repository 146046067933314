import { isValidDateValue } from "@testing-library/user-event/dist/utils";
import { handleValidationChange } from "../CommonValidation";

export const validateAddUser = (data, setIsError) => {
    var isValid = true

    if (data.role_id === "") {
      handleValidationChange("role_id", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("role_id", false, setIsError);
    }

    if (data.first_name === "") {
      handleValidationChange("first_name", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("first_name", false, setIsError);
    }

    if (data.last_name === "") {
      handleValidationChange("last_name", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("last_name", false, setIsError);
    }

    if (data.email === "") {
      handleValidationChange("email", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("email", false, setIsError);
    }


    return isValid
}