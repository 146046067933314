import React, { useState } from "react";

import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/Table/Table";

//assets
import dashhead from "../../Assets/Images/Dashboard/dashboard_head.png";
import userpic from "../../Assets/Images/Dashboard/user_profile.png";
import gradcap from "../../Assets/Images/Dashboard/GraduationCap.png";
import residence from "../../Assets/Images/Dashboard/HomePage.png";
import bag from "../../Assets/Images/Dashboard/Briefcase.png";
import hand from "../../Assets/Images/Dashboard/Handshake.png";

import "./StudentProfile.css";


function StudentProfile({ setIsChecked, isChecked }) {
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const membershipData = [
    {
      organization: "Student Council",
      type: "Academic",
      period: "1 YR",
      position: "Consultant",
    },
    {
      organization: "World Health Organization",
      type: "Academic",
      period: "1 YR",
      position: "Consultant",
    },
    {
      organization: "World Health Organization",
      type: "Academic",
      period: "1 YR",
      position: "Consultant",
    },
  ];

  const employmentData = [
    {
      company: "MYT Softdev Solutions",
      position: "frontend developer",
      start_date: "July 2022",
      end_date: "July 2024",
    },
    {
      company: "MYT Softdev Solutions",
      position: "frontend developer",
      start_date: "July 2022",
      end_date: "July 2024",
    },
    {
      company: "MYT Softdev Solutions",
      position: "frontend developer",
      start_date: "July 2022",
      end_date: "July 2024",
    },
  ];

  const academicData = [
    {
      institution: "University of Cebu",
      degree: "BS Computer Science",
      start_date: "July 2019",
      end_date: "August 2023",
    },
  ];

  const familyMemberData = [
    {
      name: "Juan Dela Cruz Sr.",
      relation: "Father",
      degree: "BS Computer Science",
      up_campus: "UP Cebu",
      address: "California, USA",
      email: "jdcruz@ gmail.com",
      tel_n0: "09999999999",
    },
  ];

  React.useEffect(() => {
    let loadingTime = setTimeout(() => {
      setIsReady(true);
    }, 5000);
  }, []);

  return (
    <div className="page">
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />

      <div className="ml-100 pt-0">
        <div className="container pt-0 ml-0 pl-0 pr-0 ml--50">
          {/* FIRST ROW */}
          <div className="row mb-4 pl-0 pr-0">
            <div className="col w-180">
              <img className="w" src={dashhead} alt="dashboard-header" />
            </div>
            <div className="container pt-0 dash-container form-control top-br">
              <div className="row center h-150">
                <div className="col-3 user-icon">
                  <img
                    className="dashboard-user-icon"
                    src={userpic}
                    alt="user-pic"
                  />
                </div>
                <div className="col-6">
                  <h1 className="title center p-50">DR. JUAN DELA CRUZ JR.</h1>
                </div>
              </div>
              <hr></hr>
              <div className="row row-form row-height">
                <div className="col-6 p-2">
                  <h3 className="rad dash-info title p-2">
                    PERSONAL INFORMATION
                  </h3>
                </div>
              </div>
              <div className="row row-form p-40 mt-3">
                <div className="col-3 rad dark-grey p-20">
                  <p className="input-subtitle-grey">CONTACT NUMBER</p>
                  <p className="input-subtitle">09933556565</p>

                  <p className="input-subtitle-grey pt-3">EMAIL ADDRESS</p>
                  <p className="input-subtitle">juan@up.edu.ph</p>

                  <p className="input-subtitle-grey pt-3">
                    SOCIAL MEDIA ACCOUNTS
                  </p>
                  <p className="input-subtitle">@itsmejuan</p>
                  <p className="input-subtitle">@therealjuan</p>

                  <p className="input-subtitle-grey pt-3">PRONOUNS</p>
                  <p className="input-subtitle">He/Him</p>
                </div>
                <div className="col ml-10">
                  <div>
                    <div className="row align-items-center">
                      <img
                        className="col-2 dash-info-icon"
                        src={gradcap}
                        alt="grad cap"
                      />
                      <h3 className="col dash-sub-info p-20">
                        EDUCATIONAL BACKGROUND
                      </h3>
                    </div>
                    <div className="row rad p-20 ml-10 light-grey ml-0 w-98">
                      <div className="row">
                        <div className="col-4">
                          <p className="input-subtitle-grey pt-3">
                            COLLEGE/SHOOL
                          </p>
                          <p className="input-subtitle">College of Science</p>
                        </div>
                        <div className="col-4">
                          <p className="input-subtitle-grey pt-3">
                            DEGREE/CERTIFICATE
                          </p>
                          <p className="input-subtitle">BS Computer Science</p>
                        </div>
                        <div className="col-4">
                          <p className="input-subtitle-grey pt-3">
                            STUDENT NUMBER
                          </p>
                          <p className="input-subtitle">2019-123456</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <p className="input-subtitle-grey pt-3">
                            LATIN AWARD/DISTINCTION
                          </p>
                          <p className="input-subtitle">Summa Cum Laude</p>
                        </div>
                        <div className="col-4">
                          <p className="input-subtitle-grey pt-3">
                            SEMESTER GRADUATED
                          </p>
                          <p className="input-subtitle">Second Semester</p>
                        </div>
                        <div className="col-4">
                          <p className="input-subtitle-grey pt-3">
                            ACADEMIC YEAR
                          </p>
                          <p className="input-subtitle">2019</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <p className="input-subtitle-grey pt-3">
                            YEAR CONFERRED
                          </p>
                          <p className="input-subtitle">2023</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row-form p-40 pt-0">
                <div className="col-3"></div>
                <div className="col">
                  <div className="row align-items-center">
                    <img
                      className="col-2 dash-info-icon"
                      src={residence}
                      alt="grad cap"
                    />
                    <h3 className="col dash-sub-info p-20">
                      RESIDENTIAL INFORMATION
                    </h3>
                  </div>
                  <div className="row rad p-20 ml-10 light-grey ml-0 w-98">
                    <div className="row">
                      <div className="col">
                        <p className="input-subtitle-grey pt-3">
                          PRESENT ADDRESS
                        </p>
                        <p className="input-subtitle">
                          Sunser Dr., Brgy Lahug, Cebu City, Cebu
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <p className="input-subtitle-grey pt-3">COUNTRY</p>
                        <p className="input-subtitle">Philippines</p>
                      </div>
                      <div className="col-3">
                        <p className="input-subtitle-grey pt-3">
                          STATE/PROVINCE
                        </p>
                        <p className="input-subtitle">Cebu</p>
                      </div>
                      <div className="col-3">
                        <p className="input-subtitle-grey pt-3">CITY</p>
                        <p className="input-subtitle">Cebu</p>
                      </div>
                      <div className="col-3">
                        <p className="input-subtitle-grey pt-3">POSTAL CODE</p>
                        <p className="input-subtitle">6000</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <p className="input-subtitle-grey pt-3">
                          PERMANENT ADDRESS
                        </p>
                        <p className="input-subtitle">
                          Sunser Dr., Brgy Lahug, Cebu City, Cebu
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <p className="input-subtitle-grey pt-3">COUNTRY</p>
                        <p className="input-subtitle">Philippines</p>
                      </div>
                      <div className="col-3">
                        <p className="input-subtitle-grey pt-3">
                          STATE/PROVINCE
                        </p>
                        <p className="input-subtitle">Cebu</p>
                      </div>
                      <div className="col-3">
                        <p className="input-subtitle-grey pt-3">CITY</p>
                        <p className="input-subtitle">Cebu</p>
                      </div>
                      <div className="col-3">
                        <p className="input-subtitle-grey pt-3">POSTAL CODE</p>
                        <p className="input-subtitle">6000</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row grey mt-3"></div>
              <div className="row row-form row-height">
                <div className="col-6 pt-3">
                  <h3 className="rad dash-info title">
                    EMPLOYMENT INFORMATION
                  </h3>
                </div>
              </div>
              <div className="row row-form p-40 pt-0">
                <div className="col">
                  <div className="row align-items-center">
                    <img
                      className="col-2 dash-info-icon"
                      src={bag}
                      alt="grad cap"
                    />
                    <h3 className="col dash-sub-info p-20">
                      CURRENT OCCUPATION
                    </h3>
                  </div>
                  <div className="row rad p-20 ml-10 light-grey ml-0 w-98">
                    <div className="row">
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          TYPE OF INSTITUTION
                        </p>
                        <p className="input-subtitle">Software Engineering</p>
                      </div>
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">SECTOR</p>
                        <p className="input-subtitle">BS Computer Science</p>
                      </div>
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          COMPANY/INSTITUTION
                        </p>
                        <p className="input-subtitle">
                          Senior Software Engineer
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          POSITION/RANK
                        </p>
                        <p className="input-subtitle">
                          Senior Software Engineer
                        </p>
                      </div>
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          SPECIALIZATION
                        </p>
                        <p className="input-subtitle">Web Development</p>
                      </div>
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          MONTHLY SALARY RATE
                        </p>
                        <p className="input-subtitle">More than P100,000</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          TYPE OF EMPLOYMENT
                        </p>
                        <p className="input-subtitle">Web Development</p>
                      </div>
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          EXTENT OF EMPLOYMENT
                        </p>
                        <p className="input-subtitle">Lorem ipsum</p>
                      </div>
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          JOB DESCRIPTION
                        </p>
                        <p className="input-subtitle">Lorem ipsum</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4"></div>
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          PERIOD OF EMPLOYMENT
                        </p>
                        <p className="input-subtitle">
                          December 2019 - Present
                        </p>
                      </div>
                      <div className="col-4"></div>
                    </div>
                  </div>
                  <div className="row rad p-20 ml-10 ml-0">
                    <h2 className="title left">HISTORY</h2>
                  </div>
                  <div className="row rad mt-3 ml-0 w-98">
                    <Table
                      type={"student_profile"}
                      tableData={employmentData}
                      clickable={false}
                      headingColumns={[
                        "COMPANY/INSTITUTION",
                        "POSITION/RANK",
                        "START DATE",
                        "END DATE",
                      ]}
                      useLoader={true}
                      isReady={isReady}
                    />
                  </div>
                </div>
              </div>
              <div className="row grey mt-3"></div>
              <div className="row row-form row-height">
                <div className="col-6 pt-3">
                  <h3 className="rad dash-info title">ACADEMIC INFORMATION</h3>
                </div>
              </div>
              <div className="row p-40">
                <div className="row rad mt-3 ml-0 w-98">
                  <Table
                    type={"student_profile"}
                    tableData={academicData}
                    clickable={false}
                    headingColumns={[
                      "COMPANY/INSTITUTION",
                      "POSITION/RANK",
                      "START DATE",
                      "END DATE",
                    ]}
                    useLoader={true}
                    isReady={isReady}
                  />
                </div>
              </div>
              <div className="row grey mt-3"></div>
              <div className="row row-form row-height">
                <div className="col-6 pt-3">
                  <h3 className="rad dash-info title membership">
                    MEMBERSHIP IN ORGANIZATIONS
                  </h3>
                </div>
              </div>
              <div className="row p-40 pt-0 ">
                <div className="row rad mt-3 ml-0 w-98">
                  <Table
                    type={"student_profile"}
                    tableData={membershipData}
                    clickable={false}
                    headingColumns={[
                      "NAME OF ORGANIZATION",
                      "TYPE",
                      "PERIOD OF MEMBERSHIP",
                      "POSITION",
                    ]}
                    useLoader={true}
                    isReady={isReady}
                  />
                </div>
              </div>
              <div className="row grey mt-3"></div>
              <div className="row row-form row-height">
                <div className="col-6 pt-3">
                  <h3 className="rad dash-info title">ALUMNI ASSOCIASION</h3>
                </div>
              </div>
              <div className="row row-form p-40 pt-0">
                <div className="col">
                  <div className="row align-items-center">
                    <img
                      className="col-2 dash-info-icon"
                      src={hand}
                      alt="handshake"
                    />
                    <h3 className="col dash-sub-info p-20">MEMBERSHIP</h3>
                  </div>
                  <div className="row rad p-20 ml-10 light-grey ml-0 w-98">
                    <div className="row">
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">CHAPTER NAME</p>
                        <p className="input-subtitle">UP Cebu</p>
                      </div>
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          PERIOD OF MEMBERSHIP
                        </p>
                        <p className="input-subtitle">March 2022 - PRESENT</p>
                      </div>
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          HIGHEST POSITION
                        </p>
                        <p className="input-subtitle">Chairperson</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row grey mt-3"></div>
              <div className="row row-form row-height">
                <div className="col-6 pt-3">
                  <h3 className="rad dash-info title membership">
                    ALUMNI FAMILY MEMBERS
                  </h3>
                </div>
              </div>
              <div className="row p-40">
                <div className="row rad mt-3 ml-0 w-98">
                  <Table
                    type={"student_profile"}
                    tableData={familyMemberData}
                    clickable={false}
                    headingColumns={[
                      "NAME",
                      "RELATION",
                      "DEGREE",
                      "UP CAMPUS",
                      "ADDRESS",
                      "EMAIL",
                      "TEL NO./MOBILE NO.",
                    ]}
                    useLoader={true}
                    isReady={isReady}
                  />
                </div>
              </div>
              <div className="row grey mt-3"></div>
              <div className="row pt-3 mb-4 ">
                <div className="col-6"></div>
                <div className="col right">
                  <div className="row right ml-20">
                    <div className="col-5 ml-10">
                      <button
                        className="btn-yellow-orange btn-rad right w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        {" "}
                        PRINT{" "}
                      </button>
                    </div>
                    <div className="col-5 ml-10">
                      <button
                        className="btn-green btn-rad right w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        {" "}
                        CLOSE{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentProfile;
