import React, { useState } from "react";
import { loginUser } from "../../APICalls/authApi";
import { refreshPage } from "../../Helpers/Utils/Common";
import { toastStyle } from "../../Helpers/Constants/StyleConstants";
import ModalPopUp from "../../Components/Modal/Modal";
import { useNavigate } from "react-router-dom";
import {Row} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "./Login.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import Logo3 from "../../Assets/Images/Login/logo_3.png";
import upCebuLogo from "../../Assets/Images/Login/up-cebu-logo.png";
import csLogo from "../../Assets/Images/Login/cs-logo.png";
import { useEffect } from "react";

function Login() {
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigateto = useNavigate();
  const clientId = "870220969586-c5l9qnq0c1b33i3pr91320kmv7pcbe20.apps.googleusercontent.com";

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const google = window.google;

  const handleCredentialResponse = async (response) => {
    setIsLoading(true)

    const res = await loginUser(response.credential, "google_login");

    if (res.data.status !== 200) {
      toast.error(res.data.messages.error.toUpperCase())
      setIsLoading(false);
    } else {
      toast.success("Successful Login!", {
        style: toastStyle(),
      });

      setTimeout(() => {
        localStorage.setItem("user", JSON.stringify(res.data.data.id));
        localStorage.setItem(
          "name",
          JSON.stringify(
            res.data.data.first_name + " " + res.data.data.last_name
          )
        );
        localStorage.setItem("token", JSON.stringify(res.data.data.token));
        localStorage.setItem("id", JSON.stringify(res.data.data.id));
        localStorage.setItem("role_id", JSON.stringify(res.data.data.role_id));
        // localStorage.setItem("role_id", JSON.stringify(2));
        localStorage.setItem("role", JSON.stringify(res.data.data.role));
        localStorage.setItem(
          "department_id",
          JSON.stringify(res.data.data.department_id)
        );
        localStorage.setItem(
          "department",
          JSON.stringify(res.data.data.department)
        );

        setIsLoading(false);

        if (
          res.data.data.role_id === '1' ||
          res.data.data.role_id === '4' ||
          res.data.data.role_id === '5' ||
          res.data.data.role_id === '7' ||
          res.data.data.role_id === '8' ||
          res.data.data.role_id === '10'
        ) {
          navigateto("/dashboard");
          refreshPage();
        } else if (res.data.data.role_id === '2') {
          navigateto("/dashboard-pio");
          refreshPage();
        } else if (res.data.data.role_id === '3') {
          navigateto("/dashboard-osa");
          refreshPage();
        } else if (res.data.data.role_id === '6') {
          navigateto("/dashboard-itc");
          refreshPage();
        } else {
          refreshPage();
        }
      }, 1000);
    }
  };

  useEffect (() => {
    google.accounts.id.initialize({
      client_id: clientId,
      callback: handleCredentialResponse,
    });

    google.accounts.id.renderButton(document.getElementById("buttonDiv"), {
      theme: "outline",
      size: "large",
    });
  });

  return (
    <div>
       <ToastContainer />
      <div className="login-container">
        <div className="login-header">
          <img src={Logo3} className="login-logo ms-3" alt=""/>
          <img src={upCebuLogo} className="login-logo" alt=""/>
          <img src={csLogo} className="login-logo" alt=""/>
        </div>
        <div className="mt-5 header-label-cont d-flex justify-content-center">
          <h1 className="login-header-label">
            College of Science Alumni Tracker
          </h1>
        </div>
        <div className="mt-5 d-flex justify-content-center">
            <Row><div className="" id="buttonDiv"></div></Row>
        </div>
        <Row>
            {isLoading ? (
              <div className="spinner-container center">
                <span className="loading-spinner"></span>
              </div>
            ) : (
              <div></div>
            )}
          </Row>
        <ModalPopUp
          type="reset-password"
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      </div>
    </div>
  );
}

export default Login;
