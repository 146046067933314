import Navbar from "../../Components/Navbar/Navbar";
import React, { useState } from "react";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import Profile from "../../Assets/Images/Dashboard/user_profile.png";
import {
  getAdminUser,
  getNewLogins,
  getNewRegistrants,
} from "../../APICalls/ManageUserApi";
import { getScholarshipDisplay } from "../../APICalls/ScholarshipApi/ScholarshipApi";
import { getProjectsDisplay } from "../../APICalls/ProjectsApi/ProjectsApi";
import { Row, Col } from "react-bootstrap";

function DashboardOsa({ setIsChecked, isChecked }) {
  const [inactive, setInactive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [redirect, setRedirect] = useState("");
  const [adminData, setAdminData] = useState({});
  const [scholarshipData, setScholarshipData] = useState([3]);
  const [projectData, setProjectData] = useState([]);
  const [newLoginData, setNewLoginData] = useState([]);
  const [newLogin, setNewLogin] = useState([]);
  const id = useParams();
  const navigateTo = useNavigate()


  async function OsaProfile() {
    const response = await getAdminUser(id);
    if (response.data) {
      setAdminData(response.data[0]);
    }
  }

  async function Scholarship() {
    const response = await getScholarshipDisplay();
    if (response.data) {
      var arr = response.data.filter((data, key) => key <= 2);
      setScholarshipData(arr);
    }
  }

  async function fetchProjects() {
    const response = await getProjectsDisplay();
    if (response.data) {
      var arr = response.data.filter((data, key) => key <= 2);
      setProjectData(arr);
    }
  }

  async function fetchNewLogins() {
    const response = await getNewLogins();
    if (response.data.todays_logins) {
      setNewLoginData(response.data.todays_logins[0]);
    }
  }

  async function fetchNewRegistrants() {
    const response = await getNewRegistrants();
    if (response.data[0]) {
      setNewLogin(response.data[0]);
    }
  }

  React.useEffect(() => {
    fetchNewLogins();
    fetchNewRegistrants();
    fetchProjects();
  }, []);

  React.useEffect(() => {
    OsaProfile();
  }, []);

  React.useEffect(() => {
    Scholarship();
  }, []);

  React.useEffect(() => {
    if (redirect === "see_all") {
      // return <Navigate to="/projects" />;
      navigateTo('/projects')
    } else if (redirect === "see_all_scholar") {
      // return <Navigate to="/scholarship" />;
      navigateTo('/scholarship')
    }
  }, [redirect])

  return (
    <div className="page">
      <Row>
        <Col>
          <Navbar
            onCollapse={(e) => {
              setInactive(e);
            }}
            active={"DASHBOARD"}
            setIsChecked={setIsChecked}
            isChecked={isChecked}
          />
        </Col>
        <div className="login-container">
          <div className="form-cont mt-4">
            <div className="itc-db-container ms-2 pt-0">
              <Row className="section-container ms-2 mt-3">
                <Col className="col-7.5 header-cont me-3">
                  <Row>
                    <Col className="ms-3 col-2 center profile-cont">
                      <img className="pio-profile" src={Profile}></img>
                    </Col>
                    <Col className="ms-3 pio-details pt-2 left">
                      <Row>
                        <label className="admin-name size mt-3 mb-0">
                          {adminData.first_name + " " + adminData.last_name}
                          {/* <label className="admin-name size mt-3 mb-0">
                            {adminData.last_name}{" "}
                          </label> */}
                        </label>
                      </Row>
                      <Row>
                        <h2 className="pio-campus-name">{adminData.role}</h2>
                      </Row>
                      <Row>
                        <p className="pio-campus-name">UP CEBU</p>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col className="col-2 me-3 input-shadow yellow-box">
                  <Row>
                    <h1 className="pt-2 count-label">
                      {newLogin.new_registrants}
                    </h1>
                  </Row>
                  <Row>
                    <label className="subtitlex ">TODAY’S </label>
                  </Row>
                  <Row>
                    <p className="subtitlex1">REGISTRANTS</p>
                  </Row>
                </Col>
                <Col className="col-2 me-0 input-shadow green-box">
                  <Row>
                    <h1 className="pt-3 count-label">
                      {newLoginData.todays_logins}
                    </h1>
                  </Row>
                  <Row>
                    {" "}
                    <label className="subtitlex">TODAY’S </label>
                  </Row>
                  <Row>
                    <p className="subtitlex1">LOGINS</p>
                  </Row>
                </Col>
              </Row>

              <Row className="section-container gray-background mt-4 ms-2">
                <Row className="section-container">
                  <Row className="pt-2">
                    <div className="col-6 ">
                      <h3 className="left admin-name2 ms-3 mt-2">PROJECTS</h3>
                    </div>
                    <div className="col-6 right">
                      <button
                        className="see-all-btn ms-2 right"
                        onClick={() => setRedirect("see_all")}
                      >
                        SEE ALL
                      </button>
                    </div>
                  </Row>
                  <Row className="section-body">
                    {projectData.map((data, key) => {
                      return (
                        <div>
                          <Row className="pt-3">
                            <Col className="col-2 left">
                              <label className="subtitle">{data.name}</label>
                            </Col>
                            <Col className="col-2 left">
                              <label type="date" className="subtitle"></label>
                              {data.date}
                            </Col>
                            <Col className="col-4 left">
                              <label className="subtitle">
                                {data.description}
                              </label>
                            </Col>
                            <Col xs={2} className="section-btns left">
                              <button className="left button-maroon me-1">
                                {data.tag_1}
                              </button>
                              {
                                data.tag_2 ? (
                                  <button className="left mt-1 me-1 button-yellow ">
                                    {data.tag_2}
                                  </button>
                                ) : (
                                  <div></div>
                                )
                              }
                            </Col>
                            <Col xs={2} className="section-btns left">
                              {
                                data.tag_3 ? (
                                  <button className="left me-1 button-green">
                                    {data.tag_3}
                                  </button>
                                ) : (
                                  <div></div>
                                )
                              }
                              {
                                data.tag_4 ? (
                                  <button className="left mt-1 me-1 button-maroon">
                                    {data.tag_4}
                                  </button>
                                ) : (
                                  <div></div>
                                )
                              }
                              {
                                data.tag_5 ? (
                                  <button className="left me-1 mt-1 button-green">
                                    {data.tag_5}
                                  </button>
                                ) : (
                                  <div></div>
                                )
                              }
                            </Col>
                          </Row>
                          <hr></hr>
                        </div>
                      );
                    })}
                  </Row>
                </Row>
              </Row>

              <Row className="section-container gray-background mt-4 ms-2">
                <Row className="section-container">
                  <Row className="pt-2">
                    <div className="col-6 ">
                      <h3 className="left admin-name2 ms-3 mt-2">
                        SCHOLARSHIPS
                      </h3>
                    </div>
                    <div className="col-6 right">
                      <button
                        className="see-all-btn ms-2 right"
                        onClick={() => setRedirect("see_all_scholar")}
                      >
                        SEE ALL
                      </button>
                    </div>
                  </Row>
                  <Row className="section-body">
                    {scholarshipData.map((data, key) => {
                      return (
                        <div>
                          <Row className="pt-3">
                            <Col className="col-3 left">
                              <label className="subtitle">{data.name}</label>
                            </Col>
                            <Col className="col-3 left">
                              <label type="date" className="subtitle"></label>
                              {data.target_date}
                            </Col>
                            <Col className="col-3 left">
                              <label className="subtitle">
                                {data.contact_person}
                              </label>
                            </Col>
                            <Col className="col-3 left">
                              <label className="subtitle">{data.budget}</label>
                            </Col>
                          </Row>
                          <hr></hr>
                        </div>
                      );
                    })}
                  </Row>
                </Row>
              </Row>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
}

export default DashboardOsa;
