import Navbar from "../../Components/Navbar/Navbar";
import React, { useState } from "react";
import "./Announcement.css";
import Table from "../../Components/Table/Table";
import ModalPopUp from "../../Components/Modal/Modal";
import { ToastContainer, toast } from "react-toastify";
import {
  createAnnouncement,
  getAnnouncement,
  updateAnnouncement,
  deleteAnnouncement,
  getAnnouncementDisplay,
} from "../../APICalls/Announcement/Announcement";
import { refreshPage } from "../../Helpers/Utils/Common";
import { CSVLink } from "react-csv";
import { getRole } from "../../Helpers/Utils/Common";
import { Row, Col } from "react-bootstrap";
import { getDepartment } from "../../Helpers/Utils/Common";
import { validateAddAnnouncement } from "../../Helpers/Validation/Announcement";

function Announcement({ setIsChecked, isChecked }) {
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [id, setId] = useState();
  const userRole = getRole();
  const [readyExcel, setReadyExcel] = useState(false);
  const [announcementHistoryData, setAnnouncementHistoryData] = useState([]);
  const [announcementDetails, setAnnouncementDetails] = useState({});
  const [isClicked, setIsClicked] = useState(false)
  const [announcementData, setAnnouncementData] = useState({
    announcement_id: id,
    date_posted: "",
    department: "",
    batch: "",
    subject: "",
    content: "",
    is_emailed: "",
  });

  const [isError, setIsError] = useState({
    date_posted: false,
    department: false,
    batch: false,
    subject: false,
    content: false,
  });

  const headers = [
    { label: "DATE POSTED", key: "date_posted" },
    { label: "ANNOUNCEMENT", key: "content" },
    { label: "DEGREE", key: "department" },
    { label: "ADDED ON", key: "added_on" },
  ];

  const [filterDate, setFilterDate] = useState({
    from: "",
    to: "",
  });

  async function fetchAnnouncement(filterDate) {
    const department_id = getDepartment();
    setAnnouncementHistoryData([]);
    const response = await getAnnouncement(department_id, filterDate);
    if (response.data) {
      setAnnouncementHistoryData(response.data);
      setIsReady(true);
      setReadyExcel(true);
    }
  }

  async function editAnnouncement(id) {
    const response = await getAnnouncementDisplay(id);
    if (response.data) {
      const res = response.data
      const date = new Date(res.date_posted);
      res.date_posted = date.toISOString().split("T")[0];
      setAnnouncementDetails(res);
      setShowModalEdit(true);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAnnouncementData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdateChange = (e) => {
    setAnnouncementDetails({
      ...announcementDetails,
      [e.target.name]: e.target.value,
    });
  };

  async function add() {
    if (
      validateAddAnnouncement(announcementData, setIsError) && !isClicked) {
      setIsClicked(true);
      const response = await createAnnouncement(announcementData);
      if (response.data && response.data.status === 200) {
        toast.success(response.data.data.response.toUpperCase());
        setTimeout(function () {
          refreshPage();
        }, 500);
      } else {
        setIsClicked(false);
        toast.error(response.error.data.messages.error.toUpperCase());
      }
    }
  }

  async function update() {
    if (validateAddAnnouncement(announcementDetails, setIsError) && !isClicked) {
      setIsClicked(true)
       const response = await updateAnnouncement(id, announcementDetails);
        if (response.data && response.data.status === 200) {
          toast.success(response.data.data.response.toUpperCase());
          setTimeout(function () {
            refreshPage();
          }, 500);
        } else {
          setIsClicked(false);
          toast.error(response.error.data.messages.error.toUpperCase());
        }
    }
  }

  async function del() {
    const response = await deleteAnnouncement(id);
    if (response.data) {
      toast.success(response.data.data.response.toUpperCase());
    } else {
      toast.error(response.error.data.response.toUpperCase());
    }
    setShowModal(false);
    setTimeout(function () {
      refreshPage();
    }, 1500);
  }

  React.useEffect(() => {
    let loadingTime = setTimeout(() => {
      setIsReady(true);
    }, 5000);
  }, []);

  React.useEffect(() => {
    fetchAnnouncement(filterDate);
  }, [filterDate]);

  const handleSelectChange = (e) => {
    const { id, name, value } = e.target;
    setId(id);
    if (value === "update") {
      editAnnouncement(id);
    } else if (value === "delete") {
      setShowModalDelete(true);
    }
  };

  function handleToExcel() {
    if (readyExcel) {
      return (
        <CSVLink
          filename={"Announcement.csv"}
          data={announcementHistoryData}
          headers={headers}
        >
          <button className="width-fill height-fill orange-btn add-btn">
            DOWNLOAD CSV
          </button>
        </CSVLink>
      );
    } else {
      return (
        <div className="col">
          <button className="width-fill height-fill orange-btn add-btn">
            DOWNLOAD CSV
          </button>
        </div>
      );
    }
  }

    return (
        <div className="page">
          <ToastContainer/>
            <Navbar
            onCollapse={(e) => {
                setInactive(e);
            }}
            active={"DASHBOARD"}
            setIsChecked={setIsChecked}
            isChecked={isChecked}
            />
            <div className=" login-container">
              <div className={`container ${inactive ? "inactive" : "active"}`}>    
                <div className="form-cont pt-4">
                  <div className="container ms-1 pt-0">
              
              <Row className="">
                <Col className="" xs={2}>
                  <h1 className="title left">ANNOUNCEMENTS</h1>
                </Col>
                <Col>
                <div className="d-flex justify-content-end">
                <Col xs={2} className="me-1">
                  <span className="input-subtitle  d-flex justify-content-start">From:</span>
                  {/* <br /> */}
                  <input
                    type="date"
                    name="date_from"
                    className="form-control input-subtitle input-shadow input-green-bg"
                    id="date_from"
                    aria-describedby="date_from"
                    onChange={(e) =>
                      setFilterDate({ ...filterDate, from: e.target.value })
                    }
                  />
                </Col>
                <Col xs={2} className="me-1">
                  <span className="input-subtitle  d-flex justify-content-start">To:</span>
                  {/* <br /> */}
                  <input
                    type="date"
                    name="date_to"
                    className="form-control input-subtitle input-shadow input-green-bg"
                    id="date_to"
                    aria-describedby="date_to"
                    onChange={(e) =>
                      setFilterDate({ ...filterDate, to: e.target.value })
                    }
                  />
                </Col>
                {
                  userRole == '1' && (
                    <Col xs={2} className="me-1 pt-4">
                      <CSVLink
                          data={announcementHistoryData}
                          headers={headers}
                          filename={"Announcements"}
                        >
                          <button className="width-fill orange-btn">
                            DOWNLOAD CSV
                          </button>
                        </CSVLink>
                    </Col>
                  )
                }
                {
                  (userRole !== "3" && userRole !== "8"&& userRole!=="10") && (
                    <Col xs={2.5} className="pt-4">
                      <button
                        className="maroon-btn"
                        onClick={() => setShowModal(true)}
                      >
                        ADD ANNOUNCEMENT
                      </button>
                  </Col>
                  )
                }
                </div></Col>
              </Row>

              <Row className='mt-3 mb-4 announcement-wrapper'>
                <Table
                  type={"announcement"}
                  tableData={announcementHistoryData}
                  clickable={true}
                  headingColumns={(userRole!=="3"&&userRole!=="8")?[
                      "DATE POSTED",
                      "ANNOUNCEMENT",
                      "DEGREE",
                      "ADDED ON",
                      "ACTION",
                  ]:[
                      "DATE POSTED",
                      "ANNOUNCEMENT",
                      "DEGREE",
                      "ADDED ON",
                  ]}
                  onSelectChange={handleSelectChange}
                  useLoader={true}
                  isReady={isReady}
                />
              </Row>
            </div>
        </div>
    </div>
       
            <ModalPopUp
                type="add-announcement"
                show={showModal}
                handleClose={() => setShowModal(false)}
                handleAdd={add}
                data={announcementData}
                setData={handleChange}
                isError={isError}
            />

            <ModalPopUp
                type="edit-announcement"
                show={showModalEdit}
                handleClose={() => setShowModalEdit(false)}
                data={announcementDetails}
                setData={handleUpdateChange}
                handleUpdate={update}
                isError={isError}
            />
            <ModalPopUp
                type="delete-announcement"
                show={showModalDelete}
                handleClose={() => setShowModalDelete(false)}
                handleDelete={del}
                setData={handleUpdateChange}
            />
          </div>
        </div>
    );
  }

export default Announcement;
