import React, { useState } from "react";
import TableFooter from "./TableFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTable from "./Pagination";
import { useNavigate } from "react-router-dom";
import { formatDateSlash, getformatDate, getRole } from "../../Helpers/Utils/Common";
import TableLoader from "./TableLoader";
import NoDataFoundImg from '../../Assets/Images/noDataFound.png'
import "./Table.scss";


function Table({
  type,
  tableData,
  onSelectChange,
  headingColumns,
  subHeadingColumns,
  breakOn = "small",
  givenClass,
  withSubData,
  setID,
  useLoader = false,
  isReady,
}) {

  const navigateto = useNavigate();
  const roleId = getRole();
  const [index, setIndex] = useState(0);
  const [clickedID, setClickedID] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(tableData, page, rowsPerPage);

  const next = () => {
    var i = index;
    if (tableData[0].branch_data.length - 1 > index) {
      setIndex(i + 1);
    }
  };

  const previous = () => {
    var i = index;
    if (index > 0) {
      setIndex(i - 1);
    }
  };
  
  const handleClick = (row_id, row_status, row_user) => {
    setID({ id: row_id, status: row_status, name: row_user });
    setClickedID(row_id);
  };

  if (clickedID) {
    navigateto("/student-profile/" + clickedID);
  }

  let tableClass = "table-container__table";

  if (breakOn === "small") {
    tableClass += " table-container__table--break-sm";
  } else if (breakOn === "medium") {
    tableClass += " table-container_table--break-md";
  } else if (breakOn === "large") {
    tableClass += " table-container_table--break-lg";
  }

  if (withSubData) {
    const mainData = tableData.map((row) => {
      let rowData = [];
      let i = 0;

      for (const key in row) {
        rowData.push({
          key: headingColumns[i],
          val: row[key],
        });
        i++;
      }

      return (
        <tr key={row.index}>
          <td>{formatDateSlash(row.tran_date)}</td>
          <td>{formatDateSlash(row.deposit_date)}</td>
          <td>{row.branch}</td>
          <td>{row.branch_code}</td>
          <td>{row.fusion_location_code}</td>
          <td>{row.outlet_type}</td>
          <td className="first-index">{row.csr_on_duty}</td>
          {row.branch_data.map((branch) => {
            return (
              <>
                <td>{branch.data.bank}</td>
                <td>{branch.data.bank_acc_num}</td>
                <td>{branch.data.deposit_ref_num}</td>
                <td>{branch.data.to_be_remitted}</td>
                <td>{branch.data.remitted_amount}</td>
                <td>{branch.data.over_short}</td>
                <td>{branch.data.reasons}</td>
              </>
            );
          })}
        </tr>
      );
    });

    return (
      <div className="table-container">
        <div className="search-table-container row"></div>
        <table className={tableClass}>
          <thead>
            <tr>
              {headingColumns.map((data, index) => {
                return <th key={index}>{data}</th>;
              })}
              <th colSpan="7">
                <button className="type-navigation-btn">
                  <button
                    className="previous-nav-btn"
                    onClick={() => previous()}
                  >
                    <FontAwesomeIcon
                      icon={"angle-left"}
                      alt={"open"}
                      className={"nav-icon"}
                      aria-hidden="true"
                    />
                  </button>
                  {isReady && tableData[0].branch_data[index].shippingType}
                  <button className="next-nav-btn" onClick={() => next()}>
                    <FontAwesomeIcon
                      icon={"angle-right"}
                      alt={"open"}
                      className={"nav-icon"}
                      aria-hidden="true"
                    />
                  </button>
                </button>
              </th>
            </tr>
            <tr>
              <th className=""></th>
              <th className=""></th>
              <th className=""></th>
              <th className=""></th>
              <th className=""></th>
              <th className=""></th>
              <th className="first-index"></th>
              {subHeadingColumns.map((data, index) => {
                return <th key={index}>{data}</th>;
              })}
            </tr>
          </thead>

          <tbody>
            {!isReady && useLoader ? (
              <td colSpan={15} key={index}>
                No data found
              </td>
            ) : (
              mainData
            )}
          </tbody>
        </table>
        <TableFooter
          range={range}
          slice={slice}
          setPage={setPage}
          page={page}
          footerClass={givenClass}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
        />
      </div>
    );
  } else {
    // const tableBody = slice.map((row, index) => {
    const tableBody = slice.slice(0, 1).map((row, index) => {
      let i = 0;
      let rowData = [];

      if (type === "alumni-history") {
        return (
          <tr key={row.index} onClick={() => setID(row.id)}>
            <td className={" text-left"}>{row.first_name}</td>
            <td className={" text-left"}>{row.student_no}</td>
            <td className={" text-left"}>{row.permanent_address}</td>
            <td className={" text-left"}>{row.birthdate}</td>
            <td className={" text-left"}>{row.contact_no}</td>
            <td className={" text-left"}>{getformatDate(row.added_on)}</td>
          </tr>
        );
      } else if (type === "student_profile") {
        return (
          <>
            {!isReady && useLoader ? (
              <TableLoader tableHeaders={headingColumns} />
            ) : (
              <tr key={row.index}>
                {rowData.slice(0).map((data, index) => (
                  <td
                    key={index}
                    data-heading={data.key}
                    className={data.val + " text-left"}
                    onClick={() =>
                      setID({ id: row.id, status: row.status, name: row.user })
                    }
                  >
                    {data.val}
                    {data.value}
                  </td>
                ))}
              </tr>
            )}
          </>
        );
      } else if (type === "alumni") {
        return (
          <tbody>
            {
                !isReady && useLoader ? (
                  <TableLoader tableHeaders={headingColumns}/>
                ) : (
                  (slice && slice.length) ? (
                  slice.map((row, index) => {
                    return (
                      <tr key={row.index} className='pointer-cursor'>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() => handleClick(row.id, row.status, row.user)}
                        >
                          {row.first_name} {row.middle_name?row.middle_name:' '} {row.last_name}
                        </td>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() => handleClick(row.id, row.status, row.user)}
                        >
                          {row.student_no}
                        </td>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() => handleClick(row.id, row.status, row.user)}
                        >
                          {row.permanent_address}
                        </td>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() => handleClick(row.id, row.status, row.user)}
                        >
                          {row.birthdate}
                        </td>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() => handleClick(row.id, row.status, row.user)}
                        >
                          {row.contact_no}
                        </td>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() => handleClick(row.id, row.status, row.user)}
                        >
                          {row.added_on}
                        </td>
                        {
                          roleId !== '2' && roleId !=='3' && roleId !=='8' && (
                            <td
                              className=""
                              data-heading={"ACTION"}
                              align="center"
                            >
                              <select
                                className="form-control input-subtitle table-dropdown action-btn"
                                name="employment"
                                id={row.id}
                                onChange={onSelectChange}
                                value={""}
                              >
                                <option value="">Select</option>
                                <option value="update">UPDATE</option>
                                <option value="delete">DELETE</option>
                                <option value="profile">VIEW EXIT INTERVIEW</option>
                              </select>
                            </td>
                          )
                        }
                      </tr>
                    );
                  })
                ) : (
                  <td colSpan={15}>
                     <img src={NoDataFoundImg} className='no-data-img smaller-img centered-image p-0'/>
                  </td>
                )
                )
            }
          </tbody>
        );
      } else if (type === "manage_user") {
        return (
          <tbody>
            {
                !isReady && useLoader ? (
                  <TableLoader tableHeaders={headingColumns}/>
                ) : (
                  (slice && slice.length) ? (
                  slice.map((row, index) => {
                    return (
                      <tr key={row.index}>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.first_name + " " + row.last_name}
                        </td>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.role}
                        </td>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.email}
                        </td>
                        <td
                          key={row.index}
                          data-heading={"STATUS"}
                          className="text-left"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.status ? row.status.charAt(0).toUpperCase() + row.status.slice(1): ''}
                        </td>
                        <td
                          className={"justify-content-center"}
                          data-heading={"ACTION"}
                          align="center"
                        >
                          <select
                            className="form-control input-subtitle table-dropdown action-btn w-75"
                            name="employment"
                            id={row.id}
                            onChange={onSelectChange}
                            value={""}
                          >
                            <option value="">Select</option>
                            <option value="update">UPDATE</option>
                            {
                              row.status === "active" && (
                                 <option value="deactivate">DEACTIVATE</option>
                              )
                            }
                            {
                              row.status === "inactive" && (
                                 <option value="activate">ACTIVATE</option>
                              )
                            }
                            <option value="delete">DELETE</option>
                          </select>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <td colSpan={15}>
                     <img src={NoDataFoundImg} className='no-data-img smaller-img centered-image p-0'/>
                  </td>
                )
                )
            }
          </tbody>
        );
      } else if (type === "manage_departments") {
        return (
          <tbody>
            {
                !isReady && useLoader ? (
                  <TableLoader tableHeaders={headingColumns}/>
                ) : (
                  (slice && slice.length) ? (
                  slice.map((row, index) => {
                    return (
                      <tr className="ms-5">
                        <td>{row.label} </td>
                        <td>{row.college} </td>
                        <td
                          className={"text-left"}
                          data-heading={"ACTION"}
                          align="center"
                        >
                          <select
                            className="form-control input-subtitle table-dropdown action-btn"
                            name="department"
                            id={row.value}
                            onChange={onSelectChange}
                            value={""}
                          >
                            <option value="">Select</option>
                            <option value="update">EDIT</option>
                          </select>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <td colSpan={15}>
                     <img src={NoDataFoundImg} className='no-data-img smaller-img centered-image p-0'/>
                  </td>
                )
                )
            }
          </tbody>
        );
      } else if (type === "manage_courses") {
        return (
          <tbody>
            {
                !isReady && useLoader ? (
                  <TableLoader tableHeaders={headingColumns}/>
                ) : (
                  (slice && slice.length) ? (
                  slice.map((row, index) => {
                    return (
                      <tr>
                        <td>{row.label} </td>
                        <td>{row.department} </td>
                        <td
                          className={"text-left"}
                          data-heading={"ACTION"}
                          align="center"
                        >
                          <select
                            className="form-control input-subtitle table-dropdown action-btn"
                            name="department"
                            id={row.value}
                            onChange={onSelectChange}
                            value={""}
                          >
                            <option value="">Select</option>
                            <option value="update">EDIT</option>
                            <option value="delete">DELETE</option>
                          </select>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <td colSpan={15}>
                     <img src={NoDataFoundImg} className='no-data-img smaller-img centered-image p-0'/>
                  </td>
                )
                )
            }
          </tbody>
        );
      } else if (type === "announcement") {
        return (
          <tbody>
            {
                !isReady && useLoader ? (
                  <TableLoader tableHeaders={headingColumns}/>
                ) : (
                  (slice && slice.length) ? (
                  slice.map((row, index) => {
                    return (
                      <tr key={row.index}>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.date_posted}
                        </td>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.content}
                        </td>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.department}
                        </td>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.added_on}
                        </td>
                        {
                          roleId!=="3" && roleId!=="8"&& roleId!=="10" && (
                            <td
                              className={" text-left"}
                              data-heading={"ACTION"}
                              align="center"
                            >
                              <select
                                className="form-control input-subtitle table-dropdown action-btn"
                                name="employment"
                                id={row.id}
                                onChange={onSelectChange}
                                value={""}
                              >
                                <option value="">Select</option>
                                <option value="update">UPDATE</option>
                                <option value="delete">DELETE</option>
                              </select>
                            </td>
                          )
                        }
                      </tr>
                    );
                  })
                ) : (
                  <td colSpan={15}>
                     <img src={NoDataFoundImg} className='no-data-img smaller-img centered-image p-0'/>
                  </td>
                )
                )
            }
          </tbody> 
        );
      } else if (type === "project") {
        return (
          <tbody>
            {
                !isReady && useLoader ? (
                  <TableLoader tableHeaders={headingColumns}/>
                ) : (
                  (slice && slice.length) ? (
                  slice.map((row, index) => {
                    return (
                    <tr key={row.index}>
                      <td
                        key={row.index}
                        data-heading={row.index}
                        className="text-left"
                        onClick={() =>
                          setID({ id: row.id, status: row.status, name: row.user })
                        }
                      >
                        {row.added_on}
                      </td>
                      <td
                        key={row.index}
                        data-heading={row.index}
                        className="text-left"
                        onClick={() =>
                          setID({ id: row.id, status: row.status, name: row.user })
                        }
                      >
                        {row.name}
                      </td>
                      <td
                        key={row.index}
                        data-heading={row.index}
                        className="text-left"
                        onClick={() =>
                          setID({ id: row.id, status: row.status, name: row.user })
                        }
                      >
                        {row.project_head}
                      </td>
                      <td
                        key={row.index}
                        data-heading={row.index}
                        className="text-left"
                        onClick={() =>
                          setID({ id: row.id, status: row.status, name: row.user })
                        }
                      >
                        {row.date}
                      </td>
                      <td
                        key={row.index}
                        data-heading={row.index}
                        className="text-left"
                        onClick={() =>
                          setID({ id: row.id, status: row.status, name: row.user })
                        }
                      >
                        {row.budget}
                      </td>
                      <td
                        key={row.index}
                        data-heading={row.index}
                        className="text-left"
                        onClick={() =>
                          setID({ id: row.id, status: row.status, name: row.user })
                        }
                      >
                        {row.description}
                      </td>
                      {
                        roleId!=="3"&&roleId!=="8"&& roleId!=="10"&&(
                          <td
                            className={" text-left"}
                            data-heading={"ACTION"}
                            align="center"
                          >
                            <select
                              className="form-control input-subtitle table-dropdown action-btn"
                              name="employment"
                              id={row.id}
                              onChange={onSelectChange}
                              value={""}
                            >
                              <option value="">Select</option>
                              <option value="update">UPDATE</option>
                              <option value="delete">DELETE</option>
                              <option value="pledge">VIEW PLEDGE</option>
                            </select>
                          </td>
                        )
                      }
                    </tr>
                    );
                  })
                ) : (
                  <td colSpan={15}>
                     <img src={NoDataFoundImg} className='no-data-img smaller-img centered-image p-0'/>
                  </td>
                )
                )
            }
          </tbody>
        );
      } else if (type === "pledge") {
        return (
            <tbody>
              {
                  !isReady && useLoader ? (
                    <TableLoader tableHeaders={headingColumns}/>
                  ) : (
                    (slice && slice.length) ? (
                    slice.map((row, index) => {
                      return (
                      <tr key={row.index}>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.alumni_name}
                        </td>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.added_on}
                        </td>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-right"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.monetary}
                        </td>
                        {/* <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.date}
                        </td>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.budget}
                        </td>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.description}
                        </td>
                        {
                          roleId!=="3"&&roleId!=="8"&& roleId!=="10"&&(
                            <td
                              className={" text-left"}
                              data-heading={"ACTION"}
                              align="center"
                            >
                              <select
                                className="form-control input-subtitle table-dropdown action-btn"
                                name="employment"
                                id={row.id}
                                onChange={onSelectChange}
                                value={""}
                              >
                                <option value="">Select</option>
                                <option value="update">UPDATE</option>
                                <option value="delete">DELETE</option>
                                <option value="pledge">VIEW PLEDGE</option>
                              </select>
                            </td> */}
                          {/* ) */}
                      </tr>
                      );
                    })
                  ) : (
                    <td colSpan={15}>
                       <img src={NoDataFoundImg} className='no-data-img smaller-img centered-image p-0'/>
                    </td>
                  )
                  )
              }
            </tbody>
        );
      } else if (type === "scholarship") {
        return (
          <tbody>
            {
                !isReady && useLoader ? (
                  <TableLoader tableHeaders={headingColumns}/>
                ) : (
                  (slice && slice.length) ? (
                  slice.map((row, index) => {
                    return (
                      <tr key={row.index}>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.added_on}
                        </td>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.name}
                        </td>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.contact_person}
                        </td>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.target_date}
                        </td>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.budget}
                        </td>
                        <td
                          key={row.index}
                          data-heading={row.index}
                          className="text-left"
                          onClick={() =>
                            setID({ id: row.id, status: row.status, name: row.user })
                          }
                        >
                          {row.description}
                        </td>
                        {
                          roleId!=="2" && roleId!=="8"&& roleId!=="10" && (
                            <td
                              className={" text-left"}
                              data-heading={"ACTION"}
                              align="center"
                            >
                              <select
                                className="form-control input-subtitle table-dropdown action-btn"
                                name="employment"
                                id={row.id}
                                onChange={onSelectChange}
                                value={""}
                              >
                                <option value="">Select</option>
                                <option value="update">UPDATE</option>
                                <option value="delete">DELETE</option>
                              </select>
                            </td>
                          )
                        }
                      </tr>
                    );
                  })
                ) : (
                  <td colSpan={15}>
                     <img src={NoDataFoundImg} className='no-data-img smaller-img centered-image p-0'/>
                  </td>
                )
                )
            }
          </tbody>
        );
      } else if (type === "further_studies") {
        return (
          <tbody>
            {
                !isReady && useLoader ? (
                  <TableLoader tableHeaders={headingColumns}/>
                ) : (
                  (slice && slice.length) ? (
                  slice.map((data, index) => {
                    return (
                      <tr>
                        <td>{data.first_name + " " + data.last_name}</td>
                        <td>{data.permanent_address}</td>
                        <td>{data.contact_no}</td>
                        <td>{data.personal_email}</td>
                        {/* <td>{data.further_studies}</td> */}
                        <td>
                          {data.further_studies.split(',').filter(Boolean).map((study, index) => (
                            <div key={index}>{study}</div>
                          ))}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr colSpan={5} >
                      NO DATA FOUND
                  </tr>
                )
                )
            }
          </tbody>
        );
      } else if (type === "employability_rate") {
      return (
        <tbody>
          {
            !isReady && useLoader ? (
              <TableLoader tableHeaders={headingColumns}/>
            ) : (
              slice.length != 0 ? (
                slice.map((data) => {
                  return (
                    <tr>
                      <td>{data.first_name + " " + data.last_name}</td>
                      <td>{data.department_name}</td>
                      <td>{data.program_name}</td>
                      <td>{data.sector}</td>
                      <td>{data.position}</td>
                      <td>{data.monthly_salary}</td>
                      <td>
                        {data.is_international == "1"
                          ? data.company_institution
                          : ""}
                      </td>
                    </tr>
                  );
                })
              ) : (
                  <td className="pink-bg p-5">
                      <img src={NoDataFoundImg} className='no-data-img smaller-img centered-image p-0'/>
                  </td>
              )
            )
          }
          
        </tbody>
      );
      } else if (type === "employability_status") {
        return (
          <tbody>
            {
              slice.length != 0 ? (
                slice.map((data, index) => {
              return (
                <tr>
                  <td>{data.first_name + " " + data.last_name}</td>
                  <td>{data.permanent_address}</td>
                  <td>{data.contact_no}</td>
                  <td>{data.personal_email}</td>
                  <td>{data.company_institution}</td>
                  <td>{data.position}</td>
                  <td>{data.monthly_salary}</td>
                </tr>
              );
            })
              ) : (
                <td colSpan={15}>NO DATA FOUND</td>
              )
            }
          </tbody>
        );
      } else if (type === "total_graduates") {
        return (
          <tbody>
            {tableData.length != 0 ? (
              tableData.map((data, index) => {
                return (
                  <tr>
                    <td>{data.year_graduated}</td>
                    <td>{data.department}</td>
                    <td>{data.graduates}</td>
                    <td>{data.total_graduates}</td>
                  </tr>
                );
              })
            ) : (
              <td colSpan={15} key={index}>
                NO DATA FOUND
              </td>
            )}
          </tbody>
        );
      }
    })


    //COMMON TABLE
      return (
        <div className="table-container transaction-table-cont">
          <div className="search-table-container row"></div>
          <table className={tableClass + " max-width" + " table-loader"}>
            <thead>
              <tr>
                {headingColumns.map((data, index) => {
                  return (
                    <th key={index} className="text-left">
                      {data}
                    </th>
                  );
                })}
              </tr>
            </thead>
            {tableBody}
            {tableData.length === 0 && (
                <td colSpan={15}>
                  <img src={NoDataFoundImg} className='no-data-img smaller-img centered-image p-0'/>
                </td>
              )}
          </table>
          <TableFooter
            range={range}
            slice={slice}
            setPage={setPage}
            page={page}
            footerClass={givenClass}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
          />
        </div>
      );
  }
}

export default Table;
