import React, { useEffect, useState } from "react";
import {Row, Col} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getType, getRole, removeUserSession } from "../../Helpers/Utils/Common";
import MenuItem from "./MenuItem";

//css
import "../Navbar/Navbar.css";

//icons
import logo from "../../Assets/Images/Login/logo-logo.png";
import home from "../../Assets/Images/Navbar/HomeIcon.png";
import user from "../../Assets/Images/Navbar/UsersIcon.png";
import projects from "../../Assets/Images/Navbar/ProjectIcon.png";
import gradcap from "../../Assets/Images/Navbar/StudentsIcon.png";
import scholarship from "../../Assets/Images/Dashboard/scholarship.png";
import announcement from "../../Assets/Images/Navbar/AnnouncementsIcon.png";
import reports from "../../Assets/Images/Navbar/ReportsIcon.png";
import logout from "../../Assets/Images/Navbar/Logout.png";
import interview from "../../Assets/Images/test.png";
import department from "../../Assets/Images/Navbar/department.png"
import course from "../../Assets/Images/Navbar/courseIcon.png"


export default function Navbar(props ) {

  const managerAdmin = [
    {
      name: "HOME",
      exact: true,
      to: "/dashboard",
      iconClassName: home,
      subMenus: [],
      expand: true,
    },
    {
      name: "ALUMNI",
      exact: true,
      to: "/alumni",
      iconClassName: gradcap,
      subMenus: [],
      expand: true,
    },
    {
      name: "USER",
      exact: true,
      to: "/manage-user",
      iconClassName: user,
      subMenus: [],
      expand: true,
    },
    {
      name: "DEPARTMENT",
      exact: true,
      to: "/department",
      iconClassName: department,
      subMenus: [],
      expand: false
    },
    {
      name: "COURSE",
      exact: true,
      to: "/course",
      iconClassName: course,
      subMenus: [],
      expand: false
    },
    {
      name: "ANNOUNCEMENTS",
      exact: true,
      to: "/announcement",
      iconClassName: announcement,
      subMenus: [],
      expand: true,
    },
    {
      name: "PROJECTS",
      exact: true,
      to: "/projects",
      iconClassName: projects,
      subMenus: [],
      expand: true,
    },
    {
      name: "SCHOLARSHIP",
      exact: true,
      to: "/scholarship",
      iconClassName: scholarship,
      subMenus: [],
      expand: true,
    },
    {
      name: "EXIT-INTERVIEW",
      exact: true,
      to: "/exit-interview",
      iconClassName: interview,
      subMenus: [],
      expand: true,
    },
    {
      name: "QUALITATIVE-EXIT-INTERVIEW",
      exact: true,
      to: "/exit-interview-qualitative",
      iconClassName: interview,
      subMenus: [],
      expand: true,
    },
    {
      name: "REPORTS",
      exact: false,
      to: "",
      iconClassName: reports,
      subMenus: [{
        name: "Employability Rate Within 6 Months",
        exact: true,
        to: "/employability_rate/6",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      },
      {
        name: "Employability Rate Within 12 Months",
        exact: true,
        to: "/employability_rate/12",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      },
      {
        name: "Employability Rate (up-to-date)",
        exact: true,
        to: "/employability_rate/999",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      },
      {
        name: "Employability Status",
        exact: true,
        to: "/employability_status",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      },
      {
        name: "Further Studies",
        exact: true,
        to: "/further_studies",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      }
    ],
      expand: true
    }
];

const managerDepartmentHead = [
  {
    name: "HOME",
    exact: true,
    to: "/dashboard",
    iconClassName: home,
    subMenus: [],
    expand: true,
  },
  {
    name: "ALUMNI",
    exact: true,
    to: "/alumni",
    iconClassName: gradcap,
    subMenus: [],
    expand: true,
  },
  {
      name: "USER",
      exact: true,
      to: "/manage-user",
      iconClassName: user,
      subMenus: [],
      expand: true,
    },
    {
      name: "DEPARTMENT",
      exact: true,
      to: "/department",
      iconClassName: department,
      subMenus: [],
      expand: false
    },
    {
      name: "COURSE",
      exact: true,
      to: "/course",
      iconClassName: course,
      subMenus: [],
      expand: false
    },
  {
    name: "ANNOUNCEMENTS",
    exact: true,
    to: "/announcement",
    iconClassName: announcement,
    subMenus: [],
    expand: true,
  },
  {
    name: "PROJECTS",
    exact: true,
    to: "/projects",
    iconClassName: projects,
    subMenus: [],
    expand: true,
  },
  {
    name: "SCHOLARSHIP",
    exact: true,
    to: "/scholarship",
    iconClassName: scholarship,
    subMenus: [],
    expand: true,
  },
  {
    name: "EXIT-INTERVIEW",
    exact: true,
    to: "/exit-interview",
    iconClassName: interview,
    subMenus: [],
    expand: true,
  },
  {
    name: "QUALITATIVE-EXIT-INTERVIEW",
    exact: true,
    to: "/exit-interview-qualitative",
    iconClassName: interview,
    subMenus: [],
    expand: true,
  },
  {
    name: "REPORTS",
    exact: false,
    to: "",
    iconClassName: reports,
    subMenus: [{
      name: "Employability Rate Within 6 Months",
      exact: true,
      to: "/employability_rate/6",
      iconClassName: reports,
      subMenus: [],
      expand: true,
    },
    {
      name: "Employability Rate Within 12 Months",
      exact: true,
      to: "/employability_rate/12",
      iconClassName: reports,
      subMenus: [],
      expand: true,
    },
    {
      name: "Employability Rate (up-to-date)",
      exact: true,
      to: "/employability_rate/999",
      iconClassName: reports,
      subMenus: [],
      expand: true,
    },
    {
      name: "Employability Status",
      exact: true,
      to: "/employability_status",
      iconClassName: reports,
      subMenus: [],
      expand: true,
    },
    {
      name: "Further Studies",
      exact: true,
      to: "/further_studies",
      iconClassName: reports,
      subMenus: [],
      expand: true,
    }
  ],
    expand: true
  }
];

const managerCos = [
  {
    name: "HOME",
    exact: true,
    to: "/dashboard",
    iconClassName: home,
    subMenus: [],
    expand: true,
  },
  {
    name: "ALUMNI",
    exact: true,
    to: "/alumni",
    iconClassName: gradcap,
    subMenus: [],
    expand: true,
  },
  {
    name: "USER",
    exact: true,
    to: "/manage-user",
    iconClassName: user,
    subMenus: [],
    expand: true,
  },
  {
      name: "USER",
      exact: true,
      to: "/manage-user",
      iconClassName: user,
      subMenus: [],
      expand: true,
    },
    {
      name: "DEPARTMENT",
      exact: true,
      to: "/department",
      iconClassName: department,
      subMenus: [],
      expand: false
    },
    {
      name: "COURSE",
      exact: true,
      to: "/course",
      iconClassName: course,
      subMenus: [],
      expand: false
    },
  {
    name: "ANNOUNCEMENTS",
    exact: true,
    to: "/announcement",
    iconClassName: announcement,
    subMenus: [],
    expand: true,
  },
  {
    name: "PROJECTS",
    exact: true,
    to: "/projects",
    iconClassName: projects,
    subMenus: [],
    expand: true,
  },
  {
    name: "SCHOLARSHIP",
    exact: true,
    to: "/scholarship",
    iconClassName: scholarship,
    subMenus: [],
    expand: true,
  },
  {
    name: "EXIT-INTERVIEW",
    exact: true,
    to: "/exit-interview",
    iconClassName: interview,
    subMenus: [],
    expand: true,
  },
  {
    name: "QUALITATIVE-EXIT-INTERVIEW",
    exact: true,
    to: "/exit-interview-qualitative",
    iconClassName: interview,
    subMenus: [],
    expand: true,
  },
  {
      name: "REPORTS",
      exact: false,
      to: "",
      iconClassName: reports,
      subMenus: [{
        name: "Employability Rate Within 6 Months",
        exact: true,
        to: "/employability_rate/6",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      },
      {
        name: "Employability Rate Within 12 Months",
        exact: true,
        to: "/employability_rate/12",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      },
      {
        name: "Employability Rate (up-to-date)",
        exact: true,
        to: "/employability_rate/999",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      },
      {
        name: "Employability Status",
        exact: true,
        to: "/employability_status",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      },
      {
        name: "Further Studies",
        exact: true,
        to: "/further_studies",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      }
    ],
      expand: true
    }
];

const managerItc = [
  {
    name: "HOME",
    exact: true,
    to: "/dashboard-itc",
    iconClassName: home,
    subMenus: [],
    expand: true,
  },
  {
    name: "ALUMNI",
    exact: true,
    to: "/alumni",
    iconClassName: gradcap,
    subMenus: [],
    expand: true,
  },
  {
      name: "USER",
      exact: true,
      to: "/manage-user",
      iconClassName: user,
      subMenus: [],
      expand: true,
    },
    {
      name: "DEPARTMENT",
      exact: true,
      to: "/department",
      iconClassName: department,
      subMenus: [],
      expand: false
    },
    {
      name: "COURSE",
      exact: true,
      to: "/course",
      iconClassName: course,
      subMenus: [],
      expand: false
    },
  {
    name: "ANNOUNCEMENTS",
    exact: true,
    to: "/announcement",
    iconClassName: announcement,
    subMenus: [],
    expand: true,
  },
  {
    name: "PROJECTS",
    exact: true,
    to: "/projects",
    iconClassName: projects,
    subMenus: [],
    expand: true,
  },
  {
    name: "SCHOLARSHIP",
    exact: true,
    to: "/scholarship",
    iconClassName: scholarship,
    subMenus: [],
    expand: true,
  },
  {
    name: "EXIT-INTERVIEW",
    exact: true,
    to: "/exit-interview",
    iconClassName: interview,
    subMenus: [],
    expand: true,
  },
  {
    name: "QUALITATIVE-EXIT-INTERVIEW",
    exact: true,
    to: "/exit-interview-qualitative",
    iconClassName: interview,
    subMenus: [],
    expand: true,
  },
  {
      name: "REPORTS",
      exact: false,
      to: "",
      iconClassName: reports,
      subMenus: [{
        name: "Employability Rate Within 6 Months",
        exact: true,
        to: "/employability_rate/6",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      },
      {
        name: "Employability Rate Within 12 Months",
        exact: true,
        to: "/employability_rate/12",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      },
      {
        name: "Employability Rate (up-to-date)",
        exact: true,
        to: "/employability_rate/999",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      },
      {
        name: "Employability Status",
        exact: true,
        to: "/employability_status",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      },
      {
        name: "Further Studies",
        exact: true,
        to: "/further_studies",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      }
    ],
      expand: true
    }
];

const managerPio = [
  {
    name: "HOME",
    exact: true,
    to: "/dashboard-pio",
    iconClassName: home,
    subMenus: [],
    expand: true,
  },
  {
      name: "ALUMNI",
      exact: true,
      to: "/alumni",
      iconClassName: gradcap,
      subMenus: [],
      expand: true,
    },
  {
    name: "ANNOUNCEMENTS",
    exact: true,
    to: "/announcement",
    iconClassName: announcement,
    subMenus: [],
    expand: true,
  },
  {
    name: "PROJECTS",
    exact: true,
    to: "/projects",
    iconClassName: projects,
    subMenus: [],
    expand: true,
  },
  // {
  //   name: "EXIT-INTERVIEW",
  //   exact: true,
  //   to: "/exit-interview",
  //   iconClassName: interview,
  //   subMenus: [],
  //   expand: true,
  // },
  // {
  //   name: "QUALITATIVE-EXIT-INTERVIEW",
  //   exact: true,
  //   to: "/exit-interview-qualitative",
  //   iconClassName: interview,
  //   subMenus: [],
  //   expand: true,
  // },
];

const managerOsa = [
  {
    name: "HOME",
    exact: true,
    to: "/dashboard-osa",
    iconClassName: home,
    subMenus: [],
    expand: true,
  },
  {
    name: "ALUMNI",
    exact: true,
    to: "/alumni",
    iconClassName: gradcap,
    subMenus: [],
    expand: true,
  },
  {
    name: "SCHOLARSHIP",
    exact: true,
    to: "/scholarship",
    iconClassName: scholarship,
    subMenus: [],
    expand: true,
  },
  {
    name: "EXIT-INTERVIEW",
    exact: true,
    to: "/exit-interview",
    iconClassName: interview,
    subMenus: [],
    expand: true,
  },
  {
    name: "QUALITATIVE-EXIT-INTERVIEW",
    exact: true,
    to: "/exit-interview-qualitative",
    iconClassName: interview,
    subMenus: [],
    expand: true,
  },
];

const managerOUR = [
  {
    name: "HOME",
    exact: true,
    to: "/dashboard",
    iconClassName: home,
    subMenus: [],
    expand: true,
  },
  {
    name: "ALUMNI",
    exact: true,
    to: "/alumni",
    iconClassName: gradcap,
    subMenus: [],
    expand: true,
  },
  {
      name: "USER",
      exact: true,
      to: "/manage-user",
      iconClassName: user,
      subMenus: [],
      expand: true,
    },
    {
      name: "DEPARTMENT",
      exact: true,
      to: "/department",
      iconClassName: department,
      subMenus: [],
      expand: false
    },
    {
      name: "COURSE",
      exact: true,
      to: "/course",
      iconClassName: course,
      subMenus: [],
      expand: false
    },
  {
    name: "ANNOUNCEMENTS",
    exact: true,
    to: "/announcement",
    iconClassName: announcement,
    subMenus: [],
    expand: true,
  },
  {
    name: "PROJECTS",
    exact: true,
    to: "/projects",
    iconClassName: projects,
    subMenus: [],
    expand: true,
  },
  {
    name: "SCHOLARSHIP",
    exact: true,
    to: "/scholarship",
    iconClassName: scholarship,
    subMenus: [],
    expand: true,
  },
  {
    name: "EXIT-INTERVIEW",
    exact: true,
    to: "/exit-interview",
    iconClassName: interview,
    subMenus: [],
    expand: true,
  },
  {
    name: "QUALITATIVE-EXIT-INTERVIEW",
    exact: true,
    to: "/exit-interview-qualitative",
    iconClassName: interview,
    subMenus: [],
    expand: true,
  },
  {
      name: "REPORTS",
      exact: false,
      to: "",
      iconClassName: reports,
      subMenus: [{
        name: "Employability Rate Within 6 Months",
        exact: true,
        to: "/employability_rate/6",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      },
      {
        name: "Employability Rate Within 12 Months",
        exact: true,
        to: "/employability_rate/12",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      },
      {
        name: "Employability Rate (up-to-date)",
        exact: true,
        to: "/employability_rate/999",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      },
      {
        name: "Employability Status",
        exact: true,
        to: "/employability_status",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      },
      {
        name: "Further Studies",
        exact: true,
        to: "/further_studies",
        iconClassName: reports,
        subMenus: [],
        expand: true,
      }
    ],
      expand: true
    }
];

const managerCommittee = [
  {
    name: "HOME",
    exact: true,
    to: "/dashboard",
    iconClassName: home,
    subMenus: [],
    expand: true,
  },
  {
    name: "ALUMNI",
    exact: true,
    to: "/alumni",
    iconClassName: gradcap,
    subMenus: [],
    expand: true,
  },
  {
    name: "ANNOUNCEMENTS",
    exact: true,
    to: "/announcement",
    iconClassName: announcement,
    subMenus: [],
    expand: true,
  },
  {
    name: "PROJECTS",
    exact: true,
    to: "/projects",
    iconClassName: projects,
    subMenus: [],
    expand: true,
  },
  {
    name: "SCHOLARSHIP",
    exact: true,
    to: "/scholarship",
    iconClassName: scholarship,
    subMenus: [],
    expand: true,
  },
  {
    name: "EXIT-INTERVIEW",
    exact: true,
    to: "/exit-interview",
    iconClassName: interview,
    subMenus: [],
    expand: true,
  },
  {
    name: "QUALITATIVE-EXIT-INTERVIEW",
    exact: true,
    to: "/exit-interview-qualitative",
    iconClassName: interview,
    subMenus: [],
    expand: true,
  },
];

  const [inactive, setInactive] = useState(true);
  const [menuItems, setMenuItems] = useState(managerAdmin);
  const navigateto = useNavigate()

  const handleExpand = (index) => {
    var list = [...menuItems];

    if (!inactive) {
      for (var i = 0; i < list.length; i++) {
        if (i !== index) {
          list[i].expand = true;
        }
      }

      list[index].expand = !list[index].expand;

      setMenuItems(list);
    }
  };

  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll(".sub-menu").forEach((el) => {
      el.classList.remove("active");
    });
  };

  const handleLogout = () => {
    const google = window.google
    google.accounts.id.disableAutoSelect();
    removeUserSession();
  }

  useEffect(() => {
    if (inactive) {
      removeActiveClassFromSubMenu();
    }

    props.onCollapse(inactive);
  }, [inactive]);

  useEffect(() => {
     const role = getRole();
     const type = getType();

    if (role === "1") {
      setMenuItems(managerAdmin);
    } 
    else if (role === "2" ) {
      setMenuItems(managerPio);
    }
    else if (role === "3" ) {
      setMenuItems(managerOsa);
    }
    else if (role === "4" ) {
      setMenuItems(managerDepartmentHead);
    }
    else if (role === "5" ) {
      setMenuItems(managerCos);
    }
    else if (role === "6" ) {
      setMenuItems(managerItc);
    }
    else if (role === "7" ) {
      setMenuItems(managerOUR);
    }
    else if (role === "8"||role==="10") {
      setMenuItems(managerCommittee);
    }

    let menuItemsList = document.querySelectorAll(".menu-item");
    menuItemsList.forEach((el, index) => {
      if (menuItems[index].name === props.active) {
        el.classList.add("active");
      }
      el.addEventListener("click", (e) => {
        const next = el.nextElementSibling;
        removeActiveClassFromSubMenu();
        menuItemsList.forEach((item) => item.classList.remove("active"));
        el.classList.toggle("active");
        if (next !== null) {
          next.classList.toggle("active");
        }
      });
    });
  }, []);


  return (
    <Col className={`side-menu ${inactive ? "inactive" : ""}`}>
      <Row className="top-section">
        <div className="logo logo-cont">
          <Row>
            <Col sm={5} onClick={() => setInactive(!inactive)}>
              <img alt={"navbar-logo"} src={logo} />
            </Col>
             <Col className={"mt-3"}>
              <span className="side-menu-title">UP CEBU ALUMNI TRACKER</span>
            </Col>
          </Row>
          <br />
        </div>
      </Row>
      <Row className={inactive ? "main-menu ms-2" : "main-menu active-menu"}>
        {menuItems.map((menuItem, index) => (
          <MenuItem
            key={index}
            name={menuItem.name}
            exact={menuItem.exact.toString()}
            to={menuItem.to}
            subMenus={menuItem.subMenus || []}
            iconClassName={menuItem.iconClassName}
            expandManage={menuItem.expand}
            setExpandManage={handleExpand}
            index={index}
            activeSub={menuItem.name === props.active}
            onClick={() => {
              if (inactive) {
                setInactive(false);
              }
            }}
          />
        ))}
      </Row>
      
      <Row className="side-menu-footer">
        <hr/>
        <div className="logout-cont mt-5" onClick={handleLogout}>
          <img id='signout_button' alt={"logout-icon"} src={logout} className="logout-btn"/>
          <span id='signout_button' className="logout-label">LOG OUT</span>
        </div>
      </Row>
    </Col>
  );
}

