import Navbar from "../../Components/Navbar/Navbar";
import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import Select from "react-select";
import { Row, Col, Container } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";
import ReactToPrint from "react-to-print";

import {getName} from "../../Helpers/Utils/Common";
import { dropdownStyle } from "../../Helpers/Constants/StyleConstants";
import { getEssayResponses } from "../../APICalls/ExitInterview/ExitInterviewApi";
import { GetDegreePrograms } from "../../APICalls/DegreeProgram.js/DegreeProgramApi";
import { GetOldestBatch } from "../../Helpers/Utils/CommonAPIs";
import Table from "../../Components/Table/Table";

import Logo3 from '../../Assets/Images/Login/logo_3.png'
import upCebuLogo from '../../Assets/Images/Login/up-cebu-logo.png'
import csLogo from '../../Assets/Images/Login/cs-logo.png'
import NoDataFoundImg from '../../Assets/Images/noDataFound.png'

import "./ExitInterview.css";


function ExitInterviewQualitative({ setIsChecked, isChecked }) {
  const departmentDropdown = GetDegreePrograms();
  const oldestBatch = GetOldestBatch();
  const currentYear = new Date().getFullYear();
  const today = new Date()
  const dateToday = today.toLocaleString('default', { month: 'long' }) + ' ' + today.getDate() + ', '+ today.getFullYear();
  const componentRef = useRef();
  const userName = getName();
  const [inactive, setInactive] = useState(false);
  const [essayData, setEssayData] = useState([]);
  const [otherPlans, setOtherPlans] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [years, setYears] = useState([]);
  const [isReady, setIsReady] = useState(false);

  const [filterData, setFilterData] = useState({
    year: "",
    degree_program_id: []
  });


  // FETCH ESSAY RESPONSES
  async function fetchExitInterviewOthers() {
    essayData.length = 0;
    const response = await getEssayResponses(filterData);
    if (response.data) {
      setEmpty(false);
      setEssayData(response.data);
      setIsReady(true);
      response.data.map((plan) => {
        if (plan.other_plan) {
          setOtherPlans([...otherPlans, plan.other_plan]);
        }
      })
    } else {
      setEmpty(true)
    }
  }

  const handleFilterDegree = (event) => {
    var degrees = []
    event.map((val) => {
      degrees.push(val.value)
    })
    setFilterData({...filterData, degree_program_id: degrees})
  }

  // GET YEARS FROM OLDEST BATCH TO RECENT CURRENT YEAR FOR FILTER
  React.useEffect(() => {
    if (oldestBatch) {
      var years_arr = [{ name: parseInt(oldestBatch) }];
      for (
        var i = parseInt(oldestBatch);
        i < new Date().getFullYear();
        ++i
      ) {
        years_arr.push({ name: i + 1 });
      }
      setYears(years_arr);
    }
  }, []);

  React.useEffect(() => {
    fetchExitInterviewOthers();
  }, [filterData])


  return (
    <div className="page">
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />

      <div className=" login-container">
        <div className={`container ${inactive ? "inactive" : "active"}`}>
          <div className="form-cont pt-4">
            <div className="container ms-2 pt-0">
              <Row>
                <Col className="col-5 left">
                  <h3 className="left title">QUALITATIVE EXIT INTERVIEW</h3>
                </Col>
                <Col className="col-3 left">
                  <Select
                    styles={dropdownStyle}
                    options={departmentDropdown}
                    placeholder="All"
                    onChange={handleFilterDegree}
                    isSearchable={false}
                    isMulti
                    closeMenuOnScroll={false}
                    closeMenuOnSelect={false}
                  />
                </Col>
                <Col className="col-1 ms-0 left">
                  <select
                    type="text"
                    name="year"
                    className="left maroon-btn"
                    onChange={(e) => setFilterData({...filterData, year: e.target.value})}
                  >
                    <option value={currentYear}>{currentYear}</option>
                    {years.map((data) => (
                      <option value={data.name}>{data.name}</option>
                    ))}
                  </select>
                </Col>
                <Col className="noprint col-1 left me-0">
                    <Row>
                      <ReactToPrint
                      trigger={() => (
                        <button className="yellow-btn ">Print</button>
                      )}
                      content={() => componentRef.current}
                      documentTitle={'Qualitative Exit Interview'}
                    />
                    </Row>
                  </Col>
                <Col className="col-2 ms-0 left">
                  <DownloadTableExcel
                      filename="Qualitative Exit Interview"
                      sheet="Exit Interview"
                      currentTableRef={componentRef.current}
                    >
                      <button className=" width-fill height-fill orange-btn">
                        DOWNLOAD CSV
                      </button>
                    </DownloadTableExcel>
                </Col>
              </Row>
              <hr />
              <Row>
                <h3 className="header-2 pt-4 left">PLANS AFTER GRADUATION (UNDER OTHERS):</h3>
              </Row>

              <Row className="center">
                {(otherPlans && otherPlans.length) ? (
                  <Col className="row">
                    {otherPlans.map((essayData) => {
                      return (
                        <Col className="col-3 ms-2 mt-2 left green-btn">
                          <label>{essayData}</label>
                        </Col>
                      );
                    })}
                  </Col>
                  
                ) : (
                  <img src={NoDataFoundImg} className='no-data-img smaller-img'/>
                )}
              </Row>
                    
                  <Container className="mx-2">
                    <Row className="row header-2"><h4 className=" d-flex justify-content-left">ESSAY RESPONSES</h4></Row>
                    <Row className="question-header mt-3 p-3">WHAT ARE YOUR BEST EXPERIENCES IN THE UNIVERSITY?</Row>
                    <Row>
                      <Container className="response-container p-2">
                        {
                          (essayData && essayData.length) ? 
                            essayData.map((data) => {
                              if (data.experience) {
                                return (
                                  <>
                                  <Row className="text-start ms-3 me-5">{data.experience}</Row>
                                  <hr/>
                                  </>
                                )
                              }
                          })
                           : (<img src={NoDataFoundImg} className='no-data-img smaller-img'/>)
                        }
                      </Container>
                    </Row>
                  </Container>

                  <Container className="mx-2 mt-0">
                    <Row className="question-header mt-3 p-3">WHAT CHALLENGES DID YOU ENCOUNTER IN THE UNIVERSITY? HOW DID YOU MANAGE IT?</Row>
                    <Row>
                      <Container className="response-container p-2">
                        {
                          (essayData && essayData.length) ? 
                            essayData.map((data) => {
                              if (data.challenge) {
                                return (
                                  <>
                                  <Row className="text-start ms-3">{data.challenge}</Row>
                                  <hr/>
                                  </>
                                )
                              }
                          })
                           : (<img src={NoDataFoundImg} className='no-data-img smaller-img'/>)
                        }
                      </Container>
                    </Row>
                  </Container>

                  <Container className="mx-2 mt-0 mb-0">
                    <Row className="question-header mt-3 p-3">WHAT ARE YOUR COMMENTS/SUGGESTIONS FOR IMPROVEMENT FOR UP CEBU?</Row>
                    <Row>
                      <Container className="response-container p-2">
                        {
                          (essayData && essayData.length) ? 
                            essayData.map((data) => {
                              if (data.remarks) {
                                return (
                                  <>
                                  <Row className="text-start ms-3">{data.remarks}</Row>
                                  <hr/>
                                  </>
                                )
                              }
                          })
                           : (<img src={NoDataFoundImg} className='no-data-img smaller-img'/>)
                        }
                      </Container>
                    </Row>
                  </Container>
            
              <Col></Col>
             
              
              <div className="onlyPrint">
                  <Row className="pt-2 report-table" ref={componentRef}>
                    <div className="report-logo-container center mb-4 ms-5 ">
                      <img src={Logo3} className="report-logo" />
                      <img src={upCebuLogo} className="report-logo" />
                      <img src={csLogo} className="report-logo" />
                    </div>
                    <table className="table table-bordered left ms-5 ">
                      <thead></thead>
                      <tbody>
                        <tr>
                          <th colSpan={3} className='left'>Plans After Graduation (Others):</th>
                        </tr>
                        {/* <tr>
                          <th colSpan={3}>Others (Please specify)</th>
                        </tr> */}
                        {otherPlans.map((data) => {
                          return (
                            <tr>
                              <td colSpan={3}>{data} </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <br></br>
                        </tr>
                        <tr>
                          <th colSpan={3} className='left'>Essay Responses:</th>
                        </tr>
                        <tr>
                          <th colSpan={3}>WHAT ARE YOUR BEST EXPERIENCES IN THE UNIVERSITY?</th>
                        </tr>
                        {essayData.map((data) => {
                          return (
                            <tr>
                              <td colSpan={3}>{data.experience} </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <br></br>
                        </tr>
                        <tr>
                          <th colSpan={3}>WHAT CHALLENGES DID YOU ENCOUNTER IN THE UNIVERSITY? HOW DID YOU MANAGE IT?</th>
                        </tr>
                        {essayData.map((data) => {
                          return (
                            <tr>
                              <td colSpan={3}>{data.challenge} </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <br></br>
                        </tr>
                        <tr>
                          <th colSpan={3}>WHAT ARE YOUR COMMENTS/SUGGESTIONS FOR IMPROVEMENT FOR UP CEBU?</th>
                        </tr>
                        {essayData.map((data) => {
                          return (
                            <tr>
                              <td colSpan={3}>{data.remarks} </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <br></br>
                        </tr>
                          
                        
                      </tbody>
                    </table>
                    <div className="report-logo-container left mb-4 ms-5 mt-5">
                      <p>Generated on: <span>{dateToday.toLocaleString()}</span></p>
                      <p>Generated by: <span>{userName}</span></p>
                    </div>
                  </Row>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>

  );
}

ExitInterviewQualitative.propTypes = {
  setIsChecked: PropTypes.any,
  isChecked: PropTypes.bool,
  key: PropTypes.any,
};

export default ExitInterviewQualitative;
