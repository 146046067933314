import React, { useState, useRef } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import close from "../../Assets/Images/Alumni/close.png";
import Table from "../../Components/Table/Table";
import { getOldestBatch } from "../../APICalls/ManageUserApi";
import { GetDepartments } from "../../APICalls/Department/DepartmentApi";
import InputError from "../InputError/InputError";
import "./Modal.css";
import "../Modal/ProgressBar/ProgressBar.css";
import "react-toastify/dist/ReactToastify.css";


const ModalPopUp = ({
  type,
  show,
  handleClose,
  handleOnClick,
  handleSelect,
  handleAdd,
  handleUpload,
  handleUpdate,
  handleDelete,
  setData,
  data,
  isError,
}) => {
  const showHideClassName = show
    ? "modal-pop display-block"
    : "modal-pop display-none";
  const [isClicked, setIsClicked] = useState(false);
  const [years, setYears] = useState([]);
  const departments = GetDepartments();

   const colleges = [
    {value: "1", label: "College of Science"}
  ]

  const handleInputChange = (e) => {
    setData(e);
  };

  const saveBtn = useRef(null);
  const disableSaveBtn = (event) => {
    saveBtn.current.disabled = true;
    setTimeout(() => {
        saveBtn.current.disabled = false;
    }, 3000)
    handleAdd();
  };

  const updateBtn = useRef(null);
  const handleTwiceUpdate = (event) => {
    updateBtn.current.disabled = true;
    setTimeout(() => {
        updateBtn.current.disabled = false;
    }, 3000)
    handleUpdate();
  }

  const uploadBtn = useRef(null);
  const handleTwiceUpload = (event) => {
    uploadBtn.current.disabled = true;
    setTimeout(() => {
        uploadBtn.current.disabled = false;
    }, 3000)
    handleUpload();
  }

  async function getBatchYears() {
    const response = await getOldestBatch();
    if (response.data) {
      var years_arr = [{ name: parseInt(response.data[0].oldest_batch) }];
      for (
        var i = parseInt(response.data[0].oldest_batch);
        i < new Date().getFullYear();
        ++i
      ) {
        years_arr.push({ name: i + 1 });
      }
      setYears(years_arr);
    }
  }

  React.useEffect(() => {
    getBatchYears();
  }, []);

  if (type === "upload") {
    return (
      <div className={showHideClassName}>
        <section className="modal-mains modal-reset">
          <div className="modal-cont-header d-flex justify-content-center">
            <div className="modal-header-label">UPLOAD FILE</div>
          </div>
          <hr className="modal-line" />
          <div className="modal-content-body">
            <Row className="">
              <Row className='mt-4 mb-4'>
                <h5 className="subtitle g">
                  If you want to continue upload the file please convert first.
                </h5>
              </Row>
              <hr className="modal-line" />
              <div className="mt-4 right d-flex justify-content-end">
                {
                  !handleSelect ? (
                    <button className="btn-maroon left me-2" onClick={handleOnClick}>
                      CONVERT
                    </button>
                  ) : (
                  <button type="button" className="btn-green me-2" ref={uploadBtn} onClick={handleTwiceUpload} >
                    UPLOAD
                  </button>)
                }
                <button type="button" className="yellow-button ms-0" onClick={handleClose}>
                  CANCEL
                </button>
              </div>
            </Row>
          </div>
        </section>
      </div>
    );
  } else if (type === "reset-password") {
    return (
      <div className={showHideClassName}>
        <section className="modal-main modal-reset">
          <div className="modal-cont-header d-flex justify-content-center">
            <div className="modal-header-label">Reset Password</div>
          </div>
          <hr className="modal-line" />
          <div className="modal-content-body">
            <div className="input-label mt-4">Email</div>
            <input type="text" className="input-2" />
            <div className="input-label mt-4">New Password</div>
            <input type="text" className="input-2" />
            <div className="input-label mt-4">Confirm Password</div>
            <input type="text" className="input-2" />
          </div>
          <div className="btn-cont">
            <button type="button" className="btn-cancel" onClick={handleClose}>
              Cancel
            </button>
            <button type="button" className="btn-done" onClick={handleClose}>
              Done
            </button>
          </div>
        </section>
      </div>
    );
  } else if (type === "delete-alumni") {
    return (
      <div className={showHideClassName}>
        <section className="modal-nav-main modal-nav">
          <Row>
              <Col className="modal-cont-header col-11 left">
                <h4 className="maroon">DELETE ALUMNI</h4>
              </Col>
          </Row>
          <hr />
          <Row className="modal-content-body pt-2">
            <h1 className="input-subtitle center">
              Are you sure you want to delete <span className="yellow">'{data.last_name}'</span> ?
            </h1>
          </Row>
          <hr />
          <div className="mt-4 d-flex justify-content-end">
              <button type="button" className="yellow-btn me-3" onClick={handleClose}>
                Cancel
              </button>
              <button type="button" className="maroon-btn" onClick={handleDelete}>
                Delete
              </button>
          </div>
        </section>
      </div>
    );
  } 

  // USERS CRUD
  else if (type==="add-user") {
    return (
      <div className={showHideClassName}>
        <Modal show={show} onHide={handleClose} className="modal-container">
          <Modal.Header closeButton>
            <Modal.Title>
                <div className="modal-header-label">ADD USER</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Row>
                <Col xs={12} sm={6} className="mt-3">
                  <div className="form-group">
                    <p className="input-subtitle">
                      USER ROLE<span className="required-icon">*</span>
                    </p>
                    <select
                      type="text"
                      name="role_id"
                      className="form-control input-shadow"
                      onChange={(e) => handleInputChange(e)}
                    >
                      <option value="">SELECT</option>
                      <option value="1">ADMIN</option>
                      <option value="8">ALUMNI COMMITTEE</option>
                      <option value="10">COLLEGE SECRETARY</option>
                      <option value="5">COS DEAN</option>
                      <option value="4">DEPARTMENT HEAD</option>
                      <option value="6">ITC</option>
                      <option value="3">OSA</option>
                      <option value="7">OUR</option>
                      <option value="2">PIO</option>
                    </select>
                    <InputError className='left' isValid={isError.role_id} message={"This field is required"}/>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mt-3">
                  {/* ADMIN */}
                  {data.role_id === "1" && (
                    <div className="row">
                      <h6 className="title"> Remarks:</h6>
                      <div>
                        <h6 className="remark-text">
                          This role can: Manage Users, Manage Alumni, Manage Announcements, Manage
                          Projects, View Scholarships, View Reports on Employability
                          Rates, View Reports on Employment History, View Summary
                          Report on Graduate Satisfaction, View Reports on % of
                          Graduates, View Exit Interview, View Transaction/Audit
                          Logs
                        </h6>
                      </div>
                    </div>
                  )}
                  {/* PIO */}
                  {data.role_id === "2" && (
                    <div className="row">
                      <h6 className="title"> Remarks:</h6>
                      <div>
                        <h6 className="remark-text">
                          This role can: Manage Announcements, View Announcements, View Projects
                        </h6>
                      </div>
                    </div>
                  )}
                  {/* OSA */}
                  {data.role_id === "3" && (
                    <div className="row">
                      <h6 className="title"> Remarks:</h6>
                      <div>
                        <h6 className="remark-text">
                          This role can: View Projects, View Alumni Records, View Announcements,
                          View Scholarships, View Summary Report on Graduation
                          Satisfaction, View Reports on Employability Rates
                        </h6>
                      </div>
                    </div>
                  )}
                  {/* DEPARTMENT HEAD */}
                  {data.role_id === "4" && (
                    <div className="left row">
                      <h6 className="title"> Remarks:</h6>
                      <div>
                        <h6 className="remark-text">
                          This role can: View Alumni, Manage Announcements, Manage Projects, View
                          Scholarships, View Reports on Employability Rates, View
                          Reports on Employment History, View Reports on % of
                          Graduates (filtered according to department)
                        </h6>
                      </div>
                    </div>
                  )}
                  {/* COS DEAN */}
                  {data.role_id === "5" && (
                    <div className="left row">
                      <h6 className="title"> Remarks:</h6>
                      <div>
                        <h6 className="remark-text">
                          This role can: Manage Users, Manage Alumni, Manage Announcements, Manage
                          Projects, View Scholarships, View Reports on Employability
                          Rates, View Reports on Employment History, View Summary
                          Report on Graduate Satisfaction, View Reports on % of
                          Graduates
                        </h6>
                      </div>
                    </div>
                  )}
                  {/* OUR */}
                  {data.role_id === "7" && (
                    <div className="left row">
                      <h6 className="title left"> Remarks:</h6>
                      <div>
                        <h6 className="remark-text">
                          This role can: Manage Alumni, Manage Announcements, Manage Projects, View
                          Scholarships, View Reports on Employability Rates, View
                          Reports on Employment History, View Summary Report on
                          Graduate Satisfaction, View Reports on % of Graduates
                        </h6>
                      </div>
                    </div>
                  )}
                  {/* ALUMNI COMMITTEE */}
                  {(data.role_id === "8"||data.role_id==="10") && (
                    <div className="left row">
                      <h6 className="title"> Remarks:</h6>
                      <div>
                        <h6 className="remark-text">
                          View Projects, View Alumni Records, View Announcements,
                          View Scholarships, View Reports on Employability Rates,
                          View Reports on Employment History, View Summary Report on
                          Graduate Satisfaction, View Reports on % of Graduates,
                          Manage Announcements
                        </h6>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>

              <Row>
                { (data.role_id === "4"||data.role_id === "5") && (
                  <Col xs={12} sm={6} className="mt-3">
                    <div className="form-group">
                      <p className="input-subtitle">
                        DEPARTMENT<span className="required-icon">*</span>
                      </p>
                      <select
                        type="text"
                        name="department_id"
                        className="form-control input-shadow"
                        onChange={(e) => handleInputChange(e)}
                      >
                        <option value="">SELECT</option>
                        {
                          departments.map((data) => (
                            <option value={data.value}>{data.label}</option>
                          ))
                        }
                      </select>
                      <InputError className='left' isValid={isError.department_id} message={"This field is required"}/>
                    </div>
                  </Col>
                )}

                <Col xs={12} sm={6} className="mt-4">
                  <div className="form-group">
                    <p className="input-subtitle">
                      EMAIL<span className="required-icon">*</span>
                    </p>
                    <input
                        type="text"
                        name="email"
                        className="form-control  input-shadow "
                        id="email"
                        aria-describedby="email"
                        onChange={(e) => handleInputChange(e)}
                      />
                      <InputError className='left' isValid={isError.email} message={"This field is required"}/>
                  </div>
                </Col>

                <Col xs={12} sm={6} className="mt-4">
                  <div className="form-group">
                    <p className="input-subtitle">
                      LAST NAME<span className="required-icon">*</span>
                    </p>
                    <input
                      type="text"
                      name="last_name"
                      className="form-control input-shadow"
                      onChange={(e) => handleInputChange(e)}
                    ></input>
                    <InputError className='left' isValid={isError.last_name} message={"This field is required"}/>
                  </div>
                </Col>
                <Col xs={12} sm={6} className="mt-4">
                  <div className="form-group">
                    <p className="input-subtitle">
                      FIRST NAME<span className="required-icon">*</span>
                    </p>
                    <input
                      type="text"
                      name="first_name"
                      className="form-control input-shadow"
                      onChange={(e) => handleInputChange(e)}
                    ></input>
                    <InputError className='left' isValid={isError.first_name} message={"This field is required"}/>
                  </div>
                </Col>
              </Row>
            </Row>          

            <Row className="mt-5 right">
              <Col>
                <button
                  type="button"
                  className="btn-cancel right"
                  onClick={handleClose}
                >
                  CANCEL
                </button>
              </Col>
              <Col>
                {isClicked === false && (
                  <button
                    ref={saveBtn}
                    type="button"
                    className="btn-post right"
                    onClick={disableSaveBtn}
                  >
                    SAVE
                  </button>
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    )
  } else if (type === "edit-user") {
    return (
      <div className={showHideClassName}>
        <Modal show={show} onHide={handleClose} className="modal-container">
          <Modal.Header closeButton>
            <Modal.Title>
                <div className="modal-header-label">EDIT USER</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Row>
                <Col xs={12} sm={6} className="mt-3">
                  <div className="form-group">
                    <p className="input-subtitle">
                      USER ROLE<span className="required-icon">*</span>
                    </p>
                    <select
                      type="text"
                      name="role_id"
                      value={data.role_id}
                      className="form-control input-shadow"
                      onChange={(e) => handleInputChange(e)}
                    >
                      <option value="">SELECT</option>
                      <option value="1">ADMIN</option>
                      <option value="8">ALUMNI COMMITTEE</option>
                      <option value="10">COLLEGE SECRETARY</option>
                      <option value="5">COS DEAN</option>
                      <option value="4">DEPARTMENT HEAD</option>
                      <option value="6">ITC</option>
                      <option value="3">OSA</option>
                      <option value="7">OUR</option>
                      <option value="2">PIO</option>
                    </select>
                    <InputError className='left' isValid={isError.role_id} message={"This field is required"}/>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mt-3">
                  {/* ADMIN */}
                  {data.role_id === "1" && (
                    <div className="row">
                      <h6 className="title"> Remarks:</h6>
                      <div>
                        <h6 className="remark-text">
                          This role can: Manage Users, Manage Alumni, Manage Announcements, Manage
                          Projects, View Scholarships, View Reports on Employability
                          Rates, View Reports on Employment History, View Summary
                          Report on Graduate Satisfaction, View Reports on % of
                          Graduates, View Exit Interview, View Transaction/Audit
                          Logs
                        </h6>
                      </div>
                    </div>
                  )}
                  {/* PIO */}
                  {data.role_id === "2" && (
                    <div className="row">
                      <h6 className="title"> Remarks:</h6>
                      <div>
                        <h6 className="remark-text">
                          This role can: Manage Announcements, View Announcements, View Projects
                        </h6>
                      </div>
                    </div>
                  )}
                  {/* OSA */}
                  {data.role_id === "3" && (
                    <div className="row">
                      <h6 className="title"> Remarks:</h6>
                      <div>
                        <h6 className="remark-text">
                          This role can: View Projects, View Alumni Records, View Announcements,
                          View Scholarships, View Summary Report on Graduation
                          Satisfaction, View Reports on Employability Rates
                        </h6>
                      </div>
                    </div>
                  )}
                  {/* DEPARTMENT HEAD */}
                  {data.role_id === "4" && (
                    <div className="left row">
                      <h6 className="title"> Remarks:</h6>
                      <div>
                        <h6 className="remark-text">
                          This role can: View Alumni, Manage Announcements, Manage Projects, View
                          Scholarships, View Reports on Employability Rates, View
                          Reports on Employment History, View Reports on % of
                          Graduates (filtered according to department)
                        </h6>
                      </div>
                    </div>
                  )}
                  {/* COS DEAN */}
                  {data.role_id === "5" && (
                    <div className="left row">
                      <h6 className="title"> Remarks:</h6>
                      <div>
                        <h6 className="remark-text">
                          This role can: Manage Users, Manage Alumni, Manage Announcements, Manage
                          Projects, View Scholarships, View Reports on Employability
                          Rates, View Reports on Employment History, View Summary
                          Report on Graduate Satisfaction, View Reports on % of
                          Graduates
                        </h6>
                      </div>
                    </div>
                  )}
                  {/* OUR */}
                  {data.role_id === "7" && (
                    <div className="left row">
                      <h6 className="title left"> Remarks:</h6>
                      <div>
                        <h6 className="remark-text">
                          This role can: Manage Alumni, Manage Announcements, Manage Projects, View
                          Scholarships, View Reports on Employability Rates, View
                          Reports on Employment History, View Summary Report on
                          Graduate Satisfaction, View Reports on % of Graduates
                        </h6>
                      </div>
                    </div>
                  )}
                  {/* ALUMNI COMMITTEE */}
                  {(data.role_id === "8"||data.role_id==="10") && (
                    <div className="left row">
                      <h6 className="title"> Remarks:</h6>
                      <div>
                        <h6 className="remark-text">
                          View Projects, View Alumni Records, View Announcements,
                          View Scholarships, View Reports on Employability Rates,
                          View Reports on Employment History, View Summary Report on
                          Graduate Satisfaction, View Reports on % of Graduates,
                          Manage Announcements
                        </h6>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>

              <Row>
                { (data.role_id === "5"||data.role_id === "4") && (
                  <Col xs={12} sm={6} className="mt-3">
                    <div className="form-group">
                      <p className="input-subtitle">
                        DEPARTMENT<span className="required-icon">*</span>
                      </p>
                      <select
                        type="text"
                        name="department_id"
                        value={data.department_id}
                        className="form-control input-shadow"
                        onChange={(e) => handleInputChange(e)}
                      >
                        <option value="">SELECT</option>
                        {
                          departments.map((data) => (
                            <option value={data.value}>{data.label}</option>
                          ))
                        }
                      </select>
                      <InputError className='left' isValid={isError.department_id} message={"This field is required"}/>
                    </div>
                  </Col>
                )}

                <Col xs={12} sm={6} className="mt-4">
                  <div className="form-group">
                    <p className="input-subtitle">
                      EMAIL<span className="required-icon">*</span>
                    </p>
                    <input
                        type="text"
                        name="email"
                        className="form-control  input-shadow "
                        id="email"
                        value={data.email}
                        aria-describedby="email"
                        onChange={(e) => handleInputChange(e)}
                      />
                      <InputError className='left' isValid={isError.email} message={"This field is required"}/>
                  </div>
                </Col>

                <Col xs={12} sm={6} className="mt-4">
                  <div className="form-group">
                    <p className="input-subtitle">
                      LAST NAME<span className="required-icon">*</span>
                    </p>
                    <input
                      type="text"
                      name="last_name"
                      value={data.last_name}
                      className="form-control input-shadow"
                      onChange={(e) => handleInputChange(e)}
                    ></input>
                    <InputError className='left' isValid={isError.last_name} message={"This field is required"}/>
                  </div>
                </Col>
                <Col xs={12} sm={6} className="mt-4">
                  <div className="form-group">
                    <p className="input-subtitle">
                      FIRST NAME<span className="required-icon">*</span>
                    </p>
                    <input
                      type="text"
                      name="first_name"
                      value={data.first_name}
                      className="form-control input-shadow"
                      onChange={(e) => handleInputChange(e)}
                    ></input>
                    <InputError className='left' isValid={isError.first_name} message={"This field is required"}/>
                  </div>
                </Col>
              </Row>
            </Row>          

            <Row className="mt-5 right">
              <Col>
                <button
                  type="button"
                  className="btn-cancel right"
                  onClick={handleClose}
                >
                  CANCEL
                </button>
              </Col>
              <Col>
                {isClicked === false && (
                  <button
                    type="button"
                    className="btn-post right"
                    onClick={handleUpdate}
                  >
                    SAVE
                  </button>
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    )
    // return (
    //   <div className={showHideClassName}>
    //     <section className="modal-nav-main modal-nav">
    //       <div className="row">
    //         <div className="modal-cont-header col-10 left">
    //           <div className="modal-header-label bold">EDIT USER</div>
    //           <div className="col-4"></div>
    //         </div>
    //         <div className="modal-cont-header col-2">
    //           <img alt="" src={close} className="close-icon" onClick={handleClose} />
    //         </div>
    //         <hr />
    //         <div className="row pt-4">
    //           <p className=" left green">
    //             USER ROLE<span className="required-icon">*</span>
    //           </p>
    //           <div className="col-5 pt-0">
    //             <select
    //               type="text"
    //               name="role_id"
    //               value={data.role_id}
    //               className="form-control input-shadow"
    //               onChange={(e) => handleInputChange(e)}
    //             >
    //               <option value="">SELECT</option>
    //               <option value="1">ADMIN</option>
    //               <option value="2">PIO</option>
    //               <option value="3">OSA</option>
    //               <option value="4">DEPARTMENT HEAD</option>
    //               <option value="5">COS DEAN</option>
    //               <option value="6">ITC</option>
    //             </select>
    //             <InputError className='left' isValid={isError.role_id} message={"This field is required"}/>
    //           </div>
    //           <p className=" left green mt-4">
    //             EMAIL<span className="required-icon">*</span>
    //           </p>
    //           <div className="col-6 pt-0">
    //             <input
    //               type="text"
    //               name="email"
    //               value={data.email}
    //               className="form-control  input-shadow "
    //               id="email"
    //               aria-describedby="up_email"
    //               onChange={(e) => handleInputChange(e)}
    //             />
    //             <InputError className='left' isValid={isError.email} message={"This field is required"}/>
    //           </div>
    //           <div className="row mt-4">
    //             <div className="col-5">
    //               <p className=" left green">
    //                 LAST NAME<span className="required-icon">*</span>
    //               </p>
    //               <div className="col- pt-0">
    //                 <input
    //                   type="text"
    //                   name="last_name"
    //                   value={data.last_name}
    //                   className="form-control input-shadow"
    //                   onChange={(e) => handleInputChange(e)}
    //                 />
    //                 <InputError className='left' isValid={isError.last_name} message={"This field is required"}/>
    //               </div>
    //             </div>
    //             <div className="col-5 ms-3">
    //               <p className=" left green">
    //                 FIRST NAME<span className="required-icon">*</span>
    //               </p>
    //               <div className="col- pt-0">
    //                 <input
    //                   type="text"
    //                   name="first_name"
    //                   id="first_name"
    //                   value={data.first_name}
    //                   className="form-control input-shadow"
    //                   onChange={(e) => handleInputChange(e)}
    //                 />
    //                 <InputError className='left' isValid={isError.first_name} message={"This field is required"}/>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="row mt-3">
    //         <div className="col-6"></div>
    //         <div className="col-3 right pt-4">
    //           <button ref={updateBtn} type="button" className="btn-post" onClick={handleTwiceUpdate}>
    //             UPDATE
    //           </button>
    //         </div>
    //         <div className="col-3 right pt-4">
    //           <button
    //             type="button"
    //             className="ms-5 btn-cancel right"
    //             onClick={handleClose}
    //           >
    //             CANCEL
    //           </button>
    //         </div>
    //       </div>
    //     </section>
    //   </div>
    // );
  } else if (type === "deactivate-user") {
    return (
      <div className={showHideClassName}>
        <section className="modal-nav-main modal-nav">
          <Row>
            <h4 className="maroon">DEACTIVATE USER</h4>
          </Row>
          <hr />
          <Row className="modal-content-body pt-2">
            <h1 className="input-subtitle center">
              Are you sure you want to deactivate <span className="yellow">{data.email}</span> ?
            </h1>      
          </Row>
          <hr />
          <div className="mt-4 d-flex justify-content-end">
              <button type="button" className="yellow-btn me-3" onClick={handleClose}>
                Cancel
              </button>
              <button type="button" className="maroon-btn" onClick={handleDelete}>
                Deactivate
              </button>
          </div>
        </section>
      </div>
    );
  } else if (type === "delete-user") {
    return (
      <div className={showHideClassName}>
        <section className="modal-nav-main modal-nav">
          <Row>
            <h4 className="maroon">DELETE USER</h4>
          </Row>
          <hr />
          <Row className="modal-content-body pt-2">
            <h1 className="input-subtitle center">
              Are you sure you want to delete <span className="yellow">{data.email}</span> ?
            </h1>      
          </Row>
          <hr />
          <div className="mt-4 d-flex justify-content-end">
              <button type="button" className="yellow-btn me-3" onClick={handleClose}>
                Cancel
              </button>
              <button type="button" className="maroon-btn" onClick={handleDelete}>
                Delete
              </button>
          </div>
        </section>
      </div>
    );
  } else if (type === "activate-user") {
    return (
      <div className={showHideClassName}>
        <section className="modal-nav-main modal-nav">
          <Row>
            <h4 className="maroon">ACTIVATE USER</h4>
          </Row>
          <hr />
          <Row className="modal-content-body pt-2">
            <h1 className="input-subtitle center">
              Are you sure you want to activate <span className="yellow">{data.email}</span> ?
            </h1>      
          </Row>
          <hr />
          <div className="mt-4 d-flex justify-content-end">
              <button type="button" className="yellow-btn me-3" onClick={handleClose}>
                Cancel
              </button>
              <button type="button" className="maroon-btn" onClick={handleUpdate}>
                Activate
              </button>
          </div>
        </section>
      </div>
    );
  }

  // PROJECTS CRUD
  else if (type === "add-project") {
    return (
      <div className={showHideClassName}>
        <Modal show={show} onHide={handleClose} className="modal-container">
          <Modal.Header closeButton>
            <Modal.Title>
                <div className="modal-header-label">ADD PROJECT</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    PROJECT NAME<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="name"
                    className="form-control  input-shadow "
                    id="subject"
                    aria-describedby="subject"
                    onChange={(e) => handleInputChange(e)}
                  />
                   <InputError className='left' isValid={isError.name} message={"This field is required"}/>
                </div>
              </Col>

              <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    PROJECT HEAD<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="project_head"
                    className="form-control  input-shadow "
                    id="subject"
                    aria-describedby="subject"
                    onChange={(e) => handleInputChange(e)}
                  />
                   <InputError className='left' isValid={isError.project_head} message={"This field is required"}/>
                </div>
              </Col>

              <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    PROJECT DATE
                  </p>
                  <input
                    type="date"
                    name="date"
                    className="form-control faded-green-bg input-shadow"
                    id="date_from"
                    aria-describedby="date_from"
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    BUDGET
                  </p>
                  <input
                    type="text"
                    name="budget"
                    className="form-control  input-shadow "
                    id="subject"
                    aria-describedby="subject"
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
              </Col>

              <Col xs={6} sm={8} className="mt-4">
                <input
                  type="checkbox"
                  name="in_kind"
                  className="input-label"
                  id="in_kind"
                  aria-describedby="subject"
                  onChange={(e) => handleInputChange(e)}
                />
                <span className=" custom-control-label input-title ms-2">
                  ACCEPT IN KIND DONATIONS
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    CONTACT PERSON <span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="contact_person"
                    className="form-control  input-shadow "
                    id="subject"
                    aria-describedby="subject"
                    onChange={(e) => handleInputChange(e)}
                  />
                   <InputError className='left' isValid={isError.contact_person} message={"This field is required"}/>
                </div>
              </Col>
              
              <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    EMAIL<span className="required-icon">*</span>
                  </p>
                   <input
                    type="text"
                    name="email"
                    className="form-control  input-shadow "
                    id="subject"
                    aria-describedby="subject"
                    onChange={(e) => handleInputChange(e)}
                  />
                   <InputError className='left' isValid={isError.email} message={"This field is required"}/>
                </div>
              </Col>

               <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    DEPARTMENT<span className="required-icon">*</span>
                  </p>
                  <select
                    type="text"
                    name="department_id"
                    className="form-control input-shadow"
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="">SELECT</option>
                    {departments.map((depts) => (
                      <option className="dropdown-options" value={depts.value}>
                        {depts.label}
                      </option>
                    ))}
                  </select>
                   <InputError className='left' isValid={isError.department_id} message={"This field is required"}/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle left">
                  TAG<span className="required-icon">*</span>
                </span>
                <input
                  type="text"
                  name="tag_1"
                  className="mt-1 form-control  input-shadow "
                  id="subject"
                  aria-describedby="subject"
                  onChange={(e) => handleInputChange(e)}
                />
                 <InputError className='left' isValid={isError.tag_1} message={"This field is required"}/>
              </Col>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle left">TAG 2 (Optional)</span>
                <input
                  type="text"
                  name="tag_2"
                  className="mt-1 form-control  input-shadow "
                  id="subject"
                  aria-describedby="subject"
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle left">TAG 3 (Optional)</span>
                <input
                  type="text"
                  name="tag_3"
                  className="mt-1 form-control  input-shadow "
                  id="subject"
                  aria-describedby="subject"
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle left">TAG 4 (Optional)</span>
                <input
                  type="text"
                  name="tag_4"
                  className="mt-1 form-control  input-shadow "
                  id="subject"
                  aria-describedby="subject"
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle left">TAG 5 (Optional)</span>
                <input
                  type="text"
                  name="tag_5"
                  className="mt-1 form-control  input-shadow "
                  id="subject"
                  aria-describedby="subject"
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Row>
                <span className="input-subtitle left">DESCRIPTION </span>
              </Row>
              <Row>
                <textarea
                  type="text"
                  name="description"
                  className=" form-control  input-shadow input-desc "
                  id="content"
                  aria-describedby="content"
                  onChange={(e) => handleInputChange(e)}
                />
              </Row>
            </Row>

            <Row className="pt-3 right">
              <Col>
                <button
                  type="button"
                  className="btn-cancel right"
                  onClick={handleClose}
                >
                  CANCEL
                </button>
              </Col>
              <Col>
                {isClicked === false && (
                  <button
                    ref={saveBtn}
                    type="button"
                    className="btn-post right"
                    onClick={disableSaveBtn}
                  >
                    SAVE
                  </button>
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  } else if (type === "edit-project") {
    return (
      <div className={showHideClassName}>
        <Modal show={show} onHide={handleClose} className="modal-container">
          <Modal.Header closeButton>
            <Modal.Title>
                <div className="modal-header-label">EDIT PROJECT</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    PROJECT NAME<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="name"
                    className="form-control  input-shadow "
                    id="subject"
                    value={data.name}
                    aria-describedby="subject"
                    onChange={(e) => handleInputChange(e)}
                  />
                   <InputError className='left' isValid={isError.name} message={"This field is required"}/>
                </div>
              </Col>

              <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    PROJECT HEAD<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="project_head"
                    className="form-control  input-shadow "
                    id="subject"
                    value={data.project_head}
                    aria-describedby="subject"
                    onChange={(e) => handleInputChange(e)}
                  />
                   <InputError className='left' isValid={isError.project_head} message={"This field is required"}/>
                </div>
              </Col>

              <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    PROJECT DATE
                  </p>
                  <input
                    type="date"
                    name="date"
                    className="form-control faded-green-bg input-shadow"
                    id="date_from"
                    value={data.date}
                    aria-describedby="date_from"
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    BUDGET
                  </p>
                  <input
                    type="text"
                    name="budget"
                    className="form-control  input-shadow "
                    id="subject"
                    value={data.budget}
                    aria-describedby="subject"
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
              </Col>

              <Col xs={6} sm={8} className="mt-4">
                <input
                  type="checkbox"
                  name="in_kind"
                  className="input-label"
                  id="in_kind"
                  value={data.is_in_kind}
                  aria-describedby="subject"
                  onChange={(e) => handleInputChange(e)}
                />
                <span className=" custom-control-label input-title ms-2">
                  ACCEPT IN KIND DONATIONS
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    CONTACT PERSON <span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="contact_person"
                    className="form-control  input-shadow "
                    value={data.contact_person}
                    onChange={(e) => handleInputChange(e)}
                  />
                   <InputError className='left' isValid={isError.contact_person} message={"This field is required"}/>
                </div>
              </Col>
              
              <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    EMAIL<span className="required-icon">*</span>
                  </p>
                   <input
                    type="text"
                    name="email"
                    className="form-control  input-shadow "
                    value={data.email}
                    onChange={(e) => handleInputChange(e)}
                  />
                   <InputError className='left' isValid={isError.email} message={"This field is required"}/>
                </div>
              </Col>

               <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    DEPARTMENT<span className="required-icon">*</span>
                  </p>
                  <select
                    type="text"
                    name="department_id"
                    value={data.department_id}
                    className="form-control input-shadow"
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="">SELECT</option>
                    {departments.map((depts) => (
                      <option className="dropdown-options" value={depts.value}>
                        {depts.label}
                      </option>
                    ))}
                  </select>
                   <InputError className='left' isValid={isError.department_id} message={"This field is required"}/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle left">
                  TAG<span className="required-icon">*</span>
                </span>
                <input
                  type="text"
                  name="tag_1"
                  className="mt-1 form-control  input-shadow "
                  value={data.tag_1}
                  onChange={(e) => handleInputChange(e)}
                />
                 <InputError className='left' isValid={isError.tag_1} message={"This field is required"}/>
              </Col>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle left">TAG 2 (Optional)</span>
                <input
                  type="text"
                  name="tag_2"
                  className="mt-1 form-control  input-shadow "
                  value={data.tag_2}
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle left">TAG 3 (Optional)</span>
                <input
                  type="text"
                  name="tag_3"
                  className="mt-1 form-control  input-shadow "
                  value={data.tag_3}
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle left">TAG 4 (Optional)</span>
                <input
                  type="text"
                  name="tag_4"
                  value={data.tag_4}
                  className="mt-1 form-control  input-shadow "
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle left">TAG 5 (Optional)</span>
                <input
                  type="text"
                  name="tag_5"
                  value={data.tag_5}
                  className="mt-1 form-control  input-shadow "
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Row>
                <span className="input-subtitle left">DESCRIPTION </span>
              </Row>
              <Row>
                <textarea
                  type="text"
                  name="description"
                  className=" form-control  input-shadow input-desc "
                  value={data.description}
                  onChange={(e) => handleInputChange(e)}
                />
              </Row>
            </Row>

            <Row className="pt-3 right">
              <Col>
                <button
                  type="button"
                  className="btn-cancel right"
                  onClick={handleClose}
                >
                  CANCEL
                </button>
              </Col>
              <Col>
                {isClicked === false && (
                  <button
                    ref={saveBtn}
                    type="button"
                    className="btn-post right"
                    onClick={handleUpdate}
                  >
                    SAVE
                  </button>
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  } else if (type === "delete-project") {
    return (
      <div className={showHideClassName}>
        <section className="modal-nav-main modal-nav">
          <Row>
            <h4 className="maroon">DELETE PROJECT</h4>
          </Row>
          <hr />
          <Row className="modal-content-body pt-2">
            <h1 className="input-subtitle center">
              Are you sure you want to delete <span className="yellow">{data.name}</span> ?
            </h1>
          </Row>
          <hr />
          <div className="mt-4 d-flex justify-content-end">
              <button type="button" className="yellow-btn me-3" onClick={handleClose}>
                Cancel
              </button>
              <button type="button" className="maroon-btn" onClick={handleDelete}>
                Delete
              </button>
          </div>
        </section>
      </div>
    );
  } else if (type === "view-project-pledge") {
    return (
      <div className={showHideClassName}>
        <section className="modal-nav-main modal-nav">
          <div className="row">
            <div className="modal-cont-header col-8 left">
              <div className="modal-header-label">PROJECT NAME</div>
              <div className="input-subtitle  black">PROJECT HEAD</div>
            </div>
            <div className="modal-cont-header ms-5 col-1 ">
              <input
                className="input-subtitle no-border maroon me-5"
                type="date"
              ></input>
            </div>
          </div>
          {/* <div className="row mt-5  ">
                <div className="modal-cont-header">
                <ProgressBar customLabel="PHP 80,000.00 of 100,000.00" completed={80000} maxCompleted={100000} />
                </div>
            </div> */}
          <div className="modal-content-body mt-3 ">
            <div className="input-subtitle mb-3 black">Total Pledges: </div>
            <div className="modal-cont-header col-8 left">
              <h2 className="green-bold">PLEDGEES</h2>
            </div>
            <div className="row mb-4">
              <Table
                type={"pledge"}
                tableData={data}
                clickable={true}
                headingColumns={["PLEDGEE NAME", "DATE PLEDGE", "AMOUNT"]}
              />
            </div>
          </div>
          <div className="row right">
            <div className="col-5">
              <button
                type="button"
                className="yellow-btn"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </section>
      </div>
    );
  } 

  // DEPARTMENTS CRUD
  else if (type === "add-department") {
    return (
      <div className={showHideClassName}>
        <Modal show={show} onHide={handleClose} className="modal-container">
          <Modal.Header closeButton>
            <Modal.Title>
                <div className="modal-header-label">ADD DEPARTMENT</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="pt-3 ms-1">
                <Row>
                  <span className="input-subtitle left">
                    DEPARTMENT NAME <span className="required-icon">*</span>
                  </span>
                </Row>
                <Row className="me-1">
                  <input
                    type="text"
                    name="department_name"
                    className="form-control input-shadow "
                    id="department_name"
                    aria-describedby="subject"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <InputError className='left' isValid={isError.department_name} message={"This field is required"}/>
                </Row>
            </Row>

            <Row className="pt-3 ms-1">
                <Row>
                  <span className="input-subtitle left">
                    COLLEGE <span className="required-icon">*</span>
                  </span>
                </Row>
                <Row className="me-1">
                  <select
                    type="text"
                    name="college_id"
                    className="form-control input-shadow"
                    id="college_id"
                    aria-describedby="subject"
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value=''>Select</option>
                    {
                      colleges.map((data) => (
                        <option value={data.value}>{data.label}</option>
                      ))
                    }
                  </select>
                  <InputError className='left' isValid={isError.college_id} message={"This field is required"}/>
                </Row>
            </Row>

            <Row className="mt-5 right">
              <Col>
                <button
                  type="button"
                  className="btn-cancel right"
                  onClick={handleClose}
                >
                  CANCEL
                </button>
              </Col>
              <Col>
                {isClicked === false && (
                  <button
                    ref={saveBtn}
                    type="button"
                    className="btn-post right"
                    onClick={disableSaveBtn}
                  >
                    SAVE
                  </button>
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  } else if (type === "edit-department") {
    return (
      <div className={showHideClassName}>
        <Modal show={show} onHide={handleClose} className="modal-container">
          <Modal.Header closeButton>
            <Modal.Title>
                <div className="modal-header-label">EDIT DEPARTMENT</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="pt-2 ms-1">
                <Row>
                  <span className="input-subtitle left">
                    DEPARTMENT NAME <span className="required-icon">*</span>
                  </span>
                </Row>
                <Row className="me-1">
                  <input
                    type="text"
                    name="department_name"
                    id="department_name"
                    className="form-control input-shadow"
                    value={data.department_name}
                    aria-describedby="department_name"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <InputError className='left' isValid={isError.department_name} message={"This field is required"}/>
                </Row>
            </Row>

            <Row className="pt-3 ms-1">
                <Row>
                  <span className="input-subtitle left">
                    COLLEGE <span className="required-icon">*</span>
                  </span>
                </Row>
                <Row className="me-1">
                  <select
                    type="text"
                    name="college_id"
                    value={data.college_id}
                    className="form-control input-subtitle input-shadow"
                    id="college_id"
                    aria-describedby="subject"
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value=''>Select</option>
                    {
                      colleges.map((data) => (
                        <option value={data.value}>{data.label}</option>
                      ))
                    }
                  </select>
                  <InputError className='left' isValid={isError.college_id} message={"This field is required"}/>
                </Row>
            </Row>

            <Row className="mt-5 right">
              <Col>
                <button
                  type="button"
                  className="btn-cancel right"
                  onClick={handleClose}
                >
                  CANCEL
                </button>
              </Col>
              <Col>
                {isClicked === false && (
                  <button ref={updateBtn} type="button" className="btn-post right" onClick={handleTwiceUpdate}>
                    UPDATE
                  </button>
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  } else if (type === "delete-department") {
    return (
      <div className={showHideClassName}>
        <section className="modal-nav-main modal-nav">
          <Row>
            <h4 className="maroon">DELETE DEPARTMENT</h4>
          </Row>
          <hr />
          <Row className="modal-content-body pt-2">
            <h1 className="input-subtitle center">
              Are you sure you want to delete <span className="yellow">{data.department}</span> ?
            </h1>
          </Row>
          <hr />
          <div className="mt-4 d-flex justify-content-end">
              <button type="button" className="yellow-btn me-3" onClick={handleClose}>
                Cancel
              </button>
              <button type="button" className="maroon-btn" onClick={handleDelete}>
                Delete
              </button>
          </div>
        </section>
      </div>
    );
  }

  // COURSES CRUD
  else if (type === "add-course") {
    return (
      <div className={showHideClassName}>
        <Modal show={show} onHide={handleClose} className="modal-container">
          <Modal.Header closeButton>
            <Modal.Title>
                <div className="modal-header-label">ADD COURSE</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="pt-3 ms-1">
                <Row>
                  <span className="input-subtitle left">
                    COURSE NAME<span className="required-icon">*</span>
                  </span>
                </Row>
                <Row className="me-1">
                  <input
                    type="text"
                    name="course_name"
                    className="form-control input-shadow "
                    id="course_name"
                    aria-describedby="course_name"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <InputError className='left' isValid={isError.course_name} message={"This field is required"}/>
                </Row>
            </Row>

            <Row className="pt-3 ms-1">
                <Row>
                  <span className="input-subtitle left">
                    DEPARTMENT<span className="required-icon">*</span>
                  </span>
                </Row>
                <Row className="me-1">
                   <select
                    type="text"
                    name="department_id"
                    className="form-control input-subtitle input-shadow"
                    id="department_id"
                    aria-describedby="department_id"
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value=''>Select</option>
                    {
                      departments.map((data) => (
                        <option value={data.value}>{data.label}</option>
                      ))
                    }
                  </select>
                  <InputError className='left' isValid={isError.department_id} message={"This field is required"}/>
                </Row>
            </Row>

            <Row className="mt-5 right">
              <Col>
                <button
                  type="button"
                  className="btn-cancel right"
                  onClick={handleClose}
                >
                  CANCEL
                </button>
              </Col>
              <Col>
                {isClicked === false && (
                  <button
                    ref={saveBtn}
                    type="button"
                    className="btn-post right"
                    onClick={disableSaveBtn}
                  >
                    SAVE
                  </button>
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  } else if (type === "edit-course") {
    return (
      <div className={showHideClassName}>
        <Modal show={show} onHide={handleClose} className="modal-container">
          <Modal.Header closeButton>
            <Modal.Title>
                <div className="modal-header-label">EDIT COURSE</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="pt-3 ms-1">
                <Row>
                  <span className="input-subtitle left">
                    COURSE NAME<span className="required-icon">*</span>
                  </span>
                </Row>
                <Row className="me-1">
                  <input
                    type="text"
                    name="course_name"
                    className="form-control input-shadow "
                    id="course_name"
                    value={data.course_name}
                    aria-describedby="course_name"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <InputError className='left' isValid={isError.course_name} message={"This field is required"}/>
                </Row>
            </Row>

            <Row className="pt-3 ms-1">
                <Row>
                  <span className="input-subtitle left">
                    DEPARTMENT<span className="required-icon">*</span>
                  </span>
                </Row>
                <Row className="me-1">
                   <select
                    type="text"
                    name="department_id"
                    className="form-control input-subtitle input-shadow"
                    id="department_id"
                    value={data.department_id}
                    aria-describedby="department_id"
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option hidden disabled>Select</option>
                    {
                      departments.map((data) => (
                        <option value={data.value}>{data.label}</option>
                      ))
                    }
                  </select>
                  <InputError className='left' isValid={isError.department_id} message={"This field is required"}/>
                </Row>
            </Row>

            <Row className="mt-5 right">
              <Col>
                <button
                  type="button"
                  className="btn-cancel right"
                  onClick={handleClose}
                >
                  CANCEL
                </button>
              </Col>
              <Col>
                {isClicked === false && (
                  <button
                    ref={updateBtn}
                    type="button"
                    className="btn-post right"
                    onClick={handleUpdate}
                  >
                    UPDATE
                  </button>
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  } else if (type === "delete-course") {
    return (
      <div className={showHideClassName}>
        <section className="modal-nav-main modal-nav">
          <Row>
            <h4 className="maroon">DELETE COURSE</h4>
          </Row>
          <hr />
          <Row className="modal-content-body pt-2">
            <h1 className="input-subtitle center">
              Are you sure you want to delete <span className="yellow">{data.degree_program}</span> ?
            </h1>
          </Row>
          <hr />
          <div className="mt-4 d-flex justify-content-end">
              <button type="button" className="yellow-btn me-3" onClick={handleClose}>
                Cancel
              </button>
              <button type="button" className="maroon-btn" onClick={handleDelete}>
                Delete
              </button>
          </div>
        </section>
      </div>
    );
  }

  // ANNOUNCEMENT CRUDS
  else if (type === "add-announcement") {
    return (
      <div className={showHideClassName}>
        <Modal show={show} onHide={handleClose} className="modal-container">
          <Modal.Header closeButton>
            <Modal.Title>
                <div className="modal-header-label">ADD ANNOUNCEMENT</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
            <Row>
              <Col xs={12} sm={4}>
                <div className="form-group">
                  <p className="input-subtitle">
                    POSTING DATE<span className="required-icon"> *</span>
                  </p>
                  <input
                    type="date"
                    name="date_posted"
                    className="form-control input-date input-subtitle input-shadow"
                    id="date_posted"
                    aria-describedby="date_posted"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <InputError className='left' isValid={isError.date_posted} message={"This field is required"}/>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="form-group">
                  <p className="input-subtitle">
                    DEPARTMENT<span className="required-icon"> *</span>
                  </p>
                  <select
                    type="text"
                    name="department"
                    className="form-control"
                    id="department"
                    aria-describedby="department"
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value=''>Select</option>
                    {departments.map((depts) => (
                      <option className="dropdown-options" value={depts.value}>
                        {depts.label}
                      </option>
                    ))}
                  </select>
                  <InputError className='left' isValid={isError.department} message={"This field is required"}/>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="form-group">
                  <p className="input-subtitle">
                    BATCH<span className="required-icon"> *</span>
                  </p>
                  <select
                    type="text"
                    name="batch"
                    className="form-control"
                    id="batch"
                    aria-describedby="batch"
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value=''>All</option>
                    {years.map((options) => (
                      <option value={options.name}>{options.name}</option>
                    ))}
                  </select>
                  <InputError className='left' isValid={isError.batch} message={"This field is required"}/>
                </div>
              </Col>
            </Row>
            
            <Row className="mt-4">
              <Col xs={12} sm={6}>
                <div className="form-group">
                  <p className="input-subtitle">
                    SUBJECT<span className="required-icon"> *</span>
                  </p>
                  <input
                    type="text"
                    name="subject"
                    className="form-control input-text input-shadow"
                    id="subject"
                    aria-describedby="subject"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <InputError className='left' isValid={isError.subject} message={"This field is required"}/>
                </div>
              </Col>
            </Row>

            <Row className="pt-4">
              <Row>
                <span className="input-subtitle left">
                  CONTENT <span className="required-icon">*</span>
                </span>
              </Row>
              <Row>
                <textarea
                  as="textarea"
                  name="content"
                  className="ms-2 form-control cont-content input-shadow"
                  id="content"
                  aria-describedby="content"
                  onChange={(e) => handleInputChange(e)}
                />
                <InputError className='left' isValid={isError.content} message={"This field is required"}/>
              </Row>
            </Row>
          </Row>

          <Row className="mt-5 right">
              <Col>
                <button
                  type="button"
                  className="btn-cancel right"
                  onClick={handleClose}
                >
                  CANCEL
                </button>
              </Col>
              <Col>
                {isClicked === false && (
                  <button
                    ref={saveBtn}
                    type="button"
                    className="btn-post right"
                    onClick={disableSaveBtn}
                  >
                    POST
                  </button>
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  } else if (type === "edit-announcement") {
    return (
      <div className={showHideClassName}>
        <Modal show={show} onHide={handleClose} className="modal-container">
          <Modal.Header closeButton>
            <Modal.Title>
                <div className="modal-header-label">EDIT ANNOUNCEMENT</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
            <Row>
              <Col xs={12} sm={4}>
                <div className="form-group">
                  <p className="input-subtitle">
                    POSTING DATE<span className="required-icon"> *</span>
                  </p>
                  <input
                    type="date"
                    name="date_posted"
                    className="form-control input-date input-subtitle input-shadow"
                    id="date_posted"
                    value={data.date_posted}
                    aria-describedby="date_posted"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <InputError className='left' isValid={isError.date_posted} message={"This field is required"}/>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="form-group">
                  <p className="input-subtitle">
                    DEPARTMENT<span className="required-icon"> *</span>
                  </p>
                  <select
                    type="text"
                    name="department"
                    className="form-control"
                    id="department"
                    value={data.department}
                    aria-describedby="department"
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value=''>Select</option>
                    {departments.map((depts) => (
                      <option className="dropdown-options" value={depts.value}>
                        {depts.label}
                      </option>
                    ))}
                  </select>
                  <InputError className='left' isValid={isError.department} message={"This field is required"}/>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="form-group">
                  <p className="input-subtitle">
                    BATCH<span className="required-icon"> *</span>
                  </p>
                  <select
                    type="text"
                    name="batch"
                    className="form-control"
                    id="batch"
                    value={data.batch}
                    aria-describedby="batch"
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value=''>All</option>
                    {years.map((options) => (
                      <option value={options.name}>{options.name}</option>
                    ))}
                  </select>
                  <InputError className='left' isValid={isError.batch} message={"This field is required"}/>
                </div>
              </Col>
            </Row>
            
            <Row className="mt-4">
              <Col xs={12} sm={6}>
                <div className="form-group">
                  <p className="input-subtitle">
                    SUBJECT<span className="required-icon"> *</span>
                  </p>
                  <input
                    type="text"
                    name="subject"
                    className="form-control input-text input-shadow"
                    id="subject"
                    value={data.subject}
                    aria-describedby="subject"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <InputError className='left' isValid={isError.subject} message={"This field is required"}/>
                </div>
              </Col>
            </Row>

            <Row className="pt-4">
              <Row>
                <span className="input-subtitle left">
                  CONTENT <span className="required-icon">*</span>
                </span>
              </Row>
              <Row>
                <textarea
                  as="textarea"
                  name="content"
                  className="ms-2 form-control cont-content input-shadow"
                  id="content"
                  value={data.content}
                  aria-describedby="content"
                  onChange={(e) => handleInputChange(e)}
                />
                <InputError className='left' isValid={isError.content} message={"This field is required"}/>
              </Row>
            </Row>
          </Row>

          <Row className="mt-5 right">
              <Col>
                <button
                  type="button"
                  className="btn-cancel right"
                  onClick={handleClose}
                >
                  CANCEL
                </button>
              </Col>
              <Col>
                {isClicked === false && (
                  <button
                    ref={saveBtn}
                    type="button"
                    className="btn-post right"
                    onClick={handleUpdate}
                  >
                    POST
                  </button>
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  } else  if (type === "delete-announcement") {
    return (
      <div className={showHideClassName}>
        <section className="modal-nav-main modal-nav">
          <Row>
            <h4 className="maroon">DELETE ANNOUNCEMENT</h4>
          </Row>
          <hr />
          <Row className="modal-content-body pt-2">
            <h1 className="input-subtitle center">
              Are you sure you want to delete this announcement?
            </h1>
          </Row>
          <hr />
          <div className="mt-4 d-flex justify-content-end">
              <button type="button" className="yellow-btn me-3" onClick={handleClose}>
                Cancel
              </button>
              <button type="button" className="maroon-btn" onClick={handleDelete}>
                Delete
              </button>
          </div>
        </section>
      </div>
    );
  }

  // SCHOLARSHIP CRUDS
  else if (type === "add-scholarship") {
    return (
      <div className={showHideClassName}>
        <Modal show={show} onHide={handleClose} className="modal-container">
          <Modal.Header closeButton>
            <Modal.Title>
                <div className="modal-header-label">ADD SCHOLARSHIP</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    NAME<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="name"
                    className="form-control input-shadow "
                    value={data.name}
                    id="scholarship_name"
                    aria-describedby="subject"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <InputError className='left' isValid={isError.name} message={"This field is required"}/>
                </div>
              </Col>
              <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    CONTACT PERSON<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="contact_person"
                    className="form-control input-shadow "
                    id="contact_person"
                    aria-describedby="contact_person"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <InputError className='left' isValid={isError.contact_person} message={"This field is required"}/>
                </div>
              </Col>

              <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    TARGET DATE
                  </p>
                  <input
                    type="date"
                    name="target_date"
                    className="form-control faded-green-bg project-date input-shadow"
                    value={data.target_date}
                    id="date_from"
                    aria-describedby="date_from"
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
              </Col>

              <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    BUDGET
                  </p>
                    <input
                      type="text"
                      name="budget"
                      className="form-control input-text input-shadow "
                      id="subject"
                      aria-describedby="subject"
                      onChange={(e) => handleInputChange(e)}
                    />
                </div>
              </Col>

                <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    EMAIL<span className="required-icon">*</span>
                  </p>
                <input
                  type="text"
                  name="email"
                  className="form-control input-text input-shadow "
                  id="email"
                  aria-describedby="email"
                  onChange={(e) => handleInputChange(e)}
                />
                <InputError className='left' isValid={isError.email} message={"This field is required"}/>                </div>
              </Col>
          </Row>

          <Row>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle left">
                  TAG<span className="required-icon">*</span>
                </span>
                <input
                  type="text"
                  name="tag_1"
                  className="form-control input-shadow "
                  onChange={(e) => handleInputChange(e)}
                />
                 <InputError className='left' isValid={isError.tag_1} message={"This field is required"}/>
              </Col>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle">TAG 2 (Optional)</span>
                <input
                  type="text"
                  name="tag_2"
                  className="form-control input-shadow "
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle">TAG 3 (Optional)</span>
                <input
                  type="text"
                  name="tag_3"
                  className="form-control input-shadow "
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle">TAG 4 (Optional)</span>
                <input
                  type="text"
                  name="tag_4"
                  className="form-control  input-shadow "
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle">TAG 5 (Optional)</span>
                <input
                  type="text"
                  name="tag_5"
                  className="form-control  input-shadow "
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
            </Row>
          

          <Row className="pt-4 ms-1">
            <Row>
              <span className="input-subtitle left">DESCRIPTION </span>
            </Row>
            <Row>
              <textarea
                type="text"
                name="description"
                className=" form-control input-text input-shadow input-desc "
                id="description"
                aria-describedby="description"
                onChange={(e) => handleInputChange(e)}
              />
            </Row>
          </Row>

            <Row className="mt-5 right">
              <Col>
                <button
                  type="button"
                  className="btn-cancel right"
                  onClick={handleClose}
                >
                  CANCEL
                </button>
              </Col>
              <Col>
                {isClicked === false && (
                  <button
                    ref={saveBtn}
                    type="button"
                    className="btn-post right"
                    onClick={disableSaveBtn}
                  >
                    SAVE
                  </button>
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  } else if (type === "edit-scholarship") {
    return (
      <div className={showHideClassName}>
        <Modal show={show} onHide={handleClose} className="modal-container">
          <Modal.Header closeButton>
            <Modal.Title>
                <div className="modal-header-label">EDIT SCHOLARSHIP</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    NAME<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="name"
                    className="form-control input-shadow "
                    value={data.name}
                    id="scholarship_name"
                    aria-describedby="subject"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <InputError className='left' isValid={isError.name} message={"This field is required"}/>
                </div>
              </Col>
              <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    CONTACT PERSON<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="contact_person"
                    className="form-control input-shadow "
                    id="contact_person"
                    value={data.contact_person}
                    aria-describedby="contact_person"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <InputError className='left' isValid={isError.contact_person} message={"This field is required"}/>
                </div>
              </Col>

              <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    TARGET DATE
                  </p>
                  <input
                    type="date"
                    name="target_date"
                    className="form-control faded-green-bg project-date input-shadow"
                    value={data.target_date}
                    id="date_from"
                    aria-describedby="date_from"
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
              </Col>

              <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    BUDGET
                  </p>
                    <input
                      type="text"
                      name="budget"
                      className="form-control input-text input-shadow "
                      id="subject"
                      value={data.budget}
                      aria-describedby="subject"
                      onChange={(e) => handleInputChange(e)}
                    />
                </div>
              </Col>

                <Col xs={12} sm={4} className="mt-3">
                <div className="form-group">
                  <p className="input-subtitle">
                    EMAIL<span className="required-icon">*</span>
                  </p>
                <input
                  type="text"
                  name="email"
                  className="form-control input-text input-shadow "
                  id="email"
                  value={data.email}
                  aria-describedby="email"
                  onChange={(e) => handleInputChange(e)}
                />
                <InputError className='left' isValid={isError.email} message={"This field is required"}/>                </div>
              </Col>
          </Row>

          <Row>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle left">
                  TAG<span className="required-icon">*</span>
                </span>
                <input
                  type="text"
                  name="tag_1"
                  value={data.tag_1}
                  className="form-control input-shadow "
                  onChange={(e) => handleInputChange(e)}
                />
                 <InputError className='left' isValid={isError.tag_1} message={"This field is required"}/>
              </Col>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle">TAG 2 (Optional)</span>
                <input
                  type="text"
                  name="tag_2"
                  value={data.tag_2}
                  className="form-control input-shadow "
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle">TAG 3 (Optional)</span>
                <input
                  type="text"
                  name="tag_3"
                  value={data.tag_3}
                  className="form-control input-shadow "
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle">TAG 4 (Optional)</span>
                <input
                  type="text"
                  name="tag_4"
                  value={data.tag_4}
                  className="form-control  input-shadow "
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
              <Col xs={6} sm={4} className="mt-3">
                <span className="input-subtitle">TAG 5 (Optional)</span>
                <input
                  type="text"
                  name="tag_5"
                  value={data.tag_5}
                  className="form-control  input-shadow "
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
            </Row>
          

          <Row className="pt-4 ms-1">
            <Row>
              <span className="input-subtitle left">DESCRIPTION </span>
            </Row>
            <Row>
              <textarea
                type="text"
                name="description"
                className=" form-control input-text input-shadow input-desc "
                id="description"
                value={data.description}
                aria-describedby="description"
                onChange={(e) => handleInputChange(e)}
              />
            </Row>
          </Row>

            <Row className="mt-5 right">
              <Col>
                <button
                  type="button"
                  className="btn-cancel right"
                  onClick={handleClose}
                >
                  CANCEL
                </button>
              </Col>
              <Col>
                {isClicked === false && (
                  <button
                    ref={saveBtn}
                    type="button"
                    className="btn-post right"
                    onClick={handleUpdate}
                  >
                    SAVE
                  </button>
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  } else if (type === "delete-scholarship") {
    return (
      <div className={showHideClassName}>
        <section className="modal-nav-main modal-nav">
          <Row>
            <h4 className="maroon">DELETE SCHOLARSHIP</h4>
          </Row>
          <hr />
          <Row className="modal-content-body pt-2">
            <h1 className="input-subtitle center">
              Are you sure you want to delete <span className="yellow">{data.name}</span> ?
            </h1>
          </Row>
          <hr />
          <div className="mt-4 d-flex justify-content-end">
              <button type="button" className="yellow-btn me-3" onClick={handleClose}>
                Cancel
              </button>
              <button type="button" className="maroon-btn" onClick={handleDelete}>
                Delete
              </button>
          </div>
        </section>
      </div>
    );
  }

  // return (
  //   <div className={showHideClassName}>
  //     <section className="modal-main">
  //       Here
  //       <button type="button" onClick={handleClose}>
  //         Close
  //       </button>
  //     </section>
  //   </div>
  // );
};

export default ModalPopUp;
