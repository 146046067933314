import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { propTypes } from "react-bootstrap/esm/Image";

function TableLoader({ tableHeaders, data }) {
  const [showLoading, setShowLoading] = useState(true);

  React.useEffect(() => {
    let loadingTime = setTimeout(() => {
      setShowLoading(false);
    }, 500);

    return () => {
      clearTimeout(loadingTime);
    };
  }, []);

  const loadingDisplay = () => {
    return (
      <>
        <tr>
          {/* <td></td> */}
          <td colSpan='7'>
            <div className=" spinner-container text-center">
              <span className="loading-spinner"></span>
            </div>
          </td>
        </tr>
      </>
    );
  };

  if (data && data.length !== 0) {
    return loadingDisplay();
  } else {
    return (
      <>
        {showLoading && loadingDisplay()}
      </>
    );
  }
}


export default TableLoader;
