import { handleValidationChange } from "../CommonValidation";

export const validateAddProject = (data, setIsError) => {
    var isValid = true

    if (data.department_id === "") {
      handleValidationChange("department_id", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("department_id", false, setIsError);
    }

    if (data.name === "") {
      handleValidationChange("name", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("name", false, setIsError);
    }

    if (data.project_head === "") {
      handleValidationChange("project_head", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("project_head", false, setIsError);
    }

    if (data.contact_person === "") {
      handleValidationChange("contact_person", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("contact_person", false, setIsError);
    }

    if (data.email === "") {
      handleValidationChange("email", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("email", false, setIsError);
    }

    if (data.tag_1 === "") {
      handleValidationChange("tag_1", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("tag_1", false, setIsError);
    }

    return isValid
}